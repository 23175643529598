import React, { useState } from 'react'
import SecondaryButton from '../../../../uiLibrary/SecondaryButton';
import GoogleLogo from '../../../../img/googleLogo.svg'
import outlookIcon from '../../../../img/outlookIcon.png'
import "react-datepicker/dist/react-datepicker.css";
import './accountSettings.scss'
import { connect } from 'react-redux';
import LabelButton from '../../../../uiLibrary/LabelButton';
import { useHistory } from "react-router-dom";
import { logout } from '../../../../actions/auth';
import { useDispatch } from "react-redux";

const emailProviderMap = {
    "GOOGLE": GoogleLogo,
    "OUTLOOK": outlookIcon
}
const AccountSettings = ({ settings, onChange, emailaddress, emailProvider }) => {

    let [password, setPassword] = useState(settings.password);
    let [isEditable, setIsEditable] = useState(false);

    const onAccountChange = () => {
        let payload = {
            "password": password
        }
        onChange(payload, "account");
    }

    const handleSubjectChange = (e) => {
        setPassword(e.target.value);
    }

    const onClickOutside = (e) => {
        if (password != "") {
            setPassword(password);
            onAccountChange();
        }
        else {
            setPassword(settings.password);
        }
        setIsEditable(false);
    }

    const history = useHistory()
    const dispatch = useDispatch()
    const onPassChange = () => {
        dispatch(logout())
        history.push({pathname: `/auth/reset-password`,
        search: `?e=${emailaddress}`,
        state: { isDisabled: true }})
        // setIsEditable(true)
    }

    return (
        <div className="accountSettings">
            <div className="heading">
                My Account
            </div>

            <div className="flexAlignDisplay">
                <div className="header">
                    Email
                </div>
                <div className="content">
                    <div className="flexDisplay">
                        <SecondaryButton className="googleBtn">
                            <img
                                width="20"
                                height="20"
                                src={emailProvider ? emailProviderMap[emailProvider] : outlookIcon}
                            />
                            {emailProvider === 'GOOGLE' ? 'Google' : 'Outlook'}
                        </SecondaryButton>
                        <div className="email">
                            {emailaddress}
                        </div>
                    </div>

                </div>
            </div>
            {emailProvider === 'GENERIC' && 
                <div className="flexAlignDisplay">
                    <div className="header">
                        Password
                    </div>
                    <div className="calendarInputText flexAlignDisplay">
                        <input
                            style={{color:'var(--bg-primary-7)' , fontWeight:'400'}}
                            // value={password}
                            // onChange={handleSubjectChange}
                            // onBlur={onClickOutside}
                            // disabled={!isEditable}
                            type="password"
                            value={123456789}
                            disabled={true}
                        />
                            <LabelButton className="link" 
                            onClick={() => onPassChange()}
                            >
                                    Change Password                            
                            </LabelButton>
                    </div>
                </div>
            }           
        </div>
    )
}

export default connect(state => ({
    emailProvider: state.outbox.campaignOperations.emailProvider,
    emailaddress: state.auth.user.email
  }))(AccountSettings)