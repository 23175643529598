import qs from "qs";
import React, { useEffect, useRef, useState } from "react";
import {
  REQUEST_ARCHIVE_PROSPECTS_SUCCESS,
  _harryPotterInitState,
  addRecommendedCount,
  archiveProspects,
  clearAdvancedFilters,
  clearAllAdvancedFilters,
  clearProspects,
  clearRecommendedCandidates,
  clearScoringFilters,
  clearSelectedProspects,
  connectSocket,
  fetchAdvancedFilter,
  fetchAdvancedFilterSynonym,
  fetchAllProspects,
  fetchProspects,
  getActivitiesOfCurrentProspect,
  getRecommendationProbability,
  initRecommendedCount,
  initSort,
  initializeProspectsController,
  openCommentsAndNavigate,
  requestSaveProspects,
  resetArchCount,
  selectProspects,
  setActiveProspectVariant,
  setCompanySizeFilter,
  setCurrentBucket,
  setCurrentCompanyFilters,
  setCurrentIndustryFilters,
  setCurrentTitleFilters,
  setEducationFilters,
  setEducationScoringFilter,
  setEthnicityFilter,
  setExperienceScoringFilter,
  setFirstNamesFilter,
  setIndustryScoringFilter,
  setLastNamesFilter,
  setLocationFilters,
  setLocationStatus,
  setPageNumber,
  setPastCompanyFilters,
  setPastIndustryFilters,
  setPastLocationFilters,
  setPastLocationStatus,
  setPastTitleFilters,
  setRelevantExperienceFilter,
  setSchoolFilters,
  setSelectedProspectComments,
  setSkillScoringFilter,
  setSkillsFilters,
  setSkillsStatus,
  setSort,
  setSpecialtiesFilters,
  setSpecialtiesStatus,
  setSpecialtyScoringFilter,
  setSpokenLanguagesFilter,
  setStrictSkills,
  setSubDropdownView,
  setTitleScoringFilter,
  setTotalExperienceFilter,
  setVisaFilters,
  setYearCurrentCompanyFilter,
  setYearCurrentCompanyToggle,
  setYearCurrentPositionFilter,
  setYearCurrentPositionToggle,
  setYearOfGraduationFilter,
  setYearOfGraduationToggle,
  toggleAdvFiters,
  toggleEducationScoringFilter,
  toggleExperienceScoringFilter,
  toggleIndustryScoringFilter,
  toggleRelevantExperienceFilter,
  toggleSkillScoringFilter,
  toggleSortModal,
  toggleSpecialtyScoringFilter,
  toggleStrictCurrentTitle,
  toggleStrictEducation,
  toggleStrictLocation,
  toggleStrictPastLocation,
  toggleStrictPastTitle,
  toggleTitleScoringFilter,
  toggleTotalExperienceFilter,
  updateProspect,
} from "../../actions/prospectsView";
import ProspectLayout from "../../components/ProspectLayout/ProspectLayout";
import alertCircle1 from "../../img/alert-circle1.svg";

import { debounce, omit } from "lodash";
import { useCallback } from "react";
import Loader from "react-loader-spinner";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { initJobSetupView } from "../../actions/JobSetup";
import { fetchCampaignDrafts } from "../../actions/outbox";
import CompanyCard from "../../components/CompanyCard/CompanyCard";
import { commentNotificationList } from "../../constants/notification";
import ActionBar from "../../uiLibrary/ActionBar";
import RecommendedProspectFullCard from "../../uiLibrary/RecommendedProspectFullCard/RecommendedProspectFullCard";
import RevampProspectFullCard from "../../uiLibrary/RevampProspectFullCard/RevampProspectFullCard";
import RevampProspectMidCard from "../../uiLibrary/RevampProspectMidCard/RevampProspectMidCard";
import { manualApiCall } from "../../utils";
import {
  getCompanyDetailsFromDB
} from "../../utils/visitApiProfile";
import FilterController from "./FilterController";
import SortController from "./SortController";
import "./style.scss";
// import myVideo4 from "../../img/test.mp4";
import { useTimer } from "react-timer-hook";
import { PER_PAGE_COUNT } from "../../constants/api";
import { firstCall } from "../../constants/call";
import usePrevious from "../../customHooks/usePrevious";
import MoreProspectsLayout from "../../uiLibrary/FindMoreProspects/MoreProspectsLayout/MoreProspectsLayout";
import Loading from "../../uiLibrary/Loading";
import RevampHarryPotterCard from "../../uiLibrary/RevampHarryPotterCard/RevampHarryPotterCard";
import CrossSvg from "../../uiLibrary/SVGs/CrossSvg";
import FavouriteSVG from "../../uiLibrary/SVGs/FavoriteSVG";
import QuestionSvg from "../../uiLibrary/SVGs/QuestionSvg/QuestionSvg";
import { Mixpanel } from "../../utils/Mixpanel";
import DeepDetailProfileCard from "./components/DeepDetailProfileCard";
import QuickDetailProfileCard from "./components/QuickDetailProfileCard";

const idealReduceCompany = {
  min: 0,
  max: 10001,
};
const idealReduceCompany2 = {
  min: 1,
  max: 10001,
};

const NotFoundErrorMessage = ({ qParams, filterCount }) => {
  let Error = (
    <h5>
      No profiles found. Try a different title or location you want to hire from
    </h5>
  );

  if (filterCount > 0) {
    Error = (
      <h5>
        No profiles. Try adjusting your filters to find what you are looking
        for.
      </h5>
    );
  } else if (qParams.fF === "s") {
    Error = (
      <h5>
        No profiles liked yet{" "}
        <span>
          <FavouriteSVG color={"#c91010"} height="30" width="40" />{" "}
        </span>
        on a profile to see them in this folder
      </h5>
    );
  } else if (qParams.fF == "mb") {
    Error = (
      <h5>
        No profiles liked yet{" "}
        <span>
          <QuestionSvg color={"#c91010"} height="30" width="40" />
        </span>{" "}
        on a profile to see them in this folder
      </h5>
    );
  } else if (qParams.fF == "ar") {
    Error = (
      <h5>
        No profiles liked yet{" "}
        <span>
          <CrossSvg color={"#c91010"} height="30" width="40" />
        </span>{" "}
        on a profile to see them in this folder
      </h5>
    );
  } else if (qParams.rN && parseInt(qParams.rN) >= 0) {
    Error = <h5>No profiles found in this batch</h5>;
  }
  return Error;
};

const ScoreAndConnect = ({
  prospects,
  allProspects,
  selectedProspects,
  isLoading,
  isLoaded,
  funnel,
  buckets,
  dispatch,
  folders,
  ScoringFilters,
  AdvFilters,
  activeProspectVariant,
  isSortOpen,
  advFilterDataIsLoading,
  advFilterDataIsLoaded,
  meta: {
    activeFunnel,
    isSavingProspect,
    isArchivingProspect,
    isDownloadingProspect,
    archRequestCount,
  },
  location: { pathname, search },
  history: { push, replace },
  history,
  job,
  selectedProspectComments,
  fS,
  fSO,
  secS,
  secSO,
  filters,
  similarCompanies,
  firstBucket,
  recommendedCount,
  recommendedProspectData,
  recommendedProfiles,
  jobPosition,
  suggestedTitles,
  jobId,
  harryPotterMode,
  shouldShowHarryPotterMode,
  harryPotterCount,
  page,
  polling,
  runInfo,
  showMoreProspect,
  setShowMoreProspect,
  candidates,
}) => {
  const [isDetailViewOpen, setIsDetailViewOpen] = React.useState(false);
  let { jId, isQuick = false } = useParams();

  if (isQuick === "false") {
    isQuick = false;
  }

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        // alert("u just pressed back button")
        push("/");
      }
    };
  }, [pathname]);

  const qParams = qs.parse(search.split("?").pop());
  const pageNumber = parseInt(
    "p" in qParams ? parseInt(qParams.p) || page : page
  );
  // console.log(
  //   ">>>>>>>>>>>>>>>>>>>>>>>> QPARAMS <<<<<<<<<<<<<<<<<<<<<<",
  //   qParams
  // );
  const newParams = {
    ...omit(qParams, [...firstCall, ...commentNotificationList]),
  };

  const previousPageNumber = usePrevious(qParams.p);

  useEffect(() => {
    if (previousPageNumber > qParams.p && qParams.p == 0) {
      dispatch(resetArchCount());
      // alert("SHOULD CLEAR ARCHcOUNT")
    }
  }, [qParams.p]);

  const sortDefault = false; // isQuick ? fS !== "title_score" : fS !== "title_score" || secS !== "skill_score";
  // alert(`sort default:  ${sortDefault}`)
  let currentBucket =
    qParams?.b === "all" ? "All Titles" : qParams?.b || "All Titles";
  // console.log('PARAMS ARE: ', { params, search })
  // console.log("QUERY PARAMS: ", qs.parse(search.split('?').pop()))
  useEffect(() => {
    if (currentBucket === "All Titles") {
      dispatch(setCurrentBucket(firstBucket));
    } else {
      const filteredBucket = buckets.filter(
        (bucket) => bucket.name === currentBucket
      );
      dispatch(setCurrentBucket(filteredBucket[0]));
    }
  }, [firstBucket]);
  useEffect(() => {
    Mixpanel.track(`Prospects - Score and connect`);
    dispatch(initializeProspectsController());
    dispatch(initRecommendedCount());

    return () => {
      dispatch(setSubDropdownView("Prospects"));
      dispatch(clearRecommendedCandidates());
      dispatch(initJobSetupView());
    };
  }, []);

  useEffect(() => {
    if (advFilterDataIsLoaded && !advFilterDataIsLoading) {
      // dispatch(fetchAdvancedFilterSynonym())
      dispatch(_harryPotterInitState());
      dispatch(clearAllAdvancedFilters());
    }
  }, []);

  useEffect(() => {
    if (qParams?.p > 0) dispatch(_harryPotterInitState());
  }, [qParams?.p]);

  useEffect(() => {
    if (advFilterDataIsLoaded && !advFilterDataIsLoading) {
      dispatch(fetchAdvancedFilterSynonym());
    }
  }, [advFilterDataIsLoading, advFilterDataIsLoaded]);
  useEffect(() => {
    isQuick && !isQuick == "false" && dispatch(setActiveProspectVariant(1));
  }, [isQuick]);

  useEffect(() => {
    // console.log("LOOK HERE LOOK HERE LOOK HERE")
    console.log(qParams);
    if (qParams.hCSR) {
      // let count = 0
      const ranges = qParams.hCSR
        .split("||")
        .map((item) =>
          ["10000-10001", "10001-10001"].includes(item) ? "10001+" : item
        );
      const updatedCompanySizeFilter = AdvFilters?.companySize?.map((item) => {
        if (ranges.includes(item.name)) {
          // count += 1
          return { ...item, value: 1 };
        } else return item;
      });

      const fCount = updatedCompanySizeFilter.filter((item) => item.value > 0)
        .length;
      // console.log("UPDATED COMPANY SIZE FILTER: ", updatedCompanySizeFilter)
      // console.log("FILTER COUNT IS: ", fCount)

      // console.log("SETTING FILTER COUNT: ", count)

      // console.log("============ filte count and updated company size filter ==============", { fCount, updatedCompanySizeFilter })
      setFilterCount(fCount);
      dispatch(setCompanySizeFilter(updatedCompanySizeFilter));
      // console.log("SET HCSR FILTER", updatedCompanySizeFilter)
    }

    return () => {
      dispatch(
        setCompanySizeFilter(
          AdvFilters?.companySize.map((item) => ({ ...item, value: 0 }))
        )
      );
    };
  }, [qParams.hCSR]);

  useEffect(() => {
    const eVCount = qParams.eV ? qParams.eV.split("||").length : 0;
    const hVCount = qParams.hV ? qParams.hV.split("||").length : 0;
    const eLCount = qParams.eL ? qParams.eL.split("||").length : 0;
    const ePLCount = qParams.ePL ? qParams.ePL.split("||").length : 0;
    const hLCount = qParams.hL ? qParams.hL.split("||").length : 0;
    const hPLCount = qParams.hPL ? qParams.hPL.split("||").length : 0;
    const eSCount = qParams.eS ? qParams.eS.split("||").length : 0;
    const hSCount = qParams.hS ? qParams.hS.split("||").length : 0;
    const eSPCount = qParams.eSP ? qParams.eSP.split("||").length : 0;
    const hSPCount = qParams.hSP ? qParams.hSP.split("||").length : 0;
    const eSSCount = qParams.eSS ? qParams.eSS.split("||").length : 0;
    const hSSCount = qParams.hSS ? qParams.hSS.split("||").length : 0;
    const eECount = qParams.eE ? qParams.eE.split("||").length : 0;
    const hECount = qParams.hE ? qParams.hE.split("||").length : 0;
    const eCICount = qParams.eCI ? qParams.eCI.split("||").length : 0;
    const ePICount = qParams.ePI ? qParams.ePI.split("||").length : 0;
    const hCICount = qParams.hCI ? qParams.hCI.split("||").length : 0;
    const hPICount = qParams.hPI ? qParams.hPI.split("||").length : 0;
    const eCTCount = qParams.eCT ? qParams.eCT.split("||").length : 0;
    const ePTCount = qParams.ePT ? qParams.ePT.split("||").length : 0;
    const hCTCount = qParams.hCT ? qParams.hCT.split("||").length : 0;
    const hPTCount = qParams.hPT ? qParams.hPT.split("||").length : 0;
    const hEtCount = qParams.hEt ? qParams.hEt.split("||").length : 0;
    const eCCCount = qParams.eCC ? qParams.eCC.split("||").length : 0;
    const ePCCount = qParams.ePC ? qParams.ePC.split("||").length : 0;
    const hCCCount = qParams.hCC ? qParams.hCC.split("||").length : 0;
    const hPCCount = qParams.hPC ? qParams.hPC.split("||").length : 0;
    const eCSRCount = qParams.eCSR ? qParams.eCSR.split("||").length : 0;
    const hCSRCount = qParams.hCSR ? qParams.hCSR.split("||").length : 0;
    const hSLCount = qParams.hSL ? qParams.hSL.split("||").length : 0;
    const eSLCount = qParams.eSL ? qParams.eSL.split("||").length : 0;
    const hFNCount = qParams.hFN ? qParams.hFN.split("||").length : 0;
    const eFNCount = qParams.eFN ? qParams.eFN.split("||").length : 0;
    const hLNCount = qParams.hLN ? qParams.hLN.split("||").length : 0;
    const eLNCount = qParams.eLN ? qParams.eLN.split("||").length : 0;
    const yCCCount = qParams.yCC ? 1 : 0;
    const yCPCount = qParams.yCP ? 1 : 0;
    const YOGCount = qParams.YOG ? 1 : 0;
    const tECount = qParams.tE ? 1 : 0;
    const rECount = qParams.rE ? 1 : 0;
    const tCount = qParams.tSF ? 1 : 0;
    const sCount = qParams.sSF ? 1 : 0;
    const spCount = qParams.spSF ? 1 : 0;
    const expCount = qParams.expSF ? 1 : 0;
    const edCount = qParams.edSF ? 1 : 0;
    const iCount = qParams.iSF ? 1 : 0;

    const sum =
      hSLCount +
      eSLCount +
      hFNCount +
      eFNCount +
      hLNCount +
      eLNCount +
      yCCCount +
      yCPCount +
      YOGCount +
      eVCount +
      hVCount +
      eLCount +
      hLCount +
      hPLCount +
      ePLCount +
      eSCount +
      hSCount +
      eSPCount +
      hSPCount +
      eSSCount +
      hSSCount +
      eCICount +
      ePICount +
      eECount +
      hECount +
      hCICount +
      hPICount +
      eCTCount +
      ePTCount +
      hCTCount +
      hPTCount +
      hEtCount +
      eCCCount +
      ePCCount +
      hCCCount +
      hPCCount +
      eCSRCount +
      hCSRCount +
      tECount +
      rECount +
      tCount +
      sCount +
      spCount +
      expCount +
      edCount +
      iCount;

    setFilterCount(sum);
  }, []);

  const [FilterCount, setFilterCount] = useState(0);
  const initialState = useRef(false);

  const numberOfProspects =
    prospects.length > 0 ? prospects[0]?.totalcurrentcount : 0;
  const numberOfSelectedProspects = Object.keys(selectedProspects).length;

  const isAllSelected =
    numberOfProspects === numberOfSelectedProspects && numberOfProspects > 0;
  const showActions = numberOfSelectedProspects > 0;

  const topMin = numberOfProspects > 0 ? 1 : 0;
  const topMax = numberOfProspects ? numberOfProspects : PER_PAGE_COUNT;
  // < PER_PAGE_COUNT && numberOfProspects > 0
  //   ? numberOfProspects
  //   : numberOfProspects < 1
  //     ? 0
  //     : PER_PAGE_COUNT;

  const shouldRenderWaypoint =
    !isLoading && isLoaded
      ? prospects.length > 0
      : // && page !== Math.ceil(parseInt(data.counts.currentCount) / parseInt(data.prospectsPerPage)) :
      false;
  useEffect(() => {
    dispatch(fetchAdvancedFilter(jId, qParams?.aT));
    dispatch(fetchCampaignDrafts(jId));
    if ("p" in qParams) {
      const page = parseInt(qParams.p);
      console.log("PAGE IS: ", page);
      dispatch(setPageNumber(page));
      // fS=title_score&fSO=desc&p=0&secS=skill_score&secSO=desc
      if (qParams?.p > 0 && qParams.fS.trim()) {
        replace(
          `${pathname}?${qs.stringify({
            ...newParams,
            fF: "a",
            f: "a",
            fS: qParams.fS,
            fSO: qParams.fSO,
            secS,
            secSO,
          })}`
        );
      }
    } else if ([null, undefined, NaN].includes(qParams?.p) && qParams.fS) {
      replace(
        `${pathname}?${qs.stringify({
          ...newParams,
          p: 0,
          fF: "a",
          f: "a",
          fS: qParams.fS,
          fSO: qParams.fSO,
          secS,
          secSO,
        })}`
      );
    }
    // else {
    // replace(
    //   `/job/${jId}?${qs.stringify({
    //     ...newParams,
    //     p: 0,
    //     fS,
    //     fSO,
    //     secS,
    //     secSO,
    //   })}`
    // );
    // }

    iniSortingParams();
    dispatch(initializeProspectsController());
    return () => {
      dispatch(initSort());
      clearInterval(polling);
    };
  }, []);
  const [timerState, setTimerState] = useState(false);
  const newRunInfo = runInfo?.length - 1;
  const actionApiAdvFilter = (actionType, flag = false) => {
    manualApiCall("/api/auth/user/activity/store", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        actionName: flag
          ? `APPLY_SCORING_FILTER_${actionType}_FILTER`
          : `APPLY_ADVANCED_FILTER_${actionType}_FILTER`,
      }),
    });
  };
  const applyInitialFilterSort = (jposition, job_id, j_id) => {
    if (jposition?.length) {
      setTimeout(() => {
        // rankFilter(jposition, job_id, j_id);
        setTimerState(true);
      }, 500);
    } else {
      setTimeout(() => {
        // debugger;
        // applyInitialFilterSort(jobPosition, jobId, jId);
      }, 300);
    }
  };

  //
  // useEffect(() => {
  //   if (timerState == false && prospects.length) {
  //     debugger;
  //     setTimeout(() => {
  //       rankFilter(jobPosition?.length ? jobPosition[0]?.rank : 0);
  //     }, 500);
  //     setTimerState(true);
  //   }
  // }, [prospects]);
  // const rankFilter = (jobPosition, jobId, jId) => {
  //   /* for sort by rank */

  //   let skillsArray = [1, 2, 3, 4, 5, 6, 7];
  //   let specialtyArray = [8, 9, 10, 11, 12, 13];
  //   let rank = jobPosition?.length ? jobPosition[0]?.rank : 0;
  //   /* ----------------- */

  //   /* for filter by company size */
  //   const company_size = jobPosition?.[0]?.company_size?.[0];
  //   const newCompanySize = suggestedTitles
  //     ?.filter((t) => t?.selected == true)
  //     ?.map((title) => {
  //       const { company_size } = title;
  //       return {
  //         min: company_size?.[0]?.min,
  //         max: company_size?.[0]?.max,
  //       };
  //     });
  //   const concatSize = newCompanySize?.concat(company_size);

  //   const reduceSize = concatSize?.reduce(
  //     (acc, curr) => {
  //       if (curr?.min < acc?.min) {
  //         acc.min = curr?.min;
  //       }
  //       if (curr?.max > acc?.max) {
  //         acc.max = curr?.max;
  //       }
  //       return acc;
  //     },
  //     { min: concatSize?.[0]?.min, max: concatSize?.[0]?.max }
  //   );
  //   console.log("reduceSize", reduceSize);

  //   /* ----------------- */

  //   // /const company_size2 = suggestedTitles

  //   let filterSum = 0;
  //   const filterParams = omit({ ...newParams }, ["cm"]);
  //   if (
  //     !isEqual(idealReduceCompany, reduceSize) &&
  //     !isEqual(idealReduceCompany2, reduceSize)
  //   ) {
  //     const newCompanySizeFilter = AdvFilters?.companySize?.map(
  //       (itm, index) => {
  //         const { name } = itm;
  //         const prevMax = name.split("-")[1];
  //         if (prevMax < reduceSize?.min) {
  //           return itm;
  //         } else if (prevMax > reduceSize?.min && prevMax <= reduceSize?.max) {
  //           filterSum += 1;
  //           return {
  //             value: 1,
  //             name: itm?.name,
  //           };
  //         } else if (index === AdvFilters?.companySize?.length - 1) {
  //           const lastMax = name.split("+")?.[0];

  //           if (lastMax == reduceSize?.max) {
  //             filterSum += 1;
  //             return {
  //               value: 1,
  //               name: itm?.name,
  //             };
  //           } else {
  //             return itm;
  //           }
  //         } else {
  //           return itm;
  //         }
  //       }
  //     );
  //     dispatch(setCompanySizeFilter([...newCompanySizeFilter]));

  //     const actionLogFlag = true;
  //     if (
  //       newCompanySizeFilter.filter((item) => item?.value === 2)?.length > 0
  //     ) {
  //       filterParams["eCSR"] = newCompanySizeFilter
  //         .filter((itm) => itm?.value === 2)
  //         .map((itm) => itm?.name)
  //         .join("||");
  //       actionLogFlag && actionApiAdvFilter("ELIMINATE_COMPANY_SIZE");
  //     }
  //     if (
  //       newCompanySizeFilter?.filter((item) => item?.value === 1)?.length > 0
  //     ) {
  //       filterParams["hCSR"] = newCompanySizeFilter
  //         .filter((itm) => itm?.value === 1)
  //         .map((itm) => itm?.name)
  //         .join("||");
  //       actionLogFlag && actionApiAdvFilter("HIGHLIGHT_COMPANY_SIZE");
  //     }
  //   }

  //   // debugger;
  //   if (skillsArray?.includes(rank)) {
  //     dispatch(
  //       setSort({
  //         secS: "skill_score",
  //         sort: "skill_score",
  //         order: "desc",
  //       })
  //     );
  //     replace(
  //       `${pathname}?${qs.stringify({
  //         ...filterParams,
  //         fF: "a",
  //         f: "a",
  //         fS,
  //         fSO,
  //         secS: "skill_score",
  //         secSO: "desc",
  //         p: 0,
  //         ...(newRunInfo >= 0 && { rN: newRunInfo }),
  //       })}`
  //     );
  //     setFilterCount(filterSum);
  //     return "skill";
  //   } else if (specialtyArray?.includes(rank)) {
  //     dispatch(
  //       setSort({
  //         secS: "industry_score",
  //         sort: "industry_score",
  //         order: "desc",
  //       })
  //     );
  //     replace(
  //       `${pathname}?${qs.stringify({
  //         ...filterParams,
  //         fF: "a",
  //         f: "a",
  //         fS,
  //         fSO,
  //         secS: "industry_score",
  //         secSO: "desc",
  //         p: 0,
  //         ...(newRunInfo >= 0 && { rN: newRunInfo }),
  //       })}`
  //     );
  //     setFilterCount(filterSum);
  //     return "specialty";
  //   } else {
  //     setFilterCount(filterSum);
  //     replace(
  //       `${pathname}?${qs.stringify({
  //         ...filterParams,
  //         p: 0,
  //         ...(newRunInfo >= 0 && { rN: newRunInfo }),
  //       })}`
  //     );
  //   }
  // };
  useEffect(() => {
    const qParams = qs.parse(search.split("?").pop());
    if ("nT" in qParams) {
      if (
        [
          "REACTED_TO_COMMENT",
          "REPLIED_TO_COMMENT",
          "COMMENT_ADD_WITH_MENTIONS",
          "COMMENT_ADD",
          "EMOJI_ADD",
        ].includes(qParams.nT)
      ) {
        try {
          setShowFullCard(true);
          if (qParams.nT === "EMOJI_ADD") {
            setTabValue("Profile");
          } else {
            setTabValue("Comments");
          }
          let filteredProspect = prospects.find(
            (item) => item.jobProfileId === parseInt(qParams.nPs)
          );
          setCurrentProspect(filteredProspect);

          dispatch(openCommentsAndNavigate(qParams.nPs, qParams.nM));
        } catch (error) {
          console.error("ERROR OCCURRED -> useEffect -> error", error);
        }
      }
    }
  }, [prospects, search]);

  const fromPage = parseInt(history?.location?.state?.from);

  useEffect(() => {
    const qParams = qs.parse(search.split("?").pop());
    if (fromPage || fromPage === 0) {
      try {
        setShowFullCard(true);
        const currPage = parseInt(qParams.p || 0);
        if (fromPage < currPage) {
          setCurrentProspect(prospects[0]);
          setCurrentProspectIndex(0);
        } else {
          setCurrentProspect(prospects[prospects.length - 1]);
          setCurrentProspectIndex(prospects.length - 1);
        }
      } catch (error) {
        setShowFullCard(false);
        window.history.replaceState({}, document.title);
        console.error("ERROR OCCURRED -> useEffect -> error", error);
      }
    }
  }, [prospects, search]);

  useEffect(() => {
    const qParams = qs.parse(search.split("?").pop());

    // alert('change detect')

    console.log("===========fetchProspects=============", {
      jId,
      qParams,
      isQuick,
    });

    if (qParams.p && qParams.p == 0) {
      if (!(fromPage || fromPage === 0)) dispatch(clearProspects());
    }
    // console.log("FETCHING PROSPECTS", { isQuick, secS: qParams.secS });

    // if (!isQuick && (!qParams.secS || qParams.secS === '')) {
    //   console.log("NOT QUICK JOB")
    //   const sort = {
    //     fS: 'title_score',
    //     fSO: 'desc',
    //     secS: qParams.secS || 'industry_score',
    //     secSO: 'desc'
    //   }

    //   push(`${pathname}?${qs.stringify({ ...qParams, ...sort, fF: 'a', p: qParams.p || 0, f: 'a' })}`)
    //   // dispatch(setSort(sort))
    //   // alert('fetch karso 0')
    //   // dispatch(fetchProspects(jId, { ...qParams, ...sort, fF: 'a', p: 0, f: 'a' }));
    // }

    // console.log('isquick: ', { isQuick, qParams })
    // if (isQuick && qParams.fS) {
    //   // alert("should fetch prospects")
    //   // alert('fetch karso 1')
    //   dispatch(fetchProspects(jId, qParams, qParams?.aT));
    // }

    // if (!isQuick && qParams.fS) {
    //   // alert('fetch karso 2')
    //   dispatch(fetchProspects(jId, qParams, qParams?.aT));
    // }

    // console.log("+++++++Searchchange: ", search)

    if (qParams.fS) {
      dispatch(fetchProspects(jId, qParams, qParams?.aT));
      dispatch(fetchAllProspects(jId, qParams, qParams?.aT));
    }

    if (qParams.rR && qParams.rR === "probability") {
      dispatch(setActiveProspectVariant(2));
    }
  }, [search]);

  const iniSortingParams = () => {
    console.log("INITIALIZING SORTING PARAMETERS: ");
    dispatch(
      setSort({
        fS: qParams.fS,
        fSO: qParams.fSO,
        secS: qParams.secS,
        secSO: qParams.secSO,
      })
    );
    // if (
    //   "fS" in qParams &&
    //   "fSO" in qParams &&
    //   "secS" in qParams &&
    //   "secSO" in qParams
    // ) {
    //   dispatch(
    //     setSort({
    //       fS: qParams.fS,
    //       fSO: qParams.fSO,
    //       secS: qParams.secS,
    //       secSO: qParams.secSO,
    //     })
    //   );
    // } else {
    //   dispatch(initSort());
    // }
  };

  const handleWaypointOnEnter = () => {
    const p = parseInt(qParams?.p) || 0;

    push(`/job/${jId}?${qs.stringify({ ...newParams, p: p + 1 })}`);
  };

  const onSelect = (e, prospect, currentProspect = false) => {
    const value = e.target.checked;
    // if (numberOfSelectedProspects > 54 && value) {
    //   alert("Can not select more than 50 prospects");
    // } else {
    dispatch(selectProspects([prospect]));
    // }
  };
  const [currentProspect, setCurrentProspect] = useState(null);
  const onSelectAll = () => {
    if (numberOfProspects === 0) {
      alert("There are no prospects");
    } else {
      // if (numberOfProspects <= PER_PAGE_COUNT)
      try {
        // const qParamsCsv = {
        //   ...omit(
        //     { ...qs.parse(search.split("?").pop()) },
        //     [],
        //   )
        // }
        // qParamsCsv['pPP'] = firstBucket?.count ? firstBucket?.count : PER_PAGE_COUNT
        // qParamsCsv['p'] = 0

        // prospectsApiCall(jobId, qParamsCsv)
        //   .then(result => {
        //     if (result?.prospects?.candidates) {
        const selectedProspects = allProspects?.filter(
          (item) => item.hidden !== true
        );
        dispatch(selectProspects(selectedProspects, !isAllSelected));
        //   }
        // })
      } catch (e) {
        console.error("ERROR OCCURRED -> onSelectAll Catch -> e", e);
      }
    }
    // else {
    //   // alert("Can not select more than 50 prospects");
    //   dispatch(clearSelectedProspects());
    //   // const top25ProspectsToSelect = prospects.slice(0, 25)
    //   // dispatch(selectProspects(top25ProspectsToSelect, !isAllSelected))
    // }
  };

  const onDeSelectAll = () => {
    dispatch(clearSelectedProspects());
  };

  const onSelectApply = (e) => {
    const value = e.target.value;
    if (value > 0) {
      const topProspectsToSelect = allProspects.slice(0, value);
      dispatch(selectProspects(topProspectsToSelect, true));
    } else {
      alert("Select more prospects");
    }
  };

  const handleClickBucket = (bucket) => {
    console.log("ON CLICK BUCKET: ", bucket);
    let b = bucket.name === "All Titles" ? "all" : bucket.id || bucket.name;

    dispatch(setCurrentBucket(bucket));
    push(`${pathname}?${qs.stringify({ ...newParams, b, p: 0 })}`);
  };

  const handleClickMaybeProspects = (profileId, maybe) => {
    if (qParams.fF === "mb" && !maybe) {
      dispatch({
        type: REQUEST_ARCHIVE_PROSPECTS_SUCCESS,
        meta: {
          profiles: [profileId],
          rollback: false,
        },
      });
    } else if (maybe) {
      dispatch(
        updateProspect({
          key: profileId,
          values: {
            maybe,
          },
        })
      );
    } else {
      dispatch(
        updateProspect({
          key: profileId,
          values: {
            maybe,
          },
        })
      );
    }
  };

  //bugfix-NA-s21-1625 & bugfix-NA-s21-1624
  const handleActivitiesofCurrentProspect = (value) => {
    dispatch(getActivitiesOfCurrentProspect(value));
  };

  const handleClickSaveProspects = (
    jobProfileIds = [],
    rollback = false,
    profileId,
    maybe = false
  ) => {
    // console.log('HANDLE CLICK SAVE: ')
    dispatch(requestSaveProspects(jId, jobProfileIds, rollback));
    push(`${pathname}?${qs.stringify({ ...newParams })}`);
    if (qParams.fF === "mb" && maybe && !rollback) {
      jobProfileIds.map((id) =>
        dispatch({
          type: REQUEST_ARCHIVE_PROSPECTS_SUCCESS,
          meta: {
            profiles: [id],
            rollback: false,
          },
        })
      );
    }
    if (qParams.fF === "s" && rollback) {
      jobProfileIds.map((id) =>
        dispatch({
          type: REQUEST_ARCHIVE_PROSPECTS_SUCCESS,
          meta: {
            profiles: [id],
            rollback: false,
          },
        })
      );
    }
  };

  const handleClickArchiveProspects = () => {
    dispatch(archiveProspects(jId));
    push(`${pathname}?${qs.stringify({ ...newParams, fF: "a" })}`);
  };

  const handleSortToggle = (e) => {
    iniSortingParams();
    dispatch(toggleSortModal());
  };

  const [showFullCard, setShowFullCard] = useState(false);
  const [fullCardTag, setFullCardTag] = useState(null);
  const [currentProspectIndex, setCurrentProspectIndex] = useState(null);

  const handleClickCard = (prospect, e) => {
    if (harryPotterMode) {
      const elements = document.querySelectorAll(".video-circle");
    }
    setTabValue("Profile");

    setCurrentProspect(prospect);

    setShowFullCard(true);
  };

  useEffect(() => {
    if (
      (qParams.eV ||
        qParams.hV ||
        qParams.eL ||
        qParams.hL ||
        qParams.eS ||
        qParams.hS ||
        qParams.eSP ||
        qParams.hSP ||
        qParams.eSS ||
        qParams.hSS ||
        qParams.eE ||
        qParams.hE ||
        qParams.eCI ||
        qParams.ePI ||
        qParams.hCI ||
        qParams.hPI ||
        qParams.eCT ||
        qParams.ePT ||
        qParams.hCT ||
        qParams.hCT ||
        qParams.hPT ||
        qParams.hEt ||
        qParams.eCC ||
        qParams.ePC ||
        qParams.hCC ||
        qParams.hPC ||
        qParams.eCSR ||
        qParams.hCSR ||
        qParams.hSL ||
        qParams.eSL ||
        qParams.hFN ||
        qParams.eFN ||
        qParams.hLN ||
        qParams.eLN ||
        qParams.yCC ||
        qParams.yCP ||
        qParams.YOG ||
        qParams.tE ||
        qParams.rE ||
        qParams.tSF ||
        qParams.sSF ||
        qParams.spSF ||
        qParams.expSF ||
        qParams.edSF ||
        qParams.iSF) &&
      FilterCount == 0
    ) {
      initialState.current = true;
      handleFilterToggle();
      initialState.current = false;
      // console.log('IN HERE LOGGED', initialState)
      // setInitialState(false)
      // console.log('IN HERE LOGGED', initialState)
    }
  }, []);

  const handleFilterToggle = () => {
    const eVCount = qParams.eV ? qParams.eV.split("||").length : 0;
    const hVCount = qParams.hV ? qParams.hV.split("||").length : 0;
    const eLCount = qParams.eL ? qParams.eL.split("||").length : 0;
    const ePLCount = qParams.ePL ? qParams.ePL.split("||").length : 0;
    const hLCount = qParams.hL ? qParams.hL.split("||").length : 0;
    const hPLCount = qParams.hPL ? qParams.hPL.split("||").length : 0;
    const eSCount = qParams.eS ? qParams.eS.split("||").length : 0;
    const hSCount = qParams.hS ? qParams.hS.split("||").length : 0;
    const eSPCount = qParams.eSP ? qParams.eSP.split("||").length : 0;
    const hSPCount = qParams.hSP ? qParams.hSP.split("||").length : 0;
    const eSSCount = qParams.eSS ? qParams.eSS.split("||").length : 0;
    const hSSCount = qParams.hSS ? qParams.hSS.split("||").length : 0;
    const eECount = qParams.eE ? qParams.eE.split("||").length : 0;
    const hECount = qParams.hE ? qParams.hE.split("||").length : 0;
    const eCICount = qParams.eCI ? qParams.eCI.split("||").length : 0;
    const ePICount = qParams.ePI ? qParams.ePI.split("||").length : 0;
    const hCICount = qParams.hCI ? qParams.hCI.split("||").length : 0;
    const hPICount = qParams.hPI ? qParams.hPI.split("||").length : 0;
    const eCTCount = qParams.eCT ? qParams.eCT.split("||").length : 0;
    const ePTCount = qParams.ePT ? qParams.ePT.split("||").length : 0;
    const hCTCount = qParams.hCT ? qParams.hCT.split("||").length : 0;
    const hPTCount = qParams.hPT ? qParams.hPT.split("||").length : 0;
    const hEtCount = qParams.hEt ? qParams.hEt.split("||").length : 0;
    const eCCCount = qParams.eCC ? qParams.eCC.split("||").length : 0;
    const ePCCount = qParams.ePC ? qParams.ePC.split("||").length : 0;
    const hCCCount = qParams.hCC ? qParams.hCC.split("||").length : 0;
    const hPCCount = qParams.hPC ? qParams.hPC.split("||").length : 0;
    const eCSRCount = qParams.eCSR ? qParams.eCSR.split("||").length : 0;
    const hCSRCount = qParams.hCSR ? qParams.hCSR.split("||").length : 0;
    const hSLCount = qParams.hSL ? qParams.hSL.split("||").length : 0;
    const eSLCount = qParams.eSL ? qParams.eSL.split("||").length : 0;
    const hFNCount = qParams.hFN ? qParams.hFN.split("||").length : 0;
    const eFNCount = qParams.eFN ? qParams.eFN.split("||").length : 0;
    const hLNCount = qParams.hLN ? qParams.hLN.split("||").length : 0;
    const eLNCount = qParams.eLN ? qParams.eLN.split("||").length : 0;
    const yCCCount = qParams.yCC ? 1 : 0;
    const yCPCount = qParams.yCP ? 1 : 0;
    const YOGCount = qParams.YOG ? 1 : 0;
    const tECount = qParams.tE ? 1 : 0;
    const rECount = qParams.rE ? 1 : 0;
    const tCount = qParams.tSF ? 1 : 0;
    const sCount = qParams.sSF ? 1 : 0;
    const spCount = qParams.spSF ? 1 : 0;
    const expCount = qParams.expSF ? 1 : 0;
    const edCount = qParams.edSF ? 1 : 0;
    const iCount = qParams.iSF ? 1 : 0;

    if (qParams?.sST == "true") {
      dispatch(setSkillsStatus(true));
    }
    if (qParams?.sST == "false") {
      dispatch(setSkillsStatus(false));
    }
    if (qParams?.sPST == "true") {
      dispatch(setSpecialtiesStatus(true));
    }
    if (qParams?.sPST == "false") {
      dispatch(setSpecialtiesStatus(false));
    }
    if (qParams?.LTS == "true") {
      dispatch(setLocationStatus(true));
    }
    if (qParams?.LTS == "false") {
      dispatch(setLocationStatus(false));
    }
    if (qParams?.PLTS == "true") {
      dispatch(setPastLocationStatus(true));
    }
    if (qParams?.PLTS == "false") {
      dispatch(setPastLocationStatus(false));
    }

    const sum =
      hSLCount +
      eSLCount +
      hFNCount +
      eFNCount +
      hLNCount +
      eLNCount +
      yCCCount +
      yCPCount +
      YOGCount +
      eVCount +
      hVCount +
      eLCount +
      hLCount +
      hPLCount +
      ePLCount +
      eSCount +
      hSCount +
      eSPCount +
      hSPCount +
      eSSCount +
      hSSCount +
      eCICount +
      ePICount +
      eECount +
      hECount +
      hCICount +
      hPICount +
      eCTCount +
      ePTCount +
      hCTCount +
      hPTCount +
      hEtCount +
      eCCCount +
      ePCCount +
      hCCCount +
      hPCCount +
      eCSRCount +
      hCSRCount +
      tECount +
      rECount +
      tCount +
      sCount +
      spCount +
      expCount +
      edCount +
      iCount;

    dispatch(setStrictSkills(qParams.sS ? qParams.sS : false));

    if (qParams.cTS && !AdvFilters.currentTitleStrict)
      dispatch(toggleStrictCurrentTitle());

    if (qParams.pTS && !AdvFilters.pastTitleStrict)
      dispatch(toggleStrictPastTitle());

    if (qParams.eDS && !AdvFilters.educationStrict)
      dispatch(toggleStrictEducation());

    if (qParams.lS && !AdvFilters.locationStrict)
      dispatch(toggleStrictLocation());

    if (qParams.PlS && !AdvFilters.pastLocationStrict)
      dispatch(toggleStrictPastLocation());

    if (eSLCount + hSLCount > 0) {
      dispatch(
        setSpokenLanguagesFilter(
          AdvFilters.languages.map((itm) =>
            eSLCount > 0 && qParams.eSL.split("||").includes(itm.name)
              ? { name: itm.name, value: 2 }
              : hSLCount > 0 && qParams.hSL.split("||").includes(itm.name)
                ? { name: itm.name, value: 1 }
                : itm
          )
        )
      );
    }
    if (eFNCount + hFNCount > 0) {
      dispatch(
        setFirstNamesFilter(
          AdvFilters.firstNames.map((itm) =>
            eFNCount > 0 && qParams.eFN.split("||").includes(itm.name)
              ? { name: itm.name, value: 2 }
              : hFNCount > 0 && qParams.hFN.split("||").includes(itm.name)
                ? { name: itm.name, value: 1 }
                : itm
          )
        )
      );
    }
    if (eLNCount + hLNCount > 0) {
      dispatch(
        setLastNamesFilter(
          AdvFilters.lastNames.map((itm) =>
            eLNCount > 0 && qParams.eLN.split("||").includes(itm.name)
              ? { name: itm.name, value: 2 }
              : hLNCount > 0 && qParams.hLN.split("||").includes(itm.name)
                ? { name: itm.name, value: 1 }
                : itm
          )
        )
      );
    }
    if (eVCount + hVCount > 0)
      dispatch(
        setVisaFilters(
          AdvFilters.visa.map((itm) =>
            eVCount > 0 && qParams.eV.split("||").includes(itm.name)
              ? { name: itm.name, value: 2 }
              : hVCount > 0 && qParams.hV.split("||").includes(itm.name)
                ? { name: itm.name, value: 1 }
                : itm
          )
        )
      );

    if (eLCount + hLCount > 0)
      dispatch(
        setLocationFilters(
          AdvFilters.location.map((itm) =>
            eLCount > 0 && qParams.eL.split("||").includes(itm.name)
              ? { name: itm.name, value: 2 }
              : hLCount > 0 && qParams.hL.split("||").includes(itm.name)
                ? { name: itm.name, value: 1 }
                : itm
          )
        )
      );

    if (ePLCount + hPLCount > 0)
      dispatch(
        setPastLocationFilters(
          AdvFilters.pastLocation.map((itm) =>
            ePLCount > 0 && qParams.ePL.split("||").includes(itm.name)
              ? { name: itm.name, value: 2 }
              : hPLCount > 0 && qParams.hPL.split("||").includes(itm.name)
                ? { name: itm.name, value: 1 }
                : itm
          )
        )
      );
    if (eSCount + hSCount > 0)
      dispatch(
        setSkillsFilters(
          AdvFilters.skills.map((itm) =>
            eSCount > 0 && qParams.eS.split("||").includes(itm.name)
              ? { name: itm.name, value: 2 }
              : hSCount > 0 && qParams.hS.split("||").includes(itm.name)
                ? { name: itm.name, value: 1 }
                : itm
          )
        )
      );

    if (eSPCount + hSPCount > 0)
      dispatch(
        setSpecialtiesFilters(
          AdvFilters.specialties.map((itm) =>
            eSPCount > 0 && qParams.eSP.split("||").includes(itm.name)
              ? { name: itm.name, value: 2 }
              : hSPCount > 0 && qParams.hSP.split("||").includes(itm.name)
                ? { name: itm.name, value: 1 }
                : itm
          )
        )
      );

    if (eSSCount + hSSCount > 0)
      dispatch(
        setSchoolFilters(
          AdvFilters.school.map((itm) =>
            eSSCount > 0 && qParams.eSS.split("||").includes(itm.name)
              ? { name: itm.name, value: 2 }
              : hSSCount > 0 && qParams.hSS.split("||").includes(itm.name)
                ? { name: itm.name, value: 1 }
                : itm
          )
        )
      );

    if (eECount + hECount > 0)
      dispatch(
        setEducationFilters(
          AdvFilters.education.map((itm) =>
            eECount > 0 && qParams.eE.split("||").includes(itm.name)
              ? { name: itm.name, value: 2 }
              : hECount > 0 && qParams.hE.split("||").includes(itm.name)
                ? { name: itm.name, value: 1 }
                : itm
          )
        )
      );

    if (eCTCount + hCTCount > 0)
      dispatch(
        setCurrentTitleFilters(
          AdvFilters.currentTitle.map((itm) =>
            eCTCount > 0 && qParams.eCT.split("||").includes(itm.name)
              ? { name: itm.name, value: 2 }
              : hCTCount > 0 && qParams.hCT.split("||").includes(itm.name)
                ? { name: itm.name, value: 1 }
                : itm
          )
        )
      );

    if (eCCCount + hCCCount > 0)
      dispatch(
        setCurrentCompanyFilters(
          AdvFilters.currentCompany.map((itm) =>
            eCCCount > 0 && qParams.eCC.split("||").includes(itm.name)
              ? { name: itm.name, value: 2 }
              : hCCCount > 0 && qParams.hCC.split("||").includes(itm.name)
                ? { name: itm.name, value: 1 }
                : itm
          )
        )
      );

    if (eCICount + hCICount > 0)
      dispatch(
        setCurrentIndustryFilters(
          AdvFilters.currentIndustry.map((itm) =>
            eCICount > 0 && qParams.eCI.split("||").includes(itm.name)
              ? { name: itm.name, value: 2 }
              : hCICount > 0 && qParams.hCI.split("||").includes(itm.name)
                ? { name: itm.name, value: 1 }
                : itm
          )
        )
      );

    if (ePTCount + hPTCount > 0)
      dispatch(
        setPastTitleFilters(
          AdvFilters.pastTitle.map((itm) =>
            ePTCount > 0 && qParams.ePT.split("||").includes(itm.name)
              ? { name: itm.name, value: 2 }
              : hPTCount > 0 && qParams.hPT.split("||").includes(itm.name)
                ? { name: itm.name, value: 1 }
                : itm
          )
        )
      );

    if (hEtCount > 0)
      dispatch(
        setEthnicityFilter(
          AdvFilters.ethnicity.map((itm) =>
            qParams.hEt.split("||").includes(itm.name)
              ? { name: itm.name, value: 1 }
              : itm
          )
        )
      );

    if (ePCCount + hPCCount > 0)
      dispatch(
        setPastCompanyFilters(
          AdvFilters.pastCompany.map((itm) =>
            ePCCount > 0 && qParams.ePC.split("||").includes(itm.name)
              ? { name: itm.name, value: 2 }
              : hPCCount > 0 && qParams.hPC.split("||").includes(itm.name)
                ? { name: itm.name, value: 1 }
                : itm
          )
        )
      );

    if (ePICount + hPICount > 0)
      dispatch(
        setPastIndustryFilters(
          AdvFilters.pastIndustry.map((itm) =>
            ePICount > 0 && qParams.ePI.split("||").includes(itm.name)
              ? { name: itm.name, value: 2 }
              : hPICount > 0 && qParams.hPI.split("||").includes(itm.name)
                ? { name: itm.name, value: 1 }
                : itm
          )
        )
      );

    if (eCSRCount + hCSRCount > 0)
      dispatch(
        setCompanySizeFilter(
          AdvFilters.companySize.map((itm) =>
            eCSRCount > 0 && qParams.eCSR.split("||").includes(itm.name)
              ? { name: itm.name, value: 2 }
              : hCSRCount > 0 && qParams.hCSR.split("||").includes(itm.name)
                ? { name: itm.name, value: 1 }
                : itm
          )
        )
      );

    if (tECount === 1) {
      if (!AdvFilters.totalExperience.active)
        dispatch(toggleTotalExperienceFilter());
      dispatch(
        setTotalExperienceFilter({
          min: parseInt(qParams.tE.split("||")[0]),
          max: parseInt(qParams.tE.split("||")[1]),
        })
      );
    }

    if (yCCCount === 1) {
      if (!AdvFilters.yearCurrentCompany.active)
        dispatch(setYearCurrentCompanyToggle());
      dispatch(
        setYearCurrentCompanyFilter({
          min: parseFloat(qParams.yCC.split("||")[0]),
          max: parseFloat(qParams.yCC.split("||")[1]),
        })
      );
    }
    if (yCPCount === 1) {
      if (!AdvFilters.yearCurrentPosition.active)
        dispatch(setYearCurrentPositionToggle());
      dispatch(
        setYearCurrentPositionFilter({
          min: parseInt(qParams.yCP.split("||")[0]),
          max: parseInt(qParams.yCP.split("||")[1]),
        })
      );
    }
    if (YOGCount === 1) {
      if (!AdvFilters.year_of_graduations.active)
        dispatch(setYearOfGraduationToggle());
      dispatch(
        setYearOfGraduationFilter({
          min: parseInt(qParams.YOG.split("||")[0]),
          max: parseInt(qParams.YOG.split("||")[1]),
        })
      );
    }

    if (rECount === 1) {
      if (!AdvFilters.relevantExperience.active)
        dispatch(toggleRelevantExperienceFilter());
      dispatch(
        setRelevantExperienceFilter({
          min: parseInt(qParams.rE.split("||")[0]),
          max: parseInt(qParams.rE.split("||")[1]),
        })
      );
    }

    if (tCount !== 0) {
      if (!ScoringFilters.titleScore.active)
        dispatch(toggleTitleScoringFilter());
      dispatch(setTitleScoringFilter(parseInt(qParams.tSF)));
    }

    if (sCount !== 0) {
      if (!ScoringFilters.skillScore.active)
        dispatch(toggleSkillScoringFilter());
      dispatch(setSkillScoringFilter(parseInt(qParams.sSF)));
    }

    if (spCount !== 0) {
      if (!ScoringFilters.specialtyScore.active)
        dispatch(toggleSpecialtyScoringFilter());
      dispatch(setSpecialtyScoringFilter(parseInt(qParams.spSF)));
    }

    if (expCount !== 0) {
      if (!ScoringFilters.experienceScore.active)
        dispatch(toggleExperienceScoringFilter());
      dispatch(setExperienceScoringFilter(parseInt(qParams.expSF)));
    }

    if (edCount !== 0) {
      if (!ScoringFilters.educationScore.active)
        dispatch(toggleEducationScoringFilter());
      dispatch(setEducationScoringFilter(parseInt(qParams.edSF)));
    }

    if (iCount !== 0) {
      if (!ScoringFilters.industryScore.active)
        dispatch(toggleIndustryScoringFilter());
      dispatch(setIndustryScoringFilter(parseInt(qParams.iSF)));
    }
    // alert(`setting filter count to  ${sum}`)
    setFilterCount(sum);

    if (!initialState.current) dispatch(toggleAdvFiters());
  };

  const handleClearFilters = () => {
    setFilterCount(0);
    dispatch(clearAdvancedFilters());
    dispatch(clearScoringFilters());
    // dispatch(setTemplate(''))

    // manualApiCall('/api/auth/user/activity/store',
    //   {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify({
    //       "actionName": "CLEAR_FILTER",
    //       "jobId": qsParams.jId
    //     })
    //   })

    const newParams_ = {
      f: newParams.f || "a",
      p: 0,
      pPP: PER_PAGE_COUNT,
      rN: newParams.rN || "",
    };

    if (qParams.fF) newParams_["fF"] = qParams.fF;
    if (qParams.fS) newParams_["fS"] = qParams.fS;
    if (qParams.fSO) newParams_["fSO"] = qParams.fSO;
    if (qParams.secS) newParams_["secS"] = qParams.secS;
    if (qParams.secSO) newParams_["secSO"] = qParams.secSO;
    if (qParams.aT) newParams_["aT"] = qParams.aT;

    dispatch(clearProspects());

    replace(`${pathname}?${qs.stringify(newParams_)}`);

    // window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    if (
      timerState == false &&
      jobPosition?.length &&
      jobId == jId &&
      runInfo?.length != 0 &&
      !isQuick
    ) {
      applyInitialFilterSort(jobPosition, jobId, jId);
    }
  }, [jobPosition, jobId, jId, runInfo?.length]);
  // const filterCount
  const [commentsExpanded, setCommentsExpanded] = useState({});
  const [tabValue, setTabValue] = useState("Profile");
  const [currentHigLighted, setCurrentHigLighted] = useState("");
  const myTimeOut = setTimeout(() => { }, 5000);
  const addHoverHighlighter = debounce((type, index, dataTag) => {
    const data_name = dataTag ? dataTag : "data-name";
    const element = document.querySelectorAll(
      `[${data_name}="${type} ${index}"]`
    );
    element.forEach((item) => {
      item.classList.add("skillHighLight");
    });
  }, 500);
  const removeHoverHighlighter = (type, index, dataTag) => {
    const skillHighlightedElements = document.querySelectorAll(
      ".skillHighLight"
    );
    if (skillHighlightedElements?.length) {
      skillHighlightedElements.forEach((el) => {
        el.classList.remove("skillHighLight");
      });
    }
  };

  const toggleCommentsBar = (ref, prospectData = null) => {
    if (ref)
      setTimeout(() => {
        const y =
          ref?.current?.getBoundingClientRect()?.top +
          window?.pageYOffset +
          -150;
        window.scrollTo({ top: y, behavior: "smooth" });
      }, 800);
    setCommentsExpanded(true);
    dispatch(setSelectedProspectComments(prospectData));
  };
  useEffect(() => {
    if (job.id) dispatch(connectSocket(job.id));
  }, [job]);
  const handleCommentClick = (prospect, index) => {
    setShowFullCard(true);
    setCurrentProspect(prospect);
    // setTimeout(() => {
    //   setTabValue("Comments");
    // }, 100);
    setCurrentProspectIndex(index);
    setTabValue("Comments");
    // setCurrentProspect(prospect);
    dispatch(setSelectedProspectComments(prospect));
    // setTimeout(() => {
    //   setShowFullCard(true);
    // }, 5000);
  };
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [companyData, setCompanyData] = useState({
    companyLogo: "",
    companyName: "",
    companyHeadline: "",
    companyEmployees: "0 employees",
    companyDescription: "",
    industry: "",
    specialities: [],
    staffCount: 0,
    companyPageUrl: "",
    headquarter: [],
    companyType: "",
  });

  const handleShowCompanyModal = async (
    e,
    detected,
    detectedSpan,
    initCallback = (e) => false,
    finCallback = (e) => false
  ) => {
    console.log("@@@ -> e:", e)
    if (e === undefined || e === null) return
    initCallback();
    const name =
      typeof e == "string"
        ? e
        : e?.universalName ??
        e?.urn?.split(":").pop() ??
        e?.company ??
        e?.company_name;
    const description = e.description;

    // console.log("HANDLE SHOW COMPANY MODAL", e)
    try {
      // const cData = await getCompanyDetails(name);

      const cData = await getCompanyDetailsFromDB(name);
      console.log("C DATA: ", cData);
      // console.log("GETING HEADQUARTER: ", cData)
      const headquarter = cData.headquarter
        ? Object.values(cData?.headquarter).filter((e) => e !== null)
        : [];

      // console.log("HEAD QUARTER: ", headquarter)
      if (detected === "2") {
        detectedSpan.map((spanIndex) => {
          cData.industries.localizedName = `<span>${cData?.industries?.localizedName}</span>`;
        });
      } else if (detected === "3") {
        try {
          detectedSpan.map((spanIndex) => {
            if (parseInt(spanIndex) < cData.specialities?.length) {
              cData.specialities[parseInt(spanIndex)] = `<span>${cData?.specialities[parseInt(spanIndex)]
                }</span>`;
            }
          });
        } catch (error) { }
      }

      setCompanyData((c) => ({
        companyName: cData?.name,
        companyLogo: cData?.companyLogoUrl,
        companyHeadline: cData?.title,
        companyDescription: cData?.companyDescription,
        industry: cData?.industries?.localizedName,
        specialities: cData?.specialities,
        staffCount: cData?.staffCount,
        companyPageUrl: cData?.companyPageUrl,
        headquarter: headquarter,
        companyType: cData?.companyType?.localizedName,
      }));
    } catch (e) {
      finCallback();
      console.log(">>>>>>>>>>>>>> ERROR <<<<<<<<<<<<<<<", e);
      setCompanyData({
        companyLogo: "",
        companyName: name,
        companyHeadline: "",
        companyEmployees: "0 employees",
        companyDescription: "",
        industry: "",
        specialities: [],
        staffCount: 0,
        companyPageUrl: "",
        headquarter: [],
        companyType: "",
      });
    }
    setTimeout(() => {
      setShowCompanyModal(true);
      finCallback();
    }, 100);
  };

  useEffect(() => {
    if (showCompanyModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [showCompanyModal]);
  /* /////////////////////  Recommended Card   //////////////////////////////// */
  const [showRecommendedCard, setShowRecommendedCard] = useState(false);
  const handleRecommendedCard = (currentProspect) => {
    setCurrentProspect(currentProspect);
    setShowRecommendedCard(true);
  };
  /* /////////////////////  Recommended Card   //////////////////////////////// */

  // commented out because recommended tabs are hidden for now.
  // useEffect(() => {
  //   dispatch(getRecommendationCandidate(jId));
  // }, []);

  useEffect(() => { }, [recommendedProspectData, recommendedProfiles]);

  useEffect(() => {
    if (
      recommendedCount?.recommended +
      (recommendedProfiles?.length ? recommendedProfiles?.length - 1 : 0) ==
      10
    ) {
      dispatch(getRecommendationProbability(jId));
    }
  }, [recommendedCount?.recommended]);
  // harry potter mode turned off
  // useEffect(() => {
  //   if (qParams.p) dispatch(_fetchHarryPotterCount(jId));
  // }, [qParams.p]);

  const _fetchProspects = () => {
    dispatch(fetchProspects(jId, qParams, qParams?.aT));
    dispatch(fetchAllProspects(jId, qParams, qParams?.aT));
  };

  const [selectedBucketCount, setSelectedBucketCount] = useState({});
  useEffect(() => {
    const temp = buckets.filter(
      (bucket) => bucket?.id === currentBucket || bucket?.name === currentBucket
    )[0];
    setSelectedBucketCount(temp?.count);
  }, [currentBucket, buckets]);
  const totalPages = Math.ceil(selectedBucketCount / PER_PAGE_COUNT);

  const handleFullCardRight = () => {
    if (currentProspectIndex > prospects.length - 2) {
      if (pageNumber < totalPages) {
        setCurrentProspect(null);
        setCurrentProspectIndex(0);
        push(`${pathname}?${qs.stringify({ ...qParams, p: pageNumber + 1 })}`, {
          from: pageNumber,
        });
      }
      return;
    }
    if (prospects[currentProspectIndex + 1]?.hidden) {
      return;
    }
    setCurrentProspect(prospects[currentProspectIndex + 1]);
    setCurrentProspectIndex(currentProspectIndex + 1);
  };
  const handleFullCardLeft = () => {
    if (currentProspectIndex < 1) {
      if (pageNumber > 0) {
        setCurrentProspect(null);
        setCurrentProspectIndex(0);
        localStorage.setItem("rev", true);
        push(`${pathname}?${qs.stringify({ ...qParams, p: pageNumber - 1 })}`, {
          from: pageNumber,
        });
      }
      return;
    }
    setCurrentProspect(prospects[currentProspectIndex - 1]);
    setCurrentProspectIndex(currentProspectIndex - 1);
  };

  useEffect(() => {
    if (currentProspectIndex >= 0) {
      if (currentProspectIndex === prospects.length) {
        // setCurrentIndex
        setCurrentProspect(prospects[currentProspectIndex - 1]);
        setCurrentProspectIndex(currentProspectIndex - 1);
      } else {
        setCurrentProspect(prospects[currentProspectIndex]);
        // bug-fix-NA-1144-code-reflection
        setCurrentProspectIndex(currentProspectIndex);
      }
    }
  }, [prospects]);

  const expiryTimestamp = Date.now() + 60 * 5000; // current time + 5 minutes
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      // harry potter mode turned off.
      // _fetchProspects();
    },
  });
  useEffect(() => {
    isRunning && pause();
  }, []);
  const useTimeObj = {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  };

  const RevampFullCardContainer = useCallback(() => {
    return (
      <RevampProspectFullCard
        // key={prospect.id}
        totalProspects={prospects.length}
        profileIndex={currentProspectIndex}
        elementIndex={pageNumber * PER_PAGE_COUNT + currentProspectIndex}
        fullCardTag={fullCardTag}
        setFullCardTag={setFullCardTag}
        showCompanyModal={(e, detected, detectedSpan, titleName) =>
          handleShowCompanyModal(e, detected, detectedSpan)
        }
        closeCompanyModal={() => setShowCompanyModal(false)}
        tabValue={tabValue}
        prospectData={
          currentProspectIndex >= 0 ? prospects[currentProspectIndex] : null
        }
        isDetail={true}
        currentHigLighted={currentHigLighted}
        isSelected={currentProspect?.id in selectedProspects}
        onSelect={(e) => onSelect(e, currentProspect, true)}
        handleFullCardRight={handleFullCardRight}
        handleFullCardLeft={handleFullCardLeft}
        showFullCard={showFullCard}
        handleRecommendedCard={handleRecommendedCard}
        onClickCard={() => {
          window.history.replaceState({}, document.title);
          setShowFullCard(false);
        }}
        onActivitiesofCurrentProspect={handleActivitiesofCurrentProspect} //bugfix-NA-s21-1625 & bugfix-NA-s21-1624
        onClickSave={handleClickSaveProspects}
        onClickComments={() => setTabValue("Comments")}
        onClickMaybe={handleClickMaybeProspects}
        disableFeedback={qParams.fF === "ar"}
        isLoading={isLoading}
      />
    );
  }, [currentProspect, tabValue, fullCardTag]);
  useEffect(() => {
    if (prospects.length && currentProspectIndex) {
      setCurrentProspect(prospects[currentProspectIndex]);
    }
  }, [selectedProspects]);

  // console.log("=============================ScoreAndConnect====================================", {activeProspectVariant})

  // harry potter mode turned off
  // const [showHarryPotterCount, setShowHarryPotterCount] = useState(false);
  // const [showHarryPotter, setShowHarryPotter] = useState(true);

  return (
    <>
      {showMoreProspect ? (
        <MoreProspectsLayout setShowMoreProspect={setShowMoreProspect} />
      ) : (
        <>
          <div
            className={`${showCompanyModal || showFullCard || showRecommendedCard
              ? "prospectBackDrop"
              : ""
              }`}
            style={{
              transition: "all 0.5s ease-in-out",
            }}
          >
            <ProspectLayout
              filterCount={FilterCount}
              showFullCard={showCompanyModal}
              applyInitialFilterSort={() =>
                applyInitialFilterSort(jobPosition, jobId, jId)
              }
              handleClearFilters={handleClearFilters}
              handleFilterToggle={handleFilterToggle}
              handleSortToggle={handleSortToggle}
              setShowMoreProspect={setShowMoreProspect}
              sortDefault={sortDefault}
              _fetchProspects={_fetchProspects}
              showActions={showActions}
              isAllSelected={isAllSelected}
              funnel={funnel}
              activeFunnel={activeFunnel}
              buckets={buckets}
              currentBucket={currentBucket}
              onClickBucket={handleClickBucket}
              folders={folders}
              topMin={topMin}
              topMax={topMax}
              useTimeObj={useTimeObj}
              onSelectAll={onSelectAll}
              onDeSelectAll={onDeSelectAll}
              onSelectApply={onSelectApply}
              onClickSave={handleClickSaveProspects}
              isSavingProspect={isSavingProspect}
              onClickArchive={handleClickArchiveProspects}
              isArchivingProspect={isArchivingProspect}
              isDownloadingProspect={isDownloadingProspect}
              jobTitle={job?.jobTitle}
              commentsExpanded={commentsExpanded}
              onClickComments={setCommentsExpanded}
              selectedProspectComments={selectedProspectComments}
            >
              {qParams.c && (
                <ActionBar
                  SecondaryLabel="Cancel"
                  primaryDisabled={Object.keys(selectProspects).length > 0}
                  onPrimaryClick={() => {
                    console.log("PRIMARY CLIKCED");
                    const element = document.querySelector(
                      "#Action_Bar_Tooltip_0"
                    );
                    console.log("ELEMENT IS: ", element);
                    element.click();
                    // document.querySelector('#Action_Bar_Tooltip_0')?.click()
                  }}
                  onSecondaryClick={() => {
                    push("/jobs?c=true");
                  }}
                />
              )}
              <SortController
                handleSortToggle={handleSortToggle}
                isQuick={isQuick}
              />
              {!advFilterDataIsLoading && advFilterDataIsLoaded && (
                <FilterController
                  FilterCount={FilterCount}
                  setFilterCount={setFilterCount}
                />
              )}

              {isLoading && (
                <div className="prospectLoaderContainer">
                  <Loading />
                </div>
              )}
              {!isLoading && isLoaded && prospects.length === 0 && (
                <div
                  className="prospectLoaderContainer"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "16px",
                  }}
                >
                  <img src={alertCircle1} width="64px" height="64px" />
                  <NotFoundErrorMessage
                    qParams={qParams}
                    filterCount={FilterCount}
                  />
                </div>
              )}
              {!isLoading && (
                <div
                  className={`${showFullCard || showRecommendedCard || showCompanyModal
                    ? "prospectBackDrop"
                    : ""
                    }`}
                  style={{
                    transition: "opacity 0.5s ease-in-out",
                  }}

                // scrollThreshold={activeProspectVariant === 1 ? 0.9 : 0.7}
                >
                  {
                    <TransitionGroup
                      style={{
                        display: "flex",
                        justifyContent:
                          activeProspectVariant === 1
                            ? "space-between"
                            : "space-between",
                        gap:
                          activeProspectVariant === 1
                            ? "2rem 1rem"
                            : "2rem 1rem",
                      }}
                      className="prospectMainContainer"
                      key={"unique key test"}
                    >
                      {recommendedCount?.recommended +
                        (recommendedProfiles?.length
                          ? recommendedProfiles?.length - 1
                          : 0) <
                        11 && search?.includes("rR") ? (
                        recommendedProspectData ? (
                          <RevampProspectMidCard
                            key={0}
                            elementIndex={recommendedProfiles.length - 1}
                            prospectData={recommendedProspectData}
                            addingProspects={true}
                            isOnScoreAndConnectRecommended={true}
                          />
                        ) : (
                          <div className="recommendedLoading">
                            <Loader
                              type="Grid"
                              color="#FF8D4E"
                              width="168"
                              height="160"
                            />
                          </div>
                        )
                      ) : (
                        prospects.map((prospect, index) => {
                          const is_recommended =
                            prospect?.scoring?.is_recommended;
                          //harryPotterMode, shouldShowHarryPotterMode

                          if (is_recommended) {
                            dispatch(addRecommendedCount());
                          }
                          if (harryPotterMode) {
                            if (
                              harryPotterCount >= 5 &&
                              shouldShowHarryPotterMode
                            ) {
                              return (
                                <div
                                  className={`single-prospect ${prospect.hidden ? "hidden" : ""
                                    }`}
                                  key={index}
                                >
                                  <RevampHarryPotterCard
                                    prospectData={prospect}
                                    key={prospect.id}
                                    elementIndex={
                                      parseInt(
                                        "p" in qParams
                                          ? parseInt(qParams.p) || page
                                          : page
                                      ) *
                                      PER_PAGE_COUNT +
                                      index
                                    }
                                    onClickCard={(e) => {
                                      handleClickCard(prospect, e);
                                      setCurrentProspectIndex(index);
                                    }}
                                  />
                                </div>
                              );
                            }
                          }
                          if (activeProspectVariant === 1) {
                            return (
                              <CSSTransition
                                key={prospect.id}
                                timeout={2000}
                                classNames="item"
                                addEndListener={(node, done) =>
                                  node.addEventListener(
                                    "transitionend",
                                    done,
                                    false
                                  )
                                }
                                unmountOnExit
                              >
                                <div
                                  style={{ width: "49%" }}
                                  className={`single-prospect ${prospect.hidden ? "hidden" : ""
                                    }`}
                                  key={index}
                                >
                                  <QuickDetailProfileCard
                                    key={prospect.id}
                                    qParams={qParams}
                                    index={
                                      parseInt(
                                        "p" in qParams
                                          ? parseInt(qParams.p) || page
                                          : page
                                      ) *
                                      PER_PAGE_COUNT +
                                      index
                                    }
                                    profileData={prospect}
                                    isSelected={
                                      prospect.id in selectedProspects
                                    }
                                    onSelect={(e) => {
                                      Mixpanel.track(`select profile checkbox`);
                                      onSelect(e, prospect);
                                    }}
                                    recommended={is_recommended}
                                    showReview={qParams?.fF == "s"}
                                    showCompanyModal={(
                                      e,
                                      initCallback,
                                      finCallback
                                    ) =>
                                      handleShowCompanyModal(
                                        e,
                                        undefined,
                                        undefined,
                                        initCallback,
                                        finCallback
                                      )
                                    }
                                    closeCompanyModal={() =>
                                      setShowCompanyModal(false)
                                    }
                                    handleRecommendedCard={
                                      handleRecommendedCard
                                    }
                                    removeHoverHighlighter={
                                      removeHoverHighlighter
                                    }
                                    onClickSave={handleClickSaveProspects}
                                    onClickMaybe={handleClickMaybeProspects}
                                    onClickCard={(e) => {
                                      Mixpanel.track(`Profile - concise view`);
                                      handleClickCard(prospect, e);

                                      setCurrentProspectIndex(
                                        pageNumber * PER_PAGE_COUNT + index
                                      );
                                    }}
                                    onClickComments={() => {
                                      // console.log("======================onClickComments========================", {prospect, index})
                                      Mixpanel.track(
                                        `Comment Icon - concise view`
                                      );
                                      handleCommentClick(prospect, index);
                                    }}
                                    disableFeedback={qParams.fF === "ar"}
                                  />
                                  {/* <ProfileCard
                                    setIsDetailViewOpen={setIsDetailViewOpen}
                                    key={prospect.id}
                                    timeout={2000}
                                    classNames="item"
                                    addEndListener={(node, done) =>
                                      node.addEventListener(
                                        "transitionend",
                                        done,
                                        false
                                      )
                                    }
                                  /> */}
                                </div>
                              </CSSTransition>
                            );
                          } else if (activeProspectVariant === 2) {
                            return (
                              <CSSTransition
                                key={prospect.id}
                                timeout={2000}
                                classNames="item"
                                addEndListener={(node, done) =>
                                  node.addEventListener(
                                    "transitionend",
                                    done,
                                    false
                                  )
                                }
                                unmountOnExit
                              >
                                <div
                                  style={{ width: "100%" }}
                                  className={`single-prospect ${prospect.hidden ? "hidden" : ""
                                    }`}
                                  key={index}
                                >
                                  <DeepDetailProfileCard
                                    key={prospect.id}
                                    jId={jId}
                                    qParams={qParams}
                                    archRequestCount={archRequestCount}
                                    isQuick={isQuick}
                                    index={
                                      parseInt(
                                        "p" in qParams
                                          ? parseInt(qParams.p) || page
                                          : page
                                      ) *
                                      PER_PAGE_COUNT +
                                      index
                                    }
                                    profileData={prospect}
                                    isSelected={
                                      prospect.id in selectedProspects
                                    }
                                    onSelect={(e) => {
                                      Mixpanel.track(`select profile checkbox`);
                                      onSelect(e, prospect);
                                    }}
                                    recommended={is_recommended}
                                    showReview={qParams?.fF == "s"}
                                    showCompanyModal={(
                                      e,
                                      initCallback,
                                      finCallback
                                    ) =>
                                      handleShowCompanyModal(
                                        e,
                                        undefined,
                                        undefined,
                                        initCallback,
                                        finCallback
                                      )
                                    }
                                    closeCompanyModal={() =>
                                      setShowCompanyModal(false)
                                    }
                                    handleRecommendedCard={
                                      handleRecommendedCard
                                    }
                                    removeHoverHighlighter={
                                      removeHoverHighlighter
                                    }
                                    onClickSave={handleClickSaveProspects}
                                    onClickMaybe={handleClickMaybeProspects}
                                    onClickCard={(e) => {
                                      Mixpanel.track(`Profile - detailed view`);
                                      handleClickCard(prospect, e);
                                      setCurrentProspectIndex(index);
                                    }}
                                    onClickComments={() => {
                                      // console.log("======================onClickComments========================", {prospect, index})
                                      Mixpanel.track(
                                        `Comment Icon - detailed view`
                                      );
                                      handleCommentClick(prospect, index);
                                    }}
                                    disableFeedback={qParams.fF === "ar"}
                                  />
                                </div>
                              </CSSTransition>
                            );
                          }
                        })
                      )}
                    </TransitionGroup>
                  }
                </div>
              )}
            </ProspectLayout>
          </div>
          <div
            className={`companyModal ${showCompanyModal ? "showCompanyModal" : ""
              }`}
          >
            <CompanyCard
              {...companyData}
              onClick={() => setShowCompanyModal(false)}
            />
          </div>
          <div
            className={`fullCardView ${showFullCard ? "showFullCard" : ""}`}
            onClick={() => {
              window.history.replaceState({}, document.title);
              setShowFullCard(false);
            }}
          >
            <CSSTransition
              in={currentProspect && showFullCard}
              timeout={500}
              mountOnEnter
              unmountOnExit
            >
              {RevampFullCardContainer()}
            </CSSTransition>
          </div>
          <div
            className={`fullCardView ${showRecommendedCard ? "showFullCard" : ""
              }`}
            onClick={() => setShowRecommendedCard(false)}
          >
            <CSSTransition
              in={currentProspect && showRecommendedCard}
              timeout={500}
              mountOnEnter
              unmountOnExit
            >
              <RecommendedProspectFullCard
                // key={prospect.id}
                // elementIndex={index}
                showCompanyModal={(e) => handleShowCompanyModal(e)}
                closeCompanyModal={() => setShowCompanyModal(false)}
                tabValue={tabValue}
                prospectData={currentProspect}
                isDetail={true}
                isSelected={currentProspect?.id in selectedProspects}
                onSelect={(e) => onSelect(e, currentProspect)}
                showFullCard={showFullCard}
                onClickCard={() => setShowRecommendedCard(false)}
                onClickSave={handleClickSaveProspects}
                onClickComments={() => setTabValue("Comments")}
              />
            </CSSTransition>
          </div>
        </>
      )}{" "}
    </>
  );
};

export default connect((state) => ({
  similarCompanies: state.jobSetup.companyDetails.similarCompanies,
  prospects: state.prospectsView.candidates.prospects,
  allProspects: state.prospectsView.candidates.allProspects,
  selectedProspects: state.prospectsView.candidates.selectedProspects,
  isLoading: state.prospectsView.candidates.isLoading,
  isLoaded: state.prospectsView.candidates.isLoaded,
  advFilterDataIsLoading: state.prospectsView.advFiltersData.isLoaded,
  advFilterDataIsLoaded: state.prospectsView.advFiltersData.isDataAvailable,
  runInfo: state.prospectsView?.advFiltersData?.data?.runInfo,
  funnel: state.prospectsView.candidates.funnel,
  buckets: state.prospectsView.candidates.buckets,
  candidates: state.prospectsView.candidates,
  currentBucket: state.prospectsView.candidates.currentBucket,
  firstBucket: state.prospectsView.candidates.firstBucket,
  folders: state.prospectsView.candidates.folders,
  meta: state.prospectsView.meta,
  isSortOpen: state.prospectsView.sorting.isOpen,
  ScoringFilters: state.prospectsView.scoringFiltersMeta,
  AdvFilters: state.prospectsView.advFiltersMeta,
  filters: state.prospectsView.advBooleanFilters.filters,
  fS: state.prospectsView.sorting.fS,
  fSO: state.prospectsView.sorting.fSO,
  secS: state.prospectsView.sorting.secS,
  secSO: state.prospectsView.sorting.secSO,
  job: state.prospectsView.candidates.job,
  selectedProspectComments:
    state.prospectsView.candidates.selectedProspectComments,
  activeProspectVariant:
    state.prospectsView.prospectVariant.activeProspectVariant,
  recommendedCount: state.prospectsView.candidates.recommendedCount,
  recommendedProspectData:
    state.prospectsView.candidates.currentRecommendedProfile,
  recommendedProfiles: state.prospectsView.candidates.recommendedProfiles,
  jobPosition: state.prospectsView.candidates.job.jobArray?.job_position,
  suggestedTitles:
    state.prospectsView?.candidates?.job?.jobArray?.suggested_titles,
  jobId: state.prospectsView.candidates.job.id,
  harryPotterMode: state.prospectsView.prospectVariant.harryPotterMode,
  shouldShowHarryPotterMode:
    state.prospectsView.prospectVariant.shouldShowHarryPotterMode,
  harryPotterCount: state.prospectsView.prospectVariant.harryPotterCount,
  pageNumber: state.prospectsView.prospectVariant.pageNumber,
  page: state.prospectsView.candidates.page,
  polling: state.notifications?.notifications?.polling,
}))(ScoreAndConnect);
