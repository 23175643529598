import { initializeApp } from 'firebase/app';
import { deleteUser, getAuth, RecaptchaVerifier, signInWithCustomToken, signInWithPhoneNumber, updateEmail } from "firebase/auth";
import { flattenDeep, get, isEmpty, uniqBy } from 'lodash';
import moment from "moment";
import { useEffect, useState } from "react";
import { decrypt } from './encryption1';
export * from "./transformCsvData";


export const extractPublicIdentifier = (profileUrl = "") => {
  console.log(
    "===============extractPublicIdentifier=====================",
    profileUrl
  );

  const profileUrlMatch = profileUrl.split("/in/");
  if (
    /linkedin\.com\/in\//gi.test(profileUrl) &&
    profileUrlMatch.length > 1 &&
    profileUrlMatch?.[1] !== ""
  ) {
    return profileUrlMatch?.[1]?.replace("/", "").split("?")?.[0];
  }
  return false;
};

export const refreshIdToken = async (refresh_token) => {
  try {
    if (!refresh_token) {
      return false
    }
    const data = {
      grant_type: "refresh_token",
      refresh_token
    }
    const formBody = [];
    for (let property in data) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    let body = formBody.join("&");
    return await fetch(`https://securetoken.googleapis.com/v1/token?key=${process.env.REACT_APP_FIREBASE_API_KEY}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body
    }).then(r => r.json())
  }
  catch (err) {
    console.log("Error: ", err?.message)
    return false
  }
}

export const manualApiCall = async (endpoint, options, aT) => {
  const user = JSON.parse(localStorage.getItem("user"));
  // console.log("OPTIONS ARE: ", options);
  const opts = {
    ...options,
    headers:
      options.method === "POST"
        ? {
          ...options.headers,
          Authorization: aT || user?.token,
          aot: !!aT,
          "Content-Type": "application/json",
        }
        : {
          ...options.headers,
          Authorization: aT || user?.token,
          aot: !!aT
        },
  };
  // console.log('OPTS ARE: ', opts)
  try {
    const r = fetch(`${process.env.REACT_APP_API_HOST}${endpoint}`, opts)
      .then(async (r) => {
        const resBody = await r?.json()
        if (r.status === 401 && !aT) {
          // console.log("=============resBody==============", {resBody, user})
          if (['auth/id-token-expired', 'auth/argument-error'].includes(resBody?.reason)) {

            const isRefreshing = JSON.parse(localStorage.getItem("token_refreshing"))
            // console.log("=====================isRefreshing========================", {endpoint, isRefreshing})
            if (isRefreshing) {
              const waitForToken = async () => await new Promise((resolve, reject) => setInterval(() => !JSON.parse(localStorage.getItem("token_refreshing")) && resolve(), 1000))
              await waitForToken()
              const refreshedUser = JSON.parse(localStorage.getItem("user"));

              // console.log("=====================refreshedUser==========0=============", {endpoint, isRefreshing, refreshedUser}, "is token updated", refreshedUser?.token!==user.token)

              if (refreshedUser?.token) {
                // console.log("=========================refreshedUser==============1==============", {endpoint, isRefreshing, refreshedUser}, "is token updated", refreshedUser?.token!==user.token)
                return await fetch(`${process.env.REACT_APP_API_HOST}${endpoint}`, { ...opts, headers: { ...opts.headers, Authorization: refreshedUser?.token } }).then(r => r.json())
              }
            }
            else {
              let tokens = false

              // console.log("=====================tokens===========1=============", {endpoint, isRefreshing})

              try {
                localStorage.setItem("token_refreshing", true)
                tokens = await refreshIdToken(user.sw)

                // console.log("====================tokens============0===========", {endpoint, isRefreshing, tokens, user}, "is token updated", tokens?.id_token!==user.token)
              }
              catch (err) {
                console.log("ERROR: ", err.massage)
              }
              finally {
                localStorage.setItem("token_refreshing", false)
              }
              if (tokens?.id_token && tokens?.refresh_token && !isEmpty(user)) {
                // console.log("====================tokens=============1==========", {endpoint, isRefreshing, tokens, user}, "is token updated", tokens?.id_token!==user.token)
                localStorage.setItem('user', JSON.stringify({ ...user, token: tokens?.id_token, sw: tokens?.refresh_token }))
                return await fetch(`${process.env.REACT_APP_API_HOST}${endpoint}`, { ...opts, headers: { ...opts.headers, Authorization: tokens?.id_token } }).then(r => r.json())
              }
            }
            return resBody

          }
          else {
            return resBody;
          }
        } else if (!((r.status > 199) & (r.status <= 300))) {
          throw new Error("Error: api failed");
        } else {
          return resBody;
        }
      })
      .catch((e) => {
        console.log("API ERROR: ", e);
        return ({
          isAuthorized: false,
          message: "Api error"
        })
      });
    return r;
  } catch (e) {
    throw e;
  }
};

export const manualPublicApiCall = async (endpoint, options) => {
  console.log("OPTIONS ARE: ", options);
  const opts = {
    ...options,
    headers:
      options.method === "POST"
        ? {
          ...options.headers,
          "Content-Type": "application/json",
        }
        : {
          ...options.headers
        },
  };
  // console.log('OPTS ARE: ', opts)
  try {
    const r = fetch(`${process.env.REACT_APP_API_HOST}${endpoint}`, opts)
      .then(async (r) => {
        const resBody = await r?.json()
        if (r.status === 401) {
          console.log("=============resBody==============", { resBody })
          return resBody;
        } else if (!((r.status > 199) & (r.status <= 300))) {
          throw new Error("Error: api failed");
        } else {
          return resBody;
        }
      })
      .catch((e) => {
        console.log("API ERROR: ", e);
        return ({
          message: "Api error"
        })
      });
    return r;
  } catch (e) {
    throw e;
  }
};


export const isMobileNumberValid = n => (/^\+(?:[0-9]●?){6,14}[0-9]$/).test(n)

export const getImgSrcURL = (rootURL = "", artifacts = []) => {
  return Array.isArray(artifacts)
    ? `${rootURL}${artifacts?.sort((a, b) => b?.width - a?.width)?.pop()
      ?.fileIdentifyingUrlPathSegment || ""
    }`
    : "";
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getMaxKey = (obj) => {
  let maxKey;
  let maxUnset = true;
  console.log("===============getMaxKey================", obj);
  Object.keys(obj).forEach((key, i) => {
    if (maxUnset && key !== "") {
      maxKey = key;
      maxUnset = false;
    } else if (key !== "" && parseFloat(obj[key]) > parseFloat(obj[maxKey])) {
      maxKey = key;
    }
  });
  return maxKey;
};

export const capitalizeCase = (str) => {
  try{
    const lowerStr = str?.toLowerCase();
    return str.charAt(0).toUpperCase() + lowerStr.slice(1);
  }
  catch (err){
    console.log("Error: ", err.massage)
    return ""
  }
};

export const getYrsAndMos = (span) => {
  const yrs = parseInt(span);
  const remaining = span - yrs;
  const mos = parseInt(remaining * 12);
  return `${yrs > 0 ? yrs + " yrs" : ""}${mos > 0 ? (yrs > 0 ? " " : "") + mos + " mos" : ""
    }`;
};

export const getShortName = (input = "") => {
  var matches = input.match(/\b(\w)/g);
  return matches.join("").toUpperCase();
};

export const validateEmail = (email = "") => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
export const validatePhone = (phone = "") => {
  // const re = /^\+[0-9] ([0-9]{3} ){2}[0-9]{4}$/;  //TODO: revise this regex
  const re = /^\+?[0-9]+$/;
  return re.test(String(phone));
};


export const validatePassword = (password) => {
  const passwordRegex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#=+/{}<>,;:'"~`%&._\-\(\)\[\]\|\\\$\^\*\?])(?=.{8,})/);
  // console.log("==============validatePassword==========0==========", String(password) )
  // console.log("==============validatePassword==========1==========", passwordRegex.test(String(password)))
  return passwordRegex.test(String(password));
}

export const findAllIndices = (str = "", searchStr = "") => {
  if (!str || !searchStr) {
    return [];
  }
  let result;
  const regex = new RegExp(searchStr.replace(/\+/g, "\\+"), "gi");
  const indices = [];
  while ((result = regex.exec(str))) {
    indices.push({ start: result.index, end: result.index + searchStr.length });
  }
  return indices;
};

export const simulateTimeout = (ms) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve("Hello world!");
    }, ms);
  });

export const prepareIdealProfiles = (profiles = []) =>
  profiles.map((profile) => ({
    ...profile,
    current_company_name:
      profile?.current_company_name?.replace(/'/g, "''") || "",
    headline: profile?.headline?.replace(/'/g, "''") || "",
    schoolName: profile?.schoolName?.replace(/'/g, "''") || "",
    fieldOfStudy: profile?.fieldOfStudy?.replace(/'/g, "''") || "",
    summary: profile?.summary?.replace(/'/g, "''") || "",
    title: profile?.title?.replace(/'/g, "''") || "",
    education:
      profile?.education?.map((education) => ({
        ...education,
        fieldOfStudy: education?.fieldOfStudy?.replace(/'/g, "''") || "",
        schoolName: education?.schoolName?.replace(/'/g, "''") || "",
        degreeName: education?.degreeName?.replace(/'/g, "''") || "",
      })) || [],
    experience:
      profile?.experience?.map((experience) => ({
        ...experience,
        company_name: experience?.company_name?.replace(/'/g, "''") || "",
        description: experience?.description?.replace(/'/g, "''") || "",
        title: experience?.title?.replace(/'/g, "''") || "",
      })) || [],
    skills: profile?.skills?.map((skill) => skill.replace(/'/g, "''")) || [],
  }));

export const getTagTranslation = (template) =>
  template
    .replace(/{{\.First_Name}}/g, "First Name ✕")
    .replace(/{{\.Last_Name}}/g, "Last Name ✕")
    .replace(/{{\.Full_Name}}/g, "Full Name ✕")
    .replace(/{{\.Current_Title}}/g, "Current Title ✕")
    .replace(/{{\.Job_Title}}/g, "Job Title ✕")
    .replace(/{{\.My_Name}}/g, "My Name ✕")
    .replace(/{{\.My_First_Name}}/g, "My First Name ✕")
    .replace(/{{\.My_Full_Name}}/g, "My Full Name ✕")
    .replace(/{{\.User_Name}}/g, "User Name ✕")
    .replace(/{{\.School_Name}}/g, "School Name ✕")
    .replace(/{{\.Current_Company}}/g, "Current Company ✕")
    .replace(/{{\.Hiring_Company}}/g, "Hiring Company ✕");

export const getTagTranslationWithoutRemove = (template) =>
  template
    .replace(/{{\.First_Name}}/g, "First Name")
    .replace(/{{\.Last_Name}}/g, "Last Name")
    .replace(/{{\.Full_Name}}/g, "Full Name")
    .replace(/{{\.Current_Title}}/g, "Current Title")
    .replace(/{{\.Job_Title}}/g, "Job Title")
    .replace(/{{\.My_Name}}/g, "My Name")
    .replace(/{{\.My_First_Name}}/g, "My First Name")
    .replace(/{{\.My_Full_Name}}/g, "My Full Name")
    .replace(/{{\.User_Name}}/g, "User Name")
    .replace(/{{\.School_Name}}/g, "School Name")
    .replace(/{{\.Current_Company}}/g, "Current Company")
    .replace(/{{\.Hiring_Company}}/g, "Hiring Company");

export const getReverseTagTranslation = (template) =>
  template
    .replace(/My Name ✕/g, "{{.My_Name}}")
    .replace(/My First Name ✕/g, "{{.My_First_Name}}")
    .replace(/My Full Name ✕/g, "{{.My_Full_Name}}")
    .replace(/First Name ✕/g, "{{.First_Name}}")
    .replace(/Last Name ✕/g, "{{.Last_Name}}")
    .replace(/Full Name ✕/g, "{{.Full_Name}}")
    .replace(/Current Title ✕/g, "{{.Current_Title}}")
    .replace(/Job Title ✕/g, "{{.Job_Title}}")
    .replace(/User Name ✕/g, "{{.User_Name}}")
    .replace(/School Name ✕/g, "{{.School_Name}}")
    .replace(/Current Company ✕/g, "{{.Current_Company}}")
    .replace(/Hiring Company ✕/g, "{{.Hiring_Company}}");

export const getReverseTagTranslationWithoutRemove = (template) =>
  template
    .replace(/My Name/g, "{{.My_Name}}")
    .replace(/My First Name/g, "{{.My_First_Name}}")
    .replace(/My Full Name/g, "{{.My_Full_Name}}")
    .replace(/First Name/g, "{{.First_Name}}")
    .replace(/Last Name/g, "{{.Last_Name}}")
    .replace(/Full Name/g, "{{.Full_Name}}")
    .replace(/Current Title/g, "{{.Current_Title}}")
    .replace(/Job Title/g, "{{.Job_Title}}")
    .replace(/User Name/g, "{{.User_Name}}")
    .replace(/School Name/g, "{{.School_Name}}")
    .replace(/Current Company/g, "{{.Current_Company}}")
    .replace(/Hiring Company/g, "{{.Hiring_Company}}");

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
};

export const useOnScreen = (ref) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) => {
    if (entry.isIntersecting) {
      setTimeout(() => {
        let height = entry.target.offsetHeight;
        if (height) {
          entry.target.style.minHeight = `${height}px`;
        }
      }, 1000);
    }
    setIntersecting(entry.isIntersecting);
  });

  useEffect(() => {
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
};

export const getInitials = (name = "") => {
  let initials = "";
  if (name) {
    const [firstName, lastName] = name.split(" ");
    if (firstName) {
      initials = `${initials}${firstName.slice(0, 1).toUpperCase()}`;
    }
    if (lastName) {
      initials = `${initials}${lastName.slice(0, 1).toUpperCase()}`;
    }
  }

  return initials;
};

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const kiloConversion = (n) => {
  if (n / 1000 >= 1) {
    return `${parseInt(n / 1000)}K`;
  }
  return n;
};

export function smoothScroll(elem, options) {
  return new Promise((resolve) => {
    if (!(elem instanceof Element)) {
      console.log("Argument 1 must be an Element");
    }
    let same = 0;
    let lastPos = null;
    const scrollOptions = Object.assign({ behavior: "smooth" }, options);

    try {
      elem.scrollIntoView(scrollOptions);
      requestAnimationFrame(check);
      function check() {
        const newPos = elem.getBoundingClientRect().top;

        if (newPos === lastPos) {
          if (same++ > 2) {
            return resolve();
          }
        } else {
          same = 0;
          lastPos = newPos;
        }
        requestAnimationFrame(check);
      }
    } catch {
      console.log("Argument 1 must be an Element");
    }
  });
}

export function isElementInViewport(el) {
  if (!(el instanceof Element)) {
    console.log("Argument 1 must be an Element");
  }

  var rect = el.getBoundingClientRect();

  return (
    rect.bottom >= 0 &&
    rect.right >= 0 &&
    rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.left <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export const getSelectionText = () => {
  var text = "";
  if (window.getSelection) {
    text = window.getSelection().toString();
  } else if (document.selection && document.selection.type != "Control") {
    text = document.selection.createRange().text;
  }
  return text;
};

export const callLiApi = async (endpoint, options, retries = 0) => {
  const controller = new AbortController();
  const signal = controller.signal;
  const user = JSON.parse(localStorage.getItem("user"));

  try {
    // console.log("====================================", process.env)
    // console.log("==================================================", process.env.REACT_APP_RONIN_API_HOST)
    const resp = await fetch(
      `${process.env.REACT_APP_RONIN_API_HOST}/p2p/ronin`,
      {
        ...options,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: user.token,
        },
        body: JSON.stringify({
          m: btoa("g"),
          h: btoa(JSON.stringify(options.headers)) || {},
          e: btoa(endpoint),
          b: null,
        }),
      }
    );

    let data = await resp.json();
    if (data?.success == false) {
      data.error = decrypt(data.error)
    } else {
      data = decrypt(data.data)
    }

    return { data };
  } catch (e) {
    if (retries > 0) {
      callLiApi(endpoint, options, retries - 1)
    } else {
      throw e;
    }
  }
};




export const fetchPlus = async (url, options = {}, retries = 0) => {
  try {
    const data = await fetch(url, options).then(r => r.json())
    return data
  } catch (e) {
    if (retries > 0) {
      fetchPlus(url, options, retries - 1)
    } else {
      throw e
    }
  }
}



const API_RETRY_LIMIT = 3;

export const callLiWrapperApi = async (t, b, v) => {
  // implementing a retryfunction for thelinkedin apis
  // const controller = new AbortController();
  // const signal = controller.signal;
  const user = JSON.parse(localStorage.getItem("user"));
  let retryCount = 0;
  while (retryCount < API_RETRY_LIMIT) {
    try {
      const resp = await fetchPlus(
        `${process.env.REACT_APP_RONIN_API_HOST}/p2p/ronin/wrapper`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: user.token,
          },
          body: JSON.stringify({
            b: null,
            t: btoa(t),
            v: btoa(JSON.stringify(v)) || {},
          }),
        },
        2
      );
      let data = resp; //await resp.json();
      if (data?.success == false) {
        data.error = decrypt(data.error);
      } else {
        data = decrypt(data.data);
      }
      return { data };
    } catch (e) {
      if (retryCount === API_RETRY_LIMIT - 1) {
        throw new Error(`Failed after ${API_RETRY_LIMIT} retries. Error: ${e.message}`);
      }
      retryCount++;
    }
  }
};

// System for American Numbering
var th_val = ["", "thousand", "million", "billion", "trillion"];
// System for uncomment this line for Number of English
// var th_val = ['','thousand','million', 'milliard','billion'];

var dg_val = [
  "zero",
  "one",
  "two",
  "three",
  "four",
  "five",
  "six",
  "seven",
  "eight",
  "nine",
];
var tn_val = [
  "ten",
  "eleven",
  "twelve",
  "thirteen",
  "fourteen",
  "fifteen",
  "sixteen",
  "seventeen",
  "eighteen",
  "nineteen",
];
var tw_val = [
  "twenty",
  "thirty",
  "forty",
  "fifty",
  "sixty",
  "seventy",
  "eighty",
  "ninety",
];
export function toWordsconver(s) {
  s = s.toString();
  s = s.replace(/[\, ]/g, "");
  if (s != parseFloat(s)) return "not a number ";
  var x_val = s.indexOf(".");
  if (x_val == -1) x_val = s.length;
  if (x_val > 15) return "too big";
  var n_val = s.split("");
  var str_val = "";
  var sk_val = 0;
  for (var i = 0; i < x_val; i++) {
    if ((x_val - i) % 3 == 2) {
      if (n_val[i] == "1") {
        str_val += tn_val[Number(n_val[i + 1])] + " ";
        i++;
        sk_val = 1;
      } else if (n_val[i] != 0) {
        str_val += tw_val[n_val[i] - 2] + " ";
        sk_val = 1;
      }
    } else if (n_val[i] != 0) {
      str_val += dg_val[n_val[i]] + " ";
      if ((x_val - i) % 3 == 0) str_val += "hundred ";
      sk_val = 1;
    }
    if ((x_val - i) % 3 == 1) {
      if (sk_val) str_val += th_val[(x_val - i - 1) / 3] + " ";
      sk_val = 0;
    }
  }
  if (x_val != s.length) {
    var y_val = s.length;
    str_val += "point ";
    for (var i = x_val + 1; i < y_val; i++) str_val += dg_val[n_val[i]] + " ";
  }
  return str_val.replace(/\s+/g, " ");
}

const titleHealthThreshold = (local_loc, us_loc, threshold = 10) => {
  console.log(
    "====================titleHealthThreshold==========================",
    {
      local_loc,
      us_loc,
      threshold,
      ratio: us_loc / local_loc,
    }
  );
  return us_loc / local_loc > threshold;
};

export const titleHealthPrediction = (localLoc, usLoc, compLoc) => {
  if (
    titleHealthThreshold(localLoc, usLoc) &&
    titleHealthThreshold(localLoc, compLoc)
  ) {
    return {
      message: "Good Title",
      health: true,
    };
  } else if (titleHealthThreshold(localLoc, usLoc) && compLoc > 0) {
    return {
      message: "Bad Employee Count",
      health: false,
    };
  } else if (titleHealthThreshold(localLoc, compLoc) && usLoc > 0) {
    return {
      message: "Bad Location",
      health: false,
    };
  }
  return {
    message: "Bad Title",
    health: false,
  };
};

export const isRecruiterUrnFetchable = ({
  fullName,
  currentCompanyName,
  title,
}) =>
  typeof fullName === "string" &&
  fullName.trim() !== "" &&
  typeof currentCompanyName === "string" &&
  currentCompanyName.trim() !== "" &&
  typeof title === "string" &&
  title.trim() !== "";

export const jsonParse = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
};

export const sortBy = (collection, property) => {
  return collection.sort((a, b) => (a[property] > b[property] ? -1 : 1));
};

export const getFirebaseApp = () => {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  };

  return initializeApp(firebaseConfig);
}

export const signInToFirebase = async ({ token, emailAddress }) => {
  try {

    const auth = getAuth(getFirebaseApp());
    const userCredential = await signInWithCustomToken(auth, token)
    console.log("======================userCredential=============================", userCredential?.user)
    if (!userCredential?.user?.email) {
      updateEmail(auth.currentUser, emailAddress).catch(err => console.log("Error: ", err?.massage))
    }
    return userCredential?.user
  }
  catch (err) {
    console.log("SIGN IN ERROR: ", err.message)
  }

}

export const getRecaptcha = async (recaptchaId = 'sign-in-button', onSubmit = () => false) => {
  console.log("==============getRecaptcha=================", { recaptchaId, onSubmit })
  const auth = getAuth(getFirebaseApp());
  console.log("==============auth=================", { auth })
  if(!window.recaptchaVerifier){
    window.recaptchaVerifier = new RecaptchaVerifier(recaptchaId, {
      'size': 'invisible',
      'callback': onSubmit,
      'expired-callback': () => {
        getRecaptcha(recaptchaId, onSubmit)
      }
    }, auth);
  }
  window.recaptchaVerifier.render().then((widgetId) => {
    window.recaptchaWidgetId = widgetId;
  });
  console.log("==============getRecaptcha=================", window.recaptchaVerifier)

}

export const send2FACode = (phoneNumber = false, callback = () => false, fallBack = () => false) => {
  try {
    const appVerifier = window.recaptchaVerifier;
    if (!phoneNumber || !appVerifier) {
      throw new Error(`Data missing${phoneNumber ? "" : ", phoneNumber"}${appVerifier ? "" : ", appVerifier"}`,)
    }
    const auth = getAuth(getFirebaseApp());
    const phone = phoneNumber?.[0] === '+' ? phoneNumber : '+' + phoneNumber
    // console.log("=================signInWithPhoneNumber====================", {phone, phoneNumber})
    signInWithPhoneNumber(auth, phone, appVerifier)
      .then((confirmationResult) => {
        callback(confirmationResult)
        window.confirmationResult = confirmationResult;
      }).catch((error) => {
        fallBack(error)
        if (error.message.includes("auth/too-many-requests")) {
          alert("Too many request, try again later")
        }
        console.log(error.message)
      });
  }
  catch (err) {
    console.log("Error: ", err.message)
  }
}

export const deleteFirebaseUser = (user) => {
  try {
    // console.log("===============deleteFirebaseUser==================", {user})
    deleteUser(user)
  }
  catch (err) {
    console.log(" deleteFirebaseUser ERROR: ", err.massage)
  }
}

export const getFirebaseCurrentUser = () => getAuth(getFirebaseApp()).currentUser
export const SignoutFirebase = () => getAuth(getFirebaseApp()).signout


export const getDefaultFilters = (jobArray) => {
  let fS = 'title_score',
    fSO = 'desc',
    secS = 'title_score',
    secSO = 'desc'

  // console.log("JOB ARRAY IS: ", jobArray)

  const companySizes = [{
    value: '1-10',
    min: 1,
    max: 10
  }, {
    value: '11-50',
    min: 11,
    max: 50
  }, {
    value: '51-200',
    min: 51,
    max: 200
  }, {
    value: '201-500',
    min: 201,
    max: 500
  }, {
    value: '501-1000',
    min: 501,
    max: 1000
  }, {
    value: '1001-5000',
    min: 1001,
    max: 5000
  }, {
    value: '5001-10000',
    min: 5001,
    max: 10000
  }, {
    value: '10001+',
    min: 10001,
    max: 10001
  },]

  const jobPositionMinSize = get(jobArray, ['job_position', '0', 'company_size', '0', 'min'], null)
  const jobPositionMaxSize = get(jobArray, ['job_position', '0', 'company_size', '0', 'max'], null)

  const suggestedTitlesCompSizes = get(jobArray, ['suggested_titles'], []).map(item =>
  ({
    min: get(item, ['company_size', '0', 'min'], null),
    max: get(item, ['company_size', '0', 'max'], null)
  })
  )



  const sizes = [{ min: jobPositionMinSize, max: jobPositionMaxSize }, ...suggestedTitlesCompSizes]

  // console.log("ALL SIZES ARE: ", sizes)

  const filteredSizes = uniqBy(flattenDeep(uniqBy(sizes, (item) => { return `${item.min}_${item.max}` }).map(item => {
    // console.log("ITEM IS: ", item)
    const ranges = companySizes.filter(it => item.min === 1 ? it.min >= item.min : it.min >= item.min + 1 && it.max <= item.max)
    // console.log("ranges IS: ", ranges)
    return ranges
  })), 'value')

  // console.log("filtered sizes are: ", filteredSizes)



  // console.log("COMPANY SIZES ARE: ", filteredSizes)
  // const hCSR = filteredSizes.map(item => item.min === 10000 ? `${item.min + 1}+` : `${item.min + 1}-${item.max}`).join('||')
  // console.log("hcsr:", hCSR)
  // console.log("FILTERED HCSR SIZES: ", filteredSizes)
  const hCSR = filteredSizes.map(item => item.value).join('||')


  const rank = get(jobArray, ['job_position', '0', 'rank'], 7)
  // console.log("RANK IS: ", rank)
  if (rank >= 8) {
    secS = 'title_score'
    secSO = 'desc'
  }

  return {
    fS,
    fSO,
    secS: '',
    secSO: '',
    hCSR: ''
  }
}
export const getValidDate = (dateString = "") => {
  // console.log("get valid date: ", dateString)
  const dateStr = dateString.replace(/-/g, " ")
  // console.log("================getValidDate", {dateString, dateStr}, dateStr?.trim()?.split(" ")?.length)
  if (dateStr?.split(" ")?.length === 1) {
    const returnDate = dateStr.trim() === "" ? "" : moment(dateStr, "YYYY").format("YYYY")
    // console.log("================returnDate==============0============", {dateString, dateStr, returnDate})
    return returnDate
  }
  else if (dateStr?.trim()?.split(" ")?.length === 2) {
    // console.log("================returnDate==============00============", {dateString, dateStr}, dateStr?.trim()?.split(" ")[0].length)
    if (dateStr?.trim()?.split(" ")[0].split("").length < 3) {
      if (dateStr?.trim()?.split(" ")[0].split("").length < 2) {
        const returnDate = moment(dateStr, "M YYYY").format("MMM YYYY")
        // console.log("================returnDate============1==============", {dateString, dateStr, returnDate})
        return returnDate
      }
      const returnDate = moment(dateStr, "MM YYYY").format("MMM YYYY")
      // console.log("================returnDate=============2=============", {dateString, dateStr, returnDate})
      return returnDate
    }
    const returnDate = moment(dateStr, "MMM YYYY").format("MMM YYYY")
    // console.log("================returnDate===============3===========", {dateString, dateStr, returnDate})
    return returnDate
  }
  else if (dateStr?.split("-")?.length === 3) {
    const returnDate = moment(dateStr, "DD MM YYYY").format("MMM YYYY")
    // console.log("================returnDate================4==========", {dateString, dateStr, returnDate})
    return returnDate
  }
  // console.log("================returnDate================5==========", { dateString, dateStr })
  return dateStr
}

export const getTaggedText = (
  tags = [], indices = [], description
) => {
  let newDescription = description
  let adjustingIndexOffset = 0;
  indices.map((indice) => {
    let startIndex = indice[0];
    let endIndex = indice[1];
    newDescription = newDescription.slice(0, parseInt(startIndex) + adjustingIndexOffset) + `<p>` + newDescription.slice(
      parseInt(startIndex) + adjustingIndexOffset,
      parseInt(endIndex) + adjustingIndexOffset
    ) + "</p>" + newDescription.slice(parseInt(endIndex) + adjustingIndexOffset);

    adjustingIndexOffset += `<p>`.length + `</p>`.length;
  });
  return newDescription;
}

export const getTitleProfileCount = async (name, locations) => {
  const { data } = await callLiWrapperApi("JobTitle", null, { name, locations }).then((r) => r.data);
  const value = data?.searchDashClustersByAll?.metadata?.totalResultCount || -1
  return value
}

export const demographicsFormatter = (age, gender, ethnicity) => {
  const ethn = ethnicity
    ? capitalizeCase(
      (() => {
        try {
          if(typeof ethnicity === "object"){
              return getMaxKey(ethnicity);
          }
          const obj = JSON.parse(ethnicity);
          if (typeof obj === "object" && obj !== null) {
            return getMaxKey(obj);
          } else {
            return ethnicity;
          }
        } catch (e) {
          return ethnicity;
        }
      })()
    )
    : "";
  let demographicsText = `${age} ${gender
    ? capitalizeCase((() => {
      try {
        if(typeof gender === "object"){
            return getMaxKey(gender);
        }
        const obj = JSON.parse(gender);
        if (typeof obj === "object" && obj !== null) {
          return getMaxKey(obj);
        } else {
          return gender;
        }
      } catch (e) {
        return gender;
      }
    })()
    )
    : ""
    } ${ethn == "Latino"
      ? "Hispanic"
      : ethn.toLowerCase() == "african-american"
        ? "Black"
        : ethn
    }`;
  return [null, "null", "", undefined, "undefined"].includes(
    demographicsText.trim()
  ) ? (
    <p className="noneFound">Not available</p>
  ) : (
    <p>{demographicsText}</p>
  );
};