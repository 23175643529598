import React, { useState } from "react";
import NewAutoSuggestion from "../../../components/NewAutoSuggestion/NewAutoSuggestion";
import "./CompanyPillSuggetions.scss";
import CrossSvg from "../../../components/svgComponents/CrossSvg";
import {
    fetchJobCompanySuggestion,
    changeJobCompanyName,
    addToMoreJobcompany,
    fetchJobSimilarCompanies
  } from "../../../actions/JobSetup";
import { connect } from "react-redux";
const CompanyPillSuggetions = ({
  dispatch,
  companyName,
  companySuggestions,
}) => {
  const [editingMode, setEditingMode] = useState(false);
  const handleEditor = () => {
    setEditingMode(!editingMode);
  };
  const handlerChangeJobCompanyName = (e) => {
    dispatch(changeJobCompanyName(e.target.value));
  };
  const handlerGetJobCompanyProfile = (value) => {
    setEditingMode(false)
    dispatch(addToMoreJobcompany(value));
    dispatch(fetchJobSimilarCompanies(value.urn));
  };
  const handlerFetchJobCompanySuggestion = (value) => {
    dispatch(fetchJobCompanySuggestion(value));
    console.log("*********companySuggestions************", companySuggestions);
  };
  return (
      <div className="addPillSuggetionsConatiner">
        {editingMode ? (
          <div className="editPillSuggetionsConatiner">
            <span className="pillSuggetionInputContainer">
              <NewAutoSuggestion
                suggestionsArray={companySuggestions}
                handleSuggestionsFetchRequested={
                  handlerFetchJobCompanySuggestion
                }
                placeholder="ACME lnc."
                inputValue={companyName}
                handleChangeInput={handlerChangeJobCompanyName}
                handleSuggestionSelected={handlerGetJobCompanyProfile}
                //handleSuggestionsClearRequested={() => setSuggestionsArray([])}
              />
            </span>
            <button onClick={handleEditor} >
              <CrossSvg width="16" height="16" strokeWidth="3" />
            </button>
          </div>
        ) : (
          <button
            color="primary"
            outline
            className="addPillSuggetionsButton"
            onClick={handleEditor}
          >
            Add More
          </button>
        )}
      </div>
  );
};

export default connect((state) => ({
  companySuggestions: state.jobSetup.companyDetails.companySuggestions,
  companyName: state.jobSetup.companyDetails.companyName,
}))(CompanyPillSuggetions);
