import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import useInterval from 'use-interval';
import { setUser } from '../../actions/auth';
import DigitInput from '../../components/DigitInput/DigitInput';
import { getRecaptcha, manualApiCall, send2FACode, signInToFirebase } from '../../utils';

function Verify2FA({
    location,
    history,
    dispatch,
    email,
    phone,
}) {

    const userData = JSON.parse(window.atob(localStorage.getItem('UserData')))
    const WAIT_TIME = 30000
    const [error, setError] = useState('')

    const [initTime, setInitTime] = useState(0)
    useEffect(() => {
        const t = localStorage.getItem('pinSendTime')

        if (t && WAIT_TIME - (Date.now() - t) > 0)
            setInitTime(t)
        else {
            const nT = Date.now()
            setInitTime(nT)
            localStorage.setItem('pinSendTime', nT)
        }
    }, [])

    const [time, setTime] = useState()


    const [userInfo, setUserInfo] = useState({})
    const [isSubmitting, setIsSubmitting] = useState(false)
    const is_2fa_enabled = false

    useEffect(() => {
        getRecaptcha('recaptcha-verify-2fa-container', res => {
            console.log("===========getRecaptcha==============", { res })
        })
        console.log("===========send2FACode==============", { phone })
        send2FACode(phone)
    }, [])

    const submitForm = (pin) => {
        setError('');
        setIsSubmitting(true)
        window?.confirmationResult?.confirm(pin)
            .then(async (result) => {
                const user = result?.user

                console.log("===================================", {is_2fa_enabled, phone_number: phone})
                const r = await manualApiCall('/public/login/user',
                    { method: 'POST', body: JSON.stringify({ email: userData.email, password: userData.password, phone_number: phone, is_2fa_enabled: true }) })

                if (r.isSuccess && !isEmpty(r.data)) {
                    console.log("===========r.data==============", r.data)
                    if (r?.data?.isBlockedQlu) {
                        alert("You are blocked, contact admin@dnnae.com.")
                        setError('Email blocked')
                        localStorage.removeItem('pinSendTime')
                        history.push('/')
                        return
                    }
                    // const phone = localStorage.getItem('mfa_phone')
                    const newUser = await signInToFirebase(r.data)
                    if (newUser) {
                        console.log("===============newUser=================", newUser)
                        const token = await newUser.getIdToken(true)
                        console.log("===============token=================", token)
                        localStorage.setItem('user',
                            JSON.stringify({
                                ...userInfo,
                                phone_number: phone,
                                is_2fa_enabled: true,
                                token,
                                sw: newUser?.stsTokenManager?.refreshToken
                            }))
                        dispatch(setUser({ ...r.data, phone_number: phone, is_2fa_enabled: true }))
                        manualApiCall('/api/v2/auth/updateUserData', {
                          method: 'POST',
                          body: JSON.stringify({ sw: newUser?.stsTokenManager?.refreshToken })
                      })
                        // alefirstrt(`2 step verification enabled on ${phone_number}`)


                        localStorage.removeItem('pinSendTime')
                        history.push('/')
                    }
                    else {
                        throw new Error("Re login Failed")
                    }
                } else {
                    if (r.message) {
                        setError(r.message)
                    }
                }
            })
            .catch(e => {
                console.log("ERROR OCCURRED:", e)
                setError('Wrong PIN, enter again');
            })
            .finally(() => {
                setIsSubmitting(false)
            })
    }

    const onChange = (val) => {
        if (String(val).length !== 6) return
        if (!isSubmitting)
            submitForm(val)

    }
    useInterval(() => {
        let t = WAIT_TIME - (Date.now() - initTime)
        if (t < 0)
            t = 0
        setTime(t)
    }, 1000)

    const resendPin = () => {
        if (time > 0) return
        send2FACode(phone)
        const nT = Date.now()
        setInitTime(nT)
        localStorage.setItem('pinSendTime', nT)
    }

    return (
        <>
            <div className='verify_2fa_root authElemContainer'>
                <h1>Check your phone</h1>
                <div className='mb32'>
                    <p>
                        Please enter the 6-digit pin we sent you on your phone number
                    </p>
                </div>
                <div className='mb32'>
                    <DigitInput onChange={onChange} error={error} />
                </div>
                <div className='mb32'>
                    <p>
                        Did not get the PIN?
                        <span className={`${time <= 0 ? 'resend' : ''}`} onClick={resendPin}>
                            Send again
                        </span>
                        ({parseInt(time / 1000)}s)
                    </p>
                </div>
                <div className='mb32' id='recaptcha-verify-2fa-container' />
            </div>
        </>
    )
}

export default connect(state => ({ ...state.auth.emailStep }))(withRouter(Verify2FA))