import { Field, Form, Formik } from 'formik'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import Loader from 'react-loader-spinner'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { FETCH_PROVIDER_SUCCESS, setUser } from '../../actions/auth'
import Divider from '../../components/Divider'
import FormikInput from '../../components/FormikInput'
import SocialLoginButton from '../../components/SocialLoginButton'
import eyeOffIcon from '../../img/eye-off.svg'
import eyeIcon from '../../img/eye.svg'
import PrimaryButton from '../../uiLibrary/PrimaryButton'
import { manualApiCall, signInToFirebase, validateEmail } from '../../utils'
import { ActivityTypes, loggingActivity } from '../../utils/activityLogger'
import { Mixpanel } from '../../utils/Mixpanel'

function LoginScreen({
    history,
    location,
    dispatch,
}) {
    useEffect(() => {
        localStorage.removeItem('UserData')
    }, [])


    const validateForm = values => {
        const errors = {};
        if (values.email && !validateEmail(values.email)) {
            errors.email = 'Invalid email address';
        }
        return errors;
    }


    const submitForm = async (values, { props, resetForm, setErrors, setSubmitting }) => {
        try {
            setSubmitting(true)
            const errors = {};
            if (!values.email) {
                errors.email = 'Email is required';
            } else if (!validateEmail(values.email)) {
                errors.email = 'Invalid email address';
            }

            if (!values.password) {
                errors.password = 'Password is required';
            }
            if (errors?.email || errors?.password) {
                setErrors(errors)
                return
            }

            const res = await manualApiCall('/public/email/lookup',
                { method: 'POST', body: JSON.stringify({ email: values.email }) })

            if (!res.data.exist) {
                setErrors({ email: 'No account exists with this email' })
            }
            else if (res?.data?.isBlockedQlu) {
                alert("You are blocked, contact admin@dnnae.com.")
                setErrors({ email: 'Email blocked' })
            }
            else {
                const r = await manualApiCall('/public/login/user',
                    { method: 'POST', body: JSON.stringify({ email: values.email, password: values.password }) })
                console.log("============setSubmitting=============", { r })
                if (r.isSuccess && !isEmpty(r.data)) {
                    console.log("===========r.data==============", r.data)
                    if (r?.data?.isBlockedQlu) {
                        alert("You are blocked, contact admin@dnnae.com.")
                        setErrors({ email: 'Email blocked' })
                    }

                    else {
                        if (r.data.is_2fa_enabled && r.data.phoneNumber) {
                            // send2FACode(r.data.phoneNumber)
                            localStorage.setItem('UserData', window.btoa(JSON.stringify({
                                email: values.email,
                                password: values.password,
                            })))
                            dispatch({ type: FETCH_PROVIDER_SUCCESS, meta: values.email, phone: r.data.phoneNumber })
                            history.push('/auth/verify-2fa')
                        }
                        else {
                            localStorage.removeItem('UserData')
                            const user = await signInToFirebase(r.data)
                            console.log("===============user=================", user)
                            if (user) {
                                const token = await user.getIdToken(true)
                                Mixpanel.track("Login with password")
                                localStorage.setItem('user', JSON.stringify({ ...r.data, email: values.email, token, sw: user.stsTokenManager.refreshToken }))
                                dispatch(setUser({ ...r.data, email: values.email }))
                                manualApiCall('/api/v2/auth/updateUserData', {
                                    method: 'POST',
                                    body: JSON.stringify({ sw: user.stsTokenManager.refreshToken })
                                })
                                loggingActivity(ActivityTypes.LOGIN)

                                const inviteTribe = localStorage.getItem('inviteTribe')
                                if (inviteTribe) {
                                    const { jobId, search } = JSON.parse(inviteTribe)
                                    history.push(`/jobs/${jobId}${search}`)
                                } else {
                                    history.push('/')
                                }
                            }
                            else {
                                throw new Error("User not found")
                            }
                        }
                    }
                } else {
                    if (r?.message === "Email is not verified.") {
                        localStorage.setItem('UserData', window.btoa(JSON.stringify(r?.data)))
                        history.push('/auth/resend-link')
                    } else {
                        setErrors({ password: r?.message })
                    }
                }
            }
        } catch (e) {
            console.log('AN ERROR OCCURRED: ', e.message)
        }
        finally {
            setSubmitting(false)
        }
    }

    const [isVisiblePassword, setIsVisiblePassword] = useState(false)

    const showError = ({ errors, touched, values }, key) => {
        // return (!values[key] && !!touched[key]) || (!!values[key] && !!errors[key])
        return errors[key]

    }

    return (
        <>
            <Formik
                initialValues={{ email: '', password: '' }}
                validateOnMount
                validate={validateForm}
                onSubmit={submitForm}
                validateOnChange={validateForm}
            >
                {({
                    errors,
                    isSubmitting,
                    values,
                    touched
                }) => {
                    return (
                        <div className='signin_root authElemContainer'>
                            <h1>Log in to Qlu</h1>
                            <Form>
                                <div className='mb32'>
                                    <p className='input_label'>EMAIL</p>
                                    <Field
                                        type="email"
                                        name="email"
                                        placeholder={'jon@doe.com'}
                                        component={FormikInput}
                                        className={`formik_input_custom ${showError({ errors, touched, values }, 'email')
                                            ? 'input_error' : ''}`}
                                    // render={({ field, form, ...props }) => (
                                    //     <FormikInput field={field} form={form} props={props}
                                    //     />
                                    // )}
                                    />
                                    {showError({ errors, touched, values }, 'email')
                                        && <p className='formik_err_msg'>{errors?.email}</p>}
                                    {/* <ErrorMessage name="email" render={msg => <p className='formik_err_msg'>{msg}</p>} /> */}
                                </div>
                                <div className='mb32 '>
                                    <p className='input_label'>
                                        PASSWORD
                                        <Link to={`/auth/reset-password?e=${values.email}`}>
                                            Forgot password?
                                        </Link>
                                    </p>
                                    <Field
                                        type={isVisiblePassword ? 'text' : 'password'}
                                        name="password" placeholder={'At least 8 characters'}
                                        autoComplete="off"
                                        component={FormikInput}
                                        className={`formik_input_custom ${showError({ errors, touched, values }, 'password')
                                            ? 'input_error' : ''}`}
                                    // render={({ field, form, ...props }) => (
                                    //     <FormikInput
                                    //         field={field} form={form} props={props}
                                    //         type={isVisiblePassword ? 'text' : 'password'} />
                                    // )}
                                    />
                                    <span className='password_show_hide' onClick={() => setIsVisiblePassword(p => !p)}>
                                        {isVisiblePassword
                                            ? <img src={eyeOffIcon} alt='hide' />
                                            : <img src={eyeIcon} alt='show' />
                                        }
                                    </span>
                                    {showError({ errors, touched, values }, 'password')
                                        && <p className='formik_err_msg'>{errors?.password}</p>}
                                    {/* <ErrorMessage name="password" render={msg => <p className='formik_err_msg'>{msg}</p>} /> */}
                                </div>
                                <PrimaryButton className='auth-submit-btn' type="submit" disabled={isSubmitting || errors.email || errors.password}>
                                    {isSubmitting ?
                                        <Loader
                                            type="TailSpin"
                                            color="white"
                                            height={18}
                                            width={18}
                                        /> :
                                        'Log In'}
                                </PrimaryButton>
                            </Form>
                            <Divider />
                            <a style={{ textDecoration: 'none' }} href={`${process.env.REACT_APP_API_HOST}/google/login`}>
                                <SocialLoginButton provider={'google'} />
                            </a>
                            <a style={{ textDecoration: 'none' }} href={process.env.REACT_APP_OUTLOOK_AUTH}>
                                <SocialLoginButton provider={'outlook'} />
                            </a>
                        </div>
                    )
                }}
            </Formik>
        </>
    )
}
export default connect(state => ({ ...state.auth.emailStep }))(withRouter(LoginScreen))