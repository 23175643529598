// import { CALL_LINKEDIN_API } from "../middlewares/apiMiddleware";
// import {
//   callLinkedinApi,
//   callCompanyRecommenderApi,
//   getCompanyDetails,
// } from "../utils";
import { flatten, get, isEmpty } from "lodash";
import { v4 } from "uuid";
import {
  callLiApi,
  callLiWrapperApi,
  extractPublicIdentifier,
  getImgSrcURL,
  getTitleProfileCount,
  manualApiCall
} from "../utils";
import {
  callCompanyRecommenderApi,
  getCompanyDetails
} from "../utils/visitApiProfile";

import { CALL_API } from "../middlewares/apiMiddleware";
import { visitApiProfileLiNew } from "../utils/visitApiProfileNew";

export const SET_JOB_SETUP_VIEW = "SET_JOB_SETUP_VIEW";
export const setJobSetupView = (payload) => ({
  type: SET_JOB_SETUP_VIEW,
  payload,
});

export const INIT_JOB_SETUP_VIEW = "INIT_JOB_SETUP_VIEW";
export const initJobSetupView = () => ({
  type: INIT_JOB_SETUP_VIEW,
});

export const CHANGE_JOB_DESCRIPTION = "CHANGE_JOB_DESCRIPTION";
export const changeJobDescription = (payload) => ({
  type: CHANGE_JOB_DESCRIPTION,
  payload,
});

export const CHANGE_JOB_DETAIL_TITLE = "CHANGE_JOB_DETAIL_TITLE";
export const changeJobDetailTitle = (payload) => ({
  type: CHANGE_JOB_DETAIL_TITLE,
  payload,
});

export const CLEAR_JOB_LOCATION_SUGGESTION = "CLEAR_JOB_LOCATION_SUGGESTION";
export const clearJobLocationSuggestions = () => ({
  type: CLEAR_JOB_LOCATION_SUGGESTION,
});

export const SET_JOB_LOCATION = "SET_JOB_LOCATION";
export const setJobLocation = (payload) => ({
  type: SET_JOB_LOCATION,
  payload,
});

export const FETCH_JOB_LOCATIONS = "FETCH_JOB_LOCATIONS";
export const FETCH_JOB_LOCATIONS_SUCCESS = "FETCH_JOB_LOCATIONS_SUCCESS";
export const FETCH_JOB_LOCATIONS_FAILURE = "FETCH_JOB_LOCATIONS_FAILURE";

export const fetchDbJobLocations = (value) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_JOB_LOCATIONS,
    });

    const resp = await fetch(
      `${process.env.REACT_APP_API_HOST}/api/v2/location?location=${value}`
    ).then((r) => r.json());
    console.log("resp from local before", resp);

    const elements = resp.data;

    const payload = Array.isArray(elements)
      ? elements.map(({ id, location, urn }) => ({
        name: location,
        // id: id,
        id: urn && urn.split(":").pop(),
      }))
      : [];

    console.log("Response from local location payload", payload);

    dispatch({
      type: FETCH_JOB_LOCATIONS_SUCCESS,
      payload,
    });
  } catch (err) {
    console.log("Error in fetch location: ", err.message);
    dispatch({
      type: FETCH_JOB_LOCATIONS_FAILURE,
    });
  }
};

const regions = [
  "Latin America",
  "Central America",
  "South America",
  "Andean Group",
  "Southern Cone",
  "Caribbean",
  "Greater Antilles",
  "Lesser Antilles",
  "North America",
  "North Central United States",
  "North East United States",
  "North West United States",
  "South Central United States",
  "South East United States",
  "South West United States",
];
function similar(b) {
  let matchEqui = [];
  let maxLength = Math.min.apply(
    Math,
    regions.map(function (str) {
      return str.length;
    })
  );
  for (let i = 0; i < regions.length; i++) {
    let equivalency = 0;
    let minLength = regions[i].length > b.length ? b.length : regions[i].length;
    // let maxLength = (regions[i].length < b.length) ? b.length : regions[i].length;
    for (let j = 0; j < minLength; j++) {
      if (regions[i][j].toLowerCase() == b[j].toLowerCase()) {
        equivalency++;
      }
    }
    let weight = equivalency / maxLength;
    matchEqui.push({ id: i, equi: weight * 100 });
  }
  const max = matchEqui.reduce(
    (prev, current) => (prev.equi > current.equi ? prev : current),
    1
  );
  if (
    max.equi > 30 &&
    regions[max.id].toLowerCase().includes(b.toLowerCase())
  ) {
    return { id: 0, name: regions[max.id] };
  }
}

export const fetchJobLocations = (value) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_JOB_LOCATIONS,
    });

    // const resp = await callLiApi(
    //   `/voyager/api/typeahead/hitsV2?keywords=${value}&origin=OTHER&q=type&queryContext=List(geoVersion-%3E3,bingGeoSubTypeFilters-%3EMARKET_AREA%7CCOUNTRY_REGION%7CADMIN_DIVISION_1%7CCITY)&type=GEO&useCase=GEO_ABBREVIATED`,
    //   {
    //     headers: {
    //       "x-li-lang": "en_US",
    //       "x-restli-protocol-version": "2.0.0",
    //     },
    //   }
    // );

    //==@liWrapper==//
    const resp = await callLiWrapperApi("Location", null, { value });

    console.log("CALL LI RESP: ", resp);
    const elements = resp.data.data.searchDashReusableTypeaheadByType.elements;
    const payload = Array.isArray(elements)
      ? elements
        .map((loc) => ({
          name: loc.title?.text,
          id: loc.target?.geo?.entityUrn?.split(":").pop(),
          urn: loc.target?.geo?.entityUrn,
        }))
        .slice(0, 5)
      : [];

    const suggestion = similar(value);
    if (suggestion) {
      payload.unshift(suggestion);
    }
    // payload.unshift(similar(value))
    console.log("PAYLOAD", payload);
    dispatch({
      type: FETCH_JOB_LOCATIONS_SUCCESS,
      payload,
    });

    // callLinkedinApi(`/voyager/api/typeahead/hitsV2?keywords=${value}&origin=OTHER&q=type&queryContext=List(geoVersion-%3E3,bingGeoSubTypeFilters-%3EMARKET_AREA%7CCOUNTRY_REGION%7CADMIN_DIVISION_1%7CCITY)&type=GEO&useCase=GEO_ABBREVIATED`,
    //   {
    //     method: "GET",
    //     headers: {
    //       "x-li-lang": "en_US",
    //       "x-restli-protocol-version": "2.0.0",
    //     },
    //   },
    //   ({ elements }) => {
    //     // console.log("=================FETCH_JOB_LOCATIONS_SUCCESS=======================", elements)
    //     const payload = Array.isArray(elements) ?
    //       elements.map(({
    //         text,
    //         targetUrn
    //       }) => ({
    //         name: text?.text,
    //         id: targetUrn.split(':').pop()
    //       })).slice(0, 5) :
    //       []

    //     dispatch({
    //       type: FETCH_JOB_LOCATIONS_SUCCESS,
    //       payload
    //     })
    //   }
    // )
  } catch (err) {
    console.log("Error in fecth location: ", err.message);
    dispatch({
      type: FETCH_JOB_LOCATIONS_FAILURE,
    });
  }
};

//-----------------LOCATION SUGGESTION-----------------//
export const FETCH_SUGGESTED_JOB_LOCATIONS_SUCCESS =
  "FETCH_SUGGESTED_JOB_LOCATIONS_SUCCESS";
export const FETCH_SUGGESTED_JOB_LOCATIONS = "FETCH_SUGGESTED_JOB_LOCATIONS";
export const FETCH_SUGGESTED_JOB_LOCATIONS_FAILURE =
  "FETCH_SUGGESTED_JOB_LOCATIONS_FAILURE";
export const FETCH_SIMILAR_JOB_LOCATIONS_SUCCESS =
  "FETCH_SIMILAR_JOB_LOCATIONS_SUCCESS";
export const FETCH_SIMILAR_JOB_LOCATIONS = "FETCH_SIMILAR_JOB_LOCATIONS";
export const FETCH_SIMILAR_JOB_LOCATIONS_FAILURE =
  "FETCH_SIMILAR_JOB_LOCATIONS_FAILURE";
export const SET_SPECIFIC_LOCATION = "SET_SPECIFIC_LOCATION";

export const fetchSuggestedLocation = (value) => async (dispatch, getState) => {
  try {
    const locations = [value];
    const titleCount = await getTitleProfileCount("", locations)
    const selectedLocations = getState().jobSetup.jobLocation.selectedLocations;
    const locationSuggestions = getState().jobSetup.jobLocation
      .locationSuggestions;
    const similarLocations = getState().jobSetup.jobLocation.similarLocations;
    const id = value.id;

    if (titleCount > 100000 || titleCount <= 0) {
      dispatch({
        type: SET_SPECIFIC_LOCATION,
        payload: { key: id, value: false },
      });
    } else {
      dispatch({
        type: SET_SPECIFIC_LOCATION,
        payload: { key: id, value: true },
      });
    }
    if (
      titleCount > 0 &&
      selectedLocations.length == 1 &&
      titleCount < 100000
    ) {
      //SUGGESTED START

      // const locationSuggestions = getState().jobSetup.jobLocation.locationSuggestions
      // const selectedLocations = getState().jobSetup.jobLocation.selectedLocations

      dispatch({
        type: FETCH_SUGGESTED_JOB_LOCATIONS,
      });

      const resp = await fetch(
        `${process.env.REACT_APP_API_HOST}/api/v2/location/near?urn=${value.urn}&range=50000`
      ).then((r) => r.json());

      const elements = resp.data;
      const data = Array.isArray(elements)
        ? elements
          .map(({ id, location, urn }) => ({
            name: location,
            // id: id,
            id: urn && urn.split(":").pop(),
          }))
          .slice(0, 5)
        : [];

      const payload = data.filter(
        (o1) => !locationSuggestions.some((o2) => o1.id === o2.id)
      );

      console.log("Payload", payload);

      dispatch({
        type: FETCH_SUGGESTED_JOB_LOCATIONS_SUCCESS,
        payload,
      });

      // SUGGESTED END
    } else if (titleCount > 100000) {
      // SIMILAR LOCATION START
      dispatch({
        type: FETCH_SIMILAR_JOB_LOCATIONS,
      });
      const similarLocation = await fetch(
        `${process.env.REACT_APP_API_HOST}/api/v2/location/similar?location=${value.name}&urn=${value.urn}&range=50000`
      ).then((r) => r.json());

      const elements = similarLocation.data;
      const data = Array.isArray(elements)
        ? elements
          .map(({ id, location, urn }) => ({
            name: location,
            // id: id,
            id: urn && urn.split(":").pop(),
          }))
          .slice(0, 5)
        : [];

      const payload = data.filter(
        (o1) => !similarLocations.some((o2) => o1.id === o2.id)
      );
      dispatch({
        type: FETCH_SIMILAR_JOB_LOCATIONS_SUCCESS,
        payload,
      });
      // SIMILAR LOCATION END
    }
  } catch (err) {
    console.log("Error in fetch location: ", err.message);
    dispatch({
      type: FETCH_SUGGESTED_JOB_LOCATIONS_FAILURE,
    });
  }
};

export const REMOVE_SUGGESTED_JOB_LOCATION = "REMOVE_SUGGESTED_JOB_LOCATION";
export const removeSuggestedJobLocation = (payload) => ({
  type: REMOVE_SUGGESTED_JOB_LOCATION,
  payload,
});

export const fetchRegionArea = (value) => async (dispatch, getState) => {
  try {
    console.log("Value for suggestion", value.name);

    const locationSuggestions = getState().jobSetup.jobLocation
      .locationSuggestions;
    const selectedLocations = getState().jobSetup.jobLocation.selectedLocations;

    const resp = await fetch(
      `${process.env.REACT_APP_API_HOST}/api/v2/location/region?location=${value.name}`
    ).then((r) => r.json());

    const elements = resp.data;
    const data = Array.isArray(elements)
      ? elements.map(({ id, location, urn }) => ({
        name: location,
        // id: id,
        id: urn && urn.split(":").pop(),
      }))
      : [];

    console.log("==================Selected location", selectedLocations);
    const payload = data.filter(
      (o1) => !selectedLocations.some((o2) => o1.name === o2.name)
    );
    payload.forEach((value) => {
      if (value.id !== null) {
        dispatch(addSelectedJobLocation(value));
      }
    });
  } catch (err) {
    console.log("Error in fetch region suggestion: ", err.message);
  }
};

//-----------------LOCATION SUGGESTION END-----------------//

export const FETCH_JOB_COMPANY_SUGGESTION = "FETCH_JOB_COMPANY_SUGGESTION";
export const FETCH_JOB_COMPANY_SUGGESTION_SUCCESS =
  "FETCH_JOB_COMPANY_SUGGESTION_SUCCESS";
export const FETCH_JOB_COMPANY_SUGGESTION_FAILURE =
  "FETCH_JOB_COMPANY_SUGGESTION_FAILURE";

export const fetchJobCompanySuggestion = (
  value,
  urlFlag = false,
  url = ""
) => async (dispatch, getState) => {
  dispatch({
    type: FETCH_JOB_COMPANY_SUGGESTION,
  });
  try {
    // const response = await
    const fetchedCompanies = getState().jobSetup.companyDetails.similarCompanies.map(
      ({ universalName }) => universalName
    );
    const companyHiringFor = getState().jobSetup.companyDetails
      .companyHiringFor;

    if (companyHiringFor?.universalName) {
      fetchedCompanies.push(companyHiringFor?.universalName);
    }
    if (urlFlag) {
      dispatch({
        type: FETCH_JOB_COMPANY_SUGGESTION_SUCCESS,
        payload: [
          {
            active: true,
            recommeded: true,
            details: {},
            name: url,
            urn: false,
            entityURN: "",
            industry: "",
            logo: "",
            universalName: value,
            isUrl: true,
          },
        ],
      });
    } else {
      const resp = await callLiWrapperApi("SearchCompanySuggestion", null, { value });
      const data = resp?.data?.data?.searchDashClustersByAll
      let companySuggestionsArray = []
      if (data?.paging?.total > 0) {
        let items = data?.elements?.map(k => k.items.map(l => l.item?.entityResult))
        items = flatten(items)
        items = items.filter(k => k)
        companySuggestionsArray = items
          .map((item) => {
            const obj = item
            const url = obj?.navigationUrl
            const nonEntityCompanyLogo = obj?.image?.attributes[0]?.detailData?.nonEntityCompanyLogo
            const vectorImage = nonEntityCompanyLogo?.vectorImage?.artifacts[0]?.fileIdentifyingUrlPathSegment
            const industry = obj?.primarySubtitle?.text.split("•").filter(k => k.trim() !== '')[0]
            return {
              active: true,
              recommeded: true,
              details: {},
              name: obj?.title?.text ? obj?.title?.text : false,
              industry: industry || "",
              logo: vectorImage || "",
              entityURN: obj?.trackingUrn || "",
              urn: nonEntityCompanyLogo?.company?.entityUrn || "",
              universalName: url.split("/").filter(k => k.trim() !== '').pop(),
            }
          })
          .filter(
            (itm) => itm.name && itm.urn && itm.entityURN && itm.universalName
          )
          .filter((itm) => !fetchedCompanies.includes(itm.universalName))
          .slice(0, 5);
      }

      dispatch({
        type: FETCH_JOB_COMPANY_SUGGESTION_SUCCESS,
        payload: companySuggestionsArray,
      });
    }
  } catch (e) {
    console.log("ERROR: ", e);
    dispatch({
      type: FETCH_JOB_COMPANY_SUGGESTION_FAILURE,
    });
  }
};

export const SELECT_COMPANY = "SELECT_COMPANY";
export const SELECT_COMPANY_SUCCESS = "SELECT_COMPANY_SUCCESS";
export const SELECT_COMPANY_FAILURE = "SELECT_COMPANY_FAILURE";
export const selectHiringCompany = (companyData) => async (dispatch) => {
  try {
    dispatch({
      type: SELECT_COMPANY,
    });

    const resp = await getCompanyDetails(companyData.universalName);

    console.log(
      "================selectHiringCompany======================",
      resp
    );

    if (!isEmpty(resp)) {
      dispatch(
        fetchJobSimilarCompanies(
          `urn:li:fsd_company:${resp?.entityUrn?.split(":")?.pop()}`
        )
      );
      dispatch({
        type: SELECT_COMPANY_SUCCESS,
        payload: {
          ...companyData,
          urn: `urn:li:fsd_company:${resp?.entityUrn?.split(":")?.pop()}`,
          logo: companyData.logo || resp.companyLogoUrl,
          industry:
            companyData.industry || resp?.industries?.localizedName || "",
          ...resp,
        },
      });
    } else {
      console.log("Error: company data not available");
      dispatch({
        type: SELECT_COMPANY_FAILURE,
      });
    }
  } catch (err) {
    console.log("Error: ", err.message);
    dispatch({
      type: SELECT_COMPANY_FAILURE,
    });
  }
};

export const FETCH_COMPANY_STAFF_COUNT = "FETCH_COMPANY_STAFF_COUNT";
export const FETCH_COMPANY_STAFF_COUNT_SUCCESS =
  "FETCH_COMPANY_STAFF_COUNT_SUCCESS";
export const FETCH_COMPANY_STAFF_COUNT_FAILURE =
  "FETCH_COMPANY_STAFF_COUNT_FAILURE";

export const getJobCompanyProfileEmployeeCount = (universalName) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: FETCH_COMPANY_STAFF_COUNT,
    });

    console.log(
      "==================getJobCompanyProfifetchSimilarCompanyDetailsleEmployeeCount===================",
      universalName
    );
    const response = await getCompanyDetails(universalName);
    const res = response.data;
    dispatch({
      type: FETCH_COMPANY_STAFF_COUNT_SUCCESS,
      payload: res,
    });
  } catch (e) {
    dispatch({
      type: FETCH_COMPANY_STAFF_COUNT_FAILURE,
    });
  }
};

export const FETCH_COMPANY_SUGGESTION_PROFILE =
  "FETCH_COMPANY_SUGGESTION_PROFILE";
export const FETCH_COMPANY_SUGGESTION_PROFILE_SUCCESS =
  "FETCH_COMPANY_SUGGESTION_PROFILE_SUCCESS";
export const FETCH_COMPANY_SUGGESTION_PROFILE_FAILURE =
  "FETCH_COMPANY_SUGGESTION_PROFILE_FAILURE";
export const getJobSuggestionCompanyProfile = (universalName) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: FETCH_COMPANY_SUGGESTION_PROFILE,
    });

    // const res = await getCompanyDetails(
    //   encodeURIComponent(universalName),
    //   {}
    // );
    dispatch({
      type: FETCH_COMPANY_SUGGESTION_PROFILE_SUCCESS,
      // payload: res,
    });
  } catch (e) {
    dispatch({
      type: FETCH_COMPANY_SUGGESTION_PROFILE_FAILURE,
    });
  }
};



export const SET_JOB_LOCATION_EDITED = "SET_JOB_LOCATION_EDITED";
export const setJobLocationEdited = (payload) => ({
  type: SET_JOB_LOCATION_EDITED,
  payload,
});


export const ADD_SELECTED_JOB_LOCATION = "ADD_SELECTED_JOB_LOCATION";
export const addSelectedJobLocation = (payload) => ({
  type: ADD_SELECTED_JOB_LOCATION,
  payload,
});

export const REMOVE_SELECTED_JOB_LOCATION = "REMOVE_SELECTED_JOB_LOCATION";
export const removeSelectedJobLocation = (payload) => ({
  type: REMOVE_SELECTED_JOB_LOCATION,
  payload,
});

export const JOB_COMPANY_PROFILE = "JOB_COMPANY_PROFILE";

export const getJobCompanyProfile = (payload) => ({
  type: JOB_COMPANY_PROFILE,
  payload,
});

export const CHANGE_JOB_COMPANY_NAME = "CHANGE_JOB_COMPANY_NAME";
export const changeJobCompanyName = (value) => ({
  type: CHANGE_JOB_COMPANY_NAME,
  payload: value,
});

export const CLEAR_JOB_COMPANY_SUGGESTION = "CLEAR_JOB_COMPANY_SUGGESTION";
export const clearJobCompanySuggestion = () => ({
  type: CLEAR_JOB_COMPANY_SUGGESTION,
});

export const JOB_COMPANY_PROFILE_DELETE = "JOB_COMPANY_PROFILE_DELETE";
export const companyProfileDelete = () => ({
  type: JOB_COMPANY_PROFILE_DELETE,
});

export const FETCH_JOB_SIMILAR_COMPANY = "FETCH_JOB_SIMILAR_COMPANY";
export const FETCH_JOB_SIMILAR_COMPANY_SUCCESS =
  "FETCH_JOB_SIMILAR_COMPANY_SUCCESS";
export const FETCH_JOB_SIMILAR_COMPANY_FAILURE =
  "FETCH_JOB_SIMILAR_COMPANY_FAILURE";

export const fetchJobSimilarCompanies = (urn, universalName = false) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: FETCH_JOB_SIMILAR_COMPANY,
    });
    // console.log(
    //   "==================FETCH_JOB_SIMILAR_COMPANY====================",
    //   { urn, universalName }
    // );
    const res = await callCompanyRecommenderApi(urn);

    // console.log(
    //   "==================FETCH_JOB_SIMILAR_COMPANY_Res====================",
    //   { urn, universalName, res }
    // );
    dispatch({
      type: FETCH_JOB_SIMILAR_COMPANY_SUCCESS,
      payload: {
        similarComp: res.filter(({ universalName }) => universalName),
        universalName,
      },
    });
  } catch (e) {
    dispatch({
      type: FETCH_JOB_SIMILAR_COMPANY_FAILURE,
    });
  }
};
export const FETCH_SIMILAR_COMPANY_DETAILS = "FETCH_SIMILAR_COMPANY_DETAILS";
export const FETCH_SIMILAR_COMPANY_DETAILS_SUCCESS =
  "FETCH_SIMILAR_COMPANY_DETAILS_SUCCESS";
export const FETCH_SIMILAR_COMPANY_DETAILS_FAILURE =
  "FETCH_SIMILAR_COMPANY_DETAILS_FAILURE";

export const fetchSimilarCompanyDetails = (universalName) => async (
  dispatch,
  getState
) => {
  try {
    const thisCompany = getState()
      .jobSetup.companyDetails.similarCompanies.filter(
        (item) => item.universalName === universalName
      )
      .pop();
    if (thisCompany.detailFetched) {
      // console.log("=============detailFetched================");
      return false;
    }
    dispatch({
      type: FETCH_SIMILAR_COMPANY_DETAILS,
      payload: universalName,
    });
    const resp = await getCompanyDetails(universalName);
    // const res = response.data
    if (!isEmpty(resp)) {
      dispatch({
        type: FETCH_SIMILAR_COMPANY_DETAILS_SUCCESS,
        payload: { universalName, ...resp },
      });
    } else {
      console.log("Error: fetchSimilarCompanyDetails empty");
      dispatch({
        type: FETCH_SIMILAR_COMPANY_DETAILS_FAILURE,
        payload: universalName,
      });
    }
  } catch (e) {
    dispatch({
      type: FETCH_SIMILAR_COMPANY_DETAILS_FAILURE,
      payload: universalName,
    });
  }
};
export const ADD_SIMILAR_COMPANY_DETAILS = "ADD_SIMILAR_COMPANY_DETAILS";
export const ADD_SIMILAR_COMPANY_DETAILS_SUCCESS =
  "ADD_SIMILAR_COMPANY_DETAILS_SUCCESS";
export const ADD_SIMILAR_COMPANY_DETAILS_FAILURE =
  "ADD_SIMILAR_COMPANY_DETAILS_FAILURE";

export const addSimilarCompanyDetails = (companyData) => async (
  dispatch,
  getState
) => {
  try {
    const thisCompany = {
      ...companyData,
      liked: true,
      detailFetched: true,
    };
    dispatch({
      type: ADD_SIMILAR_COMPANY_DETAILS,
    });
    const response = await getCompanyDetails(thisCompany.universalName);
    // const res = response.data

    if (!isEmpty(response)) {
      dispatch({
        type: ADD_SIMILAR_COMPANY_DETAILS_SUCCESS,
        payload: { ...thisCompany, ...response },
      });
      dispatch(
        fetchJobSimilarCompanies(thisCompany.urn, thisCompany.universalName)
      );
    } else {
      console.log("Error: fetchSimilarCompanyDetails empty");
      dispatch({
        type: ADD_SIMILAR_COMPANY_DETAILS_FAILURE,
      });
    }

    // if (Array.isArray(res) && res.length > 2) {
    //   dispatch({
    //     type: ADD_SIMILAR_COMPANY_DETAILS_SUCCESS,
    //     payload: { ...thisCompany, ...res?.[1] },
    //   });
    //   dispatch(fetchJobSimilarCompanies(thisCompany.urn, thisCompany.universalName))
    // }
    // else {
    //   console.log("Error: fetchSimilarCompanyDetails returned array less than 3")
    //   dispatch({
    //     type: ADD_SIMILAR_COMPANY_DETAILS_FAILURE
    //   })
    // }

    // getCompanyDetails(thisCompany.universalName,
    //   res => {
    //     // console.log("==================selectHiringCompany===================", res)
    //     if (Array.isArray(res) && res.length > 2) {
    //       dispatch({
    //         type: ADD_SIMILAR_COMPANY_DETAILS_SUCCESS,
    //         payload: { ...thisCompany, ...res?.[1] },
    //       });
    //       dispatch(fetchJobSimilarCompanies(thisCompany.urn, thisCompany.universalName))
    //     }
    //     else {
    //       console.log("Error: fetchSimilarCompanyDetails returned array less than 3")
    //       dispatch({
    //         type: ADD_SIMILAR_COMPANY_DETAILS_FAILURE
    //       })
    //     }

    //   }
    // );
  } catch (e) {
    dispatch({
      type: ADD_SIMILAR_COMPANY_DETAILS_FAILURE,
    });
  }
};

export const CHANGE_JOB_SPECIALITY = "CHANGE_JOB_SPECIALITY";
export const changeJobSpeciality = (payload) => ({
  type: CHANGE_JOB_SPECIALITY,
  payload,
});

export const fetchMainTitleSuggestions = () => async (dispatch, getState) => {
  try {
    const { name } = getState().jobSetup.jobCriteria.mainTitle;
    const payload = await fetch(process.env.REACT_APP_NER_ADDITIONAL_TITLE_SUGGESTED_MAIN_TITLES,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name }),
      }
    ).then((r) => r.json());

    dispatch(changeMainJobTitle({ maintitleSuggestions: payload }));
  } catch (err) {
    console.log("ERROR: ", err.message);
  }
};

export const changeGenericTitle = () => async (dispatch, getState) => {
  try {
    console.log("============================================");
    console.log("Inside generic");
    const criteria = getState().jobSetup.jobCriteria.mainTitle;
    const {
      company_size_max,
      company_size_min,
      frequency,
      name,
      source,
      spans,
    } = criteria;
    console.log(
      company_size_max,
      company_size_min,
      frequency,
      name,
      source,
      spans
    );
    const payload = await fetch(
      "https://ner-app-jobsetup-staging-bdhweedyzq-uc.a.run.app/generilized_titles_check",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify([
          {
            company_size_max,
            company_size_min,
            frequency,
            name,
            source,
            spans,
          },
        ]),
      }
    ).then((r) => r.json());
    if (payload[0].generic == true) {
      dispatch(changeMainJobTitle({ generic: true }));
    } else {
      dispatch(changeMainJobTitle({ generic: false }));
    }
    console.log("PAYLOAD", payload[0].generic);
  } catch (err) {
    console.log("ERROR: ", err.message);
  }
};

export const CHANGE_ADDITIONAL_JOB_SPECIALITY =
  "CHANGE_ADDITIONAL_JOB_SPECIALITY";
export const changeAdditionalJobSpecialty = (payload) => ({
  type: CHANGE_ADDITIONAL_JOB_SPECIALITY,
  payload,
});

export const ADD_ADDITIONAL_JOB_SPECIALITY = "ADD_ADDITIONAL_JOB_SPECIALITY";
export const addAdditionalSpecialty = (payload) => ({
  type: ADD_ADDITIONAL_JOB_SPECIALITY,
  payload,
});

export const INIT_ADDITIONAL_JOB_SPECIALITY = "INIT_ADDITIONAL_JOB_SPECIALITY";
export const initAdditionalSpecialty = () => ({
  type: INIT_ADDITIONAL_JOB_SPECIALITY,
});

export const REMOVE_ADDITIONAL_JOB_SPECIALITY =
  "REMOVE_ADDITIONAL_JOB_SPECIALITY";
export const removeAdditionalSpecialty = (payload) => ({
  type: REMOVE_ADDITIONAL_JOB_SPECIALITY,
  payload,
});

export const CHANGE_ADDITIONAL_JOB_TITLE = "CHANGE_ADDITIONAL_JOB_TITLE";
export const changeAdditionalJobTitle = (payload) => ({
  type: CHANGE_ADDITIONAL_JOB_TITLE,
  payload,
});

export const changeAdditionalGenericTitle = ({ i }) => async (
  dispatch,
  getState
) => {
  try {
    const {
      company_size,
      name,
    } = getState().jobSetup.jobCriteria.additionalTitles[i];
    // const { company_size_max, company_size_min, frequency, name, source, spans } = criteria
    // console.log(company_size_max, company_size_min, frequency, name, source, spans)
    const payload = await fetch(
      "https://ner-app-jobsetup-staging-bdhweedyzq-uc.a.run.app/generilized_titles_check",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify([
          {
            company_size_max: company_size[0].min,
            company_size_min: company_size[0].max,
            // frequency,
            name,
            // source,
            // spans,
          },
        ]),
      }
    ).then((r) => r.json());
    if (payload[0].generic == true) {
      dispatch(
        changeAdditionalJobTitle({ index: i, values: { generic: true } })
      );
    } else {
      dispatch(
        changeAdditionalJobTitle({ index: i, values: { generic: false } })
      );
    }
    console.log("PAYLOAD", payload[0].generic);
  } catch (err) {
    console.log("ERROR: ", err.message);
  }
};

export const ADD_ADDITIONAL_JOB_TITLE = "ADD_ADDITIONAL_JOB_TITLE";
export const addAdditionalTitle = (payload) => ({
  type: ADD_ADDITIONAL_JOB_TITLE,
  payload: { ...payload, id: v4() },
});

export const INIT_ADDITIONAL_JOB_TITLE = "INIT_ADDITIONAL_JOB_TITLE";
export const initAdditionalTitle = () => ({
  type: INIT_ADDITIONAL_JOB_TITLE,
});

export const REMOVE_ADDITIONAL_JOB_TITLE = "REMOVE_ADDITIONAL_JOB_TITLE";
export const removeAdditionalTitle = (payload) => ({
  type: REMOVE_ADDITIONAL_JOB_TITLE,
  payload,
});

export const CHANGE_ADDITIONAL_JOB_SKILLS = "CHANGE_ADDITIONAL_JOB_SKILLS";
export const changeAdditionalJobSkills = (payload) => ({
  type: CHANGE_ADDITIONAL_JOB_SKILLS,
  payload,
});

export const ADD_ADDITIONAL_JOB_SKILLS = "ADD_ADDITIONAL_JOB_SKILLS";
export const addAdditionalSkills = (payload) => ({
  type: ADD_ADDITIONAL_JOB_SKILLS,
  payload,
});

export const INIT_ADDITIONAL_JOB_SKILLS = "INIT_ADDITIONAL_JOB_SKILLS";
export const initAdditionalSkills = () => ({
  type: INIT_ADDITIONAL_JOB_SKILLS,
});

export const REMOVE_ADDITIONAL_JOB_SKILLS = "REMOVE_ADDITIONAL_JOB_SKILLS";
export const removeAdditionalSkills = (payload) => ({
  type: REMOVE_ADDITIONAL_JOB_SKILLS,
  payload,
});

export const ADD_JOB_SPECIALITY = "ADD_JOB_SPECIALITY";
export const addJobSpeciality = (payload) => ({
  type: ADD_JOB_SPECIALITY,
  payload,
});

export const CHANGE_JOB_TOTAL_EXPERIENCE = "CHANGE_JOB_TOTAL_EXPERIENCE";
export const changeJobTotalExperience = (payload) => ({
  type: CHANGE_JOB_TOTAL_EXPERIENCE,
  payload,
});

export const REMOVE_JOB_SPECIALITY = "REMOVE_JOB_SPECIALITY";
export const removeJobSpeciality = (payload) => ({
  type: REMOVE_JOB_SPECIALITY,
  payload,
});

export const ADD_JOB_TITLE = "ADD_JOB_TITLE";
export const addJobTitle = (payload) => ({
  type: ADD_JOB_TITLE,
  payload,
});

export const CHANGE_JOB_TITLE = "CHANGE_JOB_TITLE";
export const changeJobTitle = (payload) => ({
  type: CHANGE_JOB_TITLE,
  payload,
});

export const CHANGE_MAIN_JOB_TITLE = "CHANGE_MAIN_JOB_TITLE";
export const changeMainJobTitle = (payload = {}) => ({
  type: CHANGE_MAIN_JOB_TITLE,
  payload,
});

export const REMOVE_JOB_TITLE = "REMOVE_JOB_TITLE";
export const removeJobTitle = (payload) => ({
  type: REMOVE_JOB_TITLE,
  payload,
});
export const SIMILAR_COMPANY_THUMBS_UP_HANDLER =
  "SIMILAR_COMPANY_THUMBS_UP_HANDLER";
export const similarCompanyThumbsUpHandler = (value) => ({
  type: SIMILAR_COMPANY_THUMBS_UP_HANDLER,
  payload: value,
});

export const SIMILAR_COMPANY_THUMBS_DOWN_HANDLER =
  "SIMILAR_COMPANY_THUMBS_DOWN_HANDLER";
export const similarCompanyThumbsDownHandler = (value) => ({
  type: SIMILAR_COMPANY_THUMBS_DOWN_HANDLER,
  payload: value,
});

export const ADD_TO_SIMILAR_COMPANIES_FROM_LIKED =
  "ADD_TO_SIMILAR_COMPANIES_FROM_LIKED";
export const addToSimilarCompaniesFromLiked = (value) => ({
  type: ADD_TO_SIMILAR_COMPANIES_FROM_LIKED,
  payload: value,
});

export const ADD_TO_SIMILAR_COMPANIES_FROM_UNLIKED =
  "ADD_TO_SIMILAR_COMPANIES_FROM_UNLIKED";
export const addToSimilarCompaniesFromUnliked = (value) => ({
  type: ADD_TO_SIMILAR_COMPANIES_FROM_UNLIKED,
  payload: value,
});

export const ADD_TO_UNLIKED_COMPANIES_FROM_LIKED =
  "ADD_TO_UNLIKED_COMPANIES_FROM_LIKED";
export const addToUnlikedCompaniesFromLiked = (value) => ({
  type: ADD_TO_UNLIKED_COMPANIES_FROM_LIKED,
  payload: value,
});

export const ADD_TO_LIKED_COMPANIES_FROM_UNLIKED =
  "ADD_TO_LIKED_COMPANIES_FROM_UNLIKED";
export const addToLikedCompaniesFromunLiked = (value) => ({
  type: ADD_TO_LIKED_COMPANIES_FROM_UNLIKED,
  payload: value,
});

export const ADD_MORE_JOB_COMPANY_TO_SIMILAR_COMPANIES =
  "ADD_MORE_JOB_COMPANY_TO_SIMILAR_COMPANIES";
export const addToMoreJobcompany = (value) => ({
  type: ADD_MORE_JOB_COMPANY_TO_SIMILAR_COMPANIES,
  payload: value,
});
export const CHANGE_JOB_PROFILE_URL = "CHANGE_JOB_PROFILE_URL";
export const changeJobProfileUrl = (payload) => ({
  type: CHANGE_JOB_PROFILE_URL,
  payload,
});

export const CHANGE_JOB_PROFILE_NAME = "CHANGE_JOB_PROFILE_NAME";
export const changeIdealProfileName = (payload) => ({
  type: CHANGE_JOB_PROFILE_NAME,
  payload,
});

export const CLEAR_IDEAL_PROFILE_SUGGESTIONS =
  "CLEAR_IDEAL_PROFILE_SUGGESTIONS";
export const clearIdealProfileSuggestions = () => ({
  type: CLEAR_IDEAL_PROFILE_SUGGESTIONS,
});

export const FETCH_IDEAL_PROFILE_SUGGESTION = "FETCH_IDEAL_PROFILE_SUGGESTION";
export const FETCH_IDEAL_PROFILE_SUGGESTION_FAILURE =
  "FETCH_IDEAL_PROFILE_SUGGESTION_FAILURE";
export const FETCH_IDEAL_PROFILE_SUGGESTION_SUCCESS =
  "FETCH_IDEAL_PROFILE_SUGGESTION_SUCCESS";
export const fetchIdealProfileSuggestions = (name) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: FETCH_IDEAL_PROFILE_SUGGESTION,
    });
    const pubId = extractPublicIdentifier(name);
    const profiles = getState().jobSetup.jobIdealProfile.profiles;
    if (pubId) {
      dispatch({
        type: FETCH_IDEAL_PROFILE_SUGGESTION_SUCCESS,
        payload: [
          {
            name: name,
            headline: pubId,
            url: name,
            urn: "",
            imgSrc: "",
          },
        ],
      });
    } else {
      try {
        const { data } = await callLiWrapperApi(
          "SearchProfileSuggestion",
          null,
          { name }
        );
        const profs = data

        console.log("=============callLiWrapperApi===============", { data })
        const results = profs?.data.searchDashClustersByAll?.elements[1].items.map(({ item }) => item.entityResult)
        console.log("=============callLiWrapperApi===============", { results })
        const payload = results?.map((item) => {
          console.log("=============callLiWrapperApi===============", { item })
          let title = item?.title?.text || ""
          let primarySubtitle = item?.primarySubtitle?.text || ""
          let navigationUrl = item?.navigationUrl || ""
          let trackingUrn = item?.trackingUrn || ""
          let image = item?.image
          const vectorImage = image?.attributes[0]?.detailData?.nonEntityProfilePicture?.vectorImage;
          const imgSrc = getImgSrcURL(
            vectorImage?.rootUrl,
            vectorImage?.artifacts
          );
          if (title !== "") {
            return {
              name: title,
              headline: primarySubtitle?.replace(/\sat\s /, "@"),
              url: navigationUrl,
              urn: trackingUrn?.split(":")?.pop(),
              imgSrc,
            };
          }
        }).filter(
          ({ url }) =>
            !profiles
              .map(({ publicIdentifier }) => publicIdentifier)
              .includes(extractPublicIdentifier(url))
        );
        dispatch({
          type: FETCH_IDEAL_PROFILE_SUGGESTION_SUCCESS,
          payload,
        });
      } catch (e) {
        console.log(
          "Error in FETCH_IDEAL_PROFILE_SUGGESTION call back:",
          e?.message
        );
        throw e;
      }
    }
  } catch (err) {
    console.log("ERROR: ", err?.message);
    dispatch({
      type: FETCH_IDEAL_PROFILE_SUGGESTION_FAILURE,
    });
  }
};

export const SET_IDEAL_PROFILE_VIEW_CONTROLLER =
  "SET_IDEAL_PROFILE_VIEW_CONTROLLER";
export const setIdealProfileViewController = (payload) => ({
  type: SET_IDEAL_PROFILE_VIEW_CONTROLLER,
  payload,
});

export const QUICK_SCRAPE_IDEAL_PROFILE_DATA = "QUICK_SCRAPE_IDEAL_PROFILE_DATA";
export const quickScrapeIdealProfileData = (payload) => ({
  type: QUICK_SCRAPE_IDEAL_PROFILE_DATA,
  payload,
});

export const FETCH_IDEAL_PROFILE_DATA = "FETCH_IDEAL_PROFILE_DATA";
export const FETCH_IDEAL_PROFILE_DATA_SCRAPED = "FETCH_IDEAL_PROFILE_DATA_SCRAPED";
export const FETCH_IDEAL_PROFILE_DATA_SUCCESS = "FETCH_IDEAL_PROFILE_DATA_SUCCESS";
export const FETCH_IDEAL_PROFILE_DATA_SCRAPED_SUCCESS = "FETCH_IDEAL_PROFILE_DATA_SCRAPED_SUCCESS";
export const FETCH_IDEAL_PROFILE_DATA_FAILURE = "FETCH_IDEAL_PROFILE_DATA_FAILURE";
export const FETCH_IDEAL_PROFILE_DATA_SCRAPED_FAILURE = "FETCH_IDEAL_PROFILE_DATA_SCRAPED_FAILURE";

export const fetchIdealProfileData = ({ publicIdentifier, name }) => async (dispatch, getState) => {
  try {
    console.log(
      "==============FETCH_IDEAL_PROFILE_DATA_pubId JobSetup=================",
      publicIdentifier
    );

    dispatch({
      type: FETCH_IDEAL_PROFILE_DATA_SCRAPED,
      payload: publicIdentifier
    });
    let payload;
    console.log("<setting from li");
    payload = await Promise.race([
      await visitApiProfileLiNew(publicIdentifier),
      await visitApiProfileLiNew(publicIdentifier),
      await visitApiProfileLiNew(publicIdentifier)
    ])
    if (payload?.firstName && payload?.lastName) {
      const locations = [];
      const titleCount = await getTitleProfileCount(
        payload.experience[0]?.title?.replace(/[^a-zA-Z0-9 ]/g, ""),
        locations)

      dispatch({
        type: FETCH_IDEAL_PROFILE_DATA_SCRAPED_SUCCESS,
        payload: {
          ...payload,
          titleCount,
          isScraping: false,
          isScraped: true
        },
      });
    } else {
      alert(`${name}'s profile failed to fetch`)
      dispatch({
        type: FETCH_IDEAL_PROFILE_DATA_SCRAPED_FAILURE,
        payload: publicIdentifier
      });
    }
  } catch (err) {
    console.log("ERROR: ", err?.message);
    alert(`${name}'s profile failed to fetch`)
    dispatch({
      type: FETCH_IDEAL_PROFILE_DATA_SCRAPED_FAILURE,
      payload: publicIdentifier
    });
  }
};
export const REMOVE_SELECTED_IDEAL_PROFILE = "REMOVE_SELECTED_IDEAL_PROFILE";
export const removeSelectedIdealProfile = (payload) => ({
  type: REMOVE_SELECTED_IDEAL_PROFILE,
  payload,
});

export const ADD_JOB_SKILL = "ADD_JOB_SKILL";
export const OVERWRITE_JOB_SKILL = "OVERWRITE_JOB_SKILL";
export const addJobSkill = (payload, overwrite = false) => ({
  type: overwrite ? OVERWRITE_JOB_SKILL : ADD_JOB_SKILL,
  payload,
});

export const CHANGE_JOB_SKILL = "CHANGE_JOB_SKILL";
export const changeJobSkill = (payload) => ({
  type: CHANGE_JOB_SKILL,
  payload,
});

export const REMOVE_JOB_SKILL = "REMOVE_JOB_SKILL";
export const removeJobSkill = (payload) => ({
  type: REMOVE_JOB_SKILL,
  payload,
});

export const ADD_JOB_FUNCTION = "ADD_JOB_FUNCTION";
export const addJobFunction = (payload) => ({
  type: ADD_JOB_FUNCTION,
  payload,
});

export const CHANGE_JOB_FUNCTION = "CHANGE_JOB_FUNCTION";
export const changeJobFunction = (payload) => ({
  type: CHANGE_JOB_FUNCTION,
  payload,
});

export const REMOVE_JOB_FUNCTION = "REMOVE_JOB_FUNCTION";
export const removeJobFunction = (payload) => ({
  type: REMOVE_JOB_FUNCTION,
  payload,
});

export const ADD_JOB_EDUCATION = "ADD_JOB_EDUCATION";
export const addJobEducation = (payload) => ({
  type: ADD_JOB_EDUCATION,
  payload,
});

export const CHANGE_JOB_EDUCATION = "CHANGE_JOB_EDUCATION";
export const changeJobEducation = (payload) => ({
  type: CHANGE_JOB_EDUCATION,
  payload,
});

export const REMOVE_JOB_EDUCATION = "REMOVE_JOB_EDUCATION";
export const removeJobEducation = (payload) => ({
  type: REMOVE_JOB_EDUCATION,
  payload,
});

export const ADD_LIKED_ATTRIBUTES = "ADD_LIKED_ATTRIBUTES";
export const addLikedAttributes = (payload) => ({
  type: ADD_LIKED_ATTRIBUTES,
  payload,
});

//Tribe Member (New Job)

export const CHANGE_TRIBE_SUGGESTION_INPUT = "CHANGE_TRIBE_SUGGESTION_INPUT";
export const changeSuggestionInput = (value) => ({
  type: CHANGE_TRIBE_SUGGESTION_INPUT,
  payload: value,
});

export const SELECT_TRIBE_MEMBER = "SELECT_TRIBE_MEMBER";
export const selectTribeMember = (payload) => ({
  type: SELECT_TRIBE_MEMBER,
  payload,
});

export const SET_TRIBE_SUGGESTIONS = "SET_TRIBE_SUGGESTIONS";
export const setTribeSuggestions = (payload) => (dispatch, getState) => {
  const addedMember = getState()
    .jobSetup.tribeMembers.tribeCards.filter(({ added }) => added)
    .map(({ userId }) => userId);
  dispatch({
    type: SET_TRIBE_SUGGESTIONS,
    payload: payload.isEmpty
      ? []
      : payload.isEmail
        ? [
          {
            name: payload.inputValue,
            email: payload.inputValue,
            type: "invite",
            added: false,
            reachoutPermission: true,
            viewPermission: true,
          },
        ]
        : getState().jobSetup.tribeMembers.memberList.filter(
          (member) =>
            member.name.toLowerCase().trim().slice(0, payload.inputLength) ===
            payload.inputValue && !addedMember.includes(member.userId)
        ),
  });
};

export const FETCH_SUGGESTIONS_FOR_TRIBE_MEMBERS =
  "FETCH_SUGGESTIONS_FOR_TRIBE_MEMBERS";
export const FETCH_SUGGESTIONS_FOR_TRIBE_MEMBERS_SUCCESS =
  "FETCH_SUGGESTIONS_FOR_TRIBE_MEMBERS_SUCCESS";
export const FETCH_SUGGESTIONS_FOR_TRIBE_MEMBERS_FAILURE =
  "FETCH_SUGGESTIONS_FOR_TRIBE_MEMBERS_FAILURE";

export const fetchSuggestionForTribeMembers = (value) => (
  dispatch,
  getstate
) => {
  try {
    const languages = [
      {
        tribeName: "anas chaudhary",
        tribeEmail: "anasbinarif@gmail.com",
      },
      {
        tribeName: "ali raza",
        tribeEmail: "hey126@gmail.com",
      },
    ];
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    dispatch({
      type: FETCH_SUGGESTIONS_FOR_TRIBE_MEMBERS,
    });
    const temp =
      inputLength === 0
        ? []
        : languages.filter(
          (lang) =>
            lang.tribeName.toLowerCase().slice(0, inputLength) ===
            inputValue ||
            lang.tribeEmail.toLowerCase().slice(0, inputLength) === inputValue
        );
    console.log("+++++++++++++lan++++++", temp);
    dispatch({
      type: FETCH_SUGGESTIONS_FOR_TRIBE_MEMBERS_SUCCESS,
      payload: temp,
    });
  } catch (e) {
    dispatch({
      type: FETCH_SUGGESTIONS_FOR_TRIBE_MEMBERS_FAILURE,
    });
  }
};

export const GET_TRIBE_MEMBERS_CARDS_ON_SELECT =
  "GET_TRIBE_MEMBERS_CARDS_ON_SELECT";
export const getTribeMembersCardsOnSelect = (value) => ({
  type: GET_TRIBE_MEMBERS_CARDS_ON_SELECT,
  payload: value,
});

export const DELETE_TRIBE_MEMBER_CARD = "DELETE_TRIBE_MEMBER_CARD";
export const deleteTribeMemberCard = (value) => ({
  type: DELETE_TRIBE_MEMBER_CARD,
  payload: value,
});

export const GET_NOT_ADDED_TRIBE_MEMBERS = "GET_NOT_ADDED_TRIBE_MEMBERS";
export const getNotAddedTribeMember = (value) => (dispatch, getstate) => {
  const temp = { tribeName: "", tribeEmail: value };

  dispatch({
    type: GET_NOT_ADDED_TRIBE_MEMBERS,
    payload: temp,
  });
};

export const EMPTY_INPUT_VALUE_TRIBE_MEMBER = "EMPTY_INPUT_VALUE_TRIBE_MEMBER";
export const emptyInputTribeMember = () => ({
  type: EMPTY_INPUT_VALUE_TRIBE_MEMBER,
});

export const FETCH_FAV_TRIBE_MEMBERS = "FETCH_FAV_TRIBE_MEMBERS";
export const FETCH_FAV_TRIBE_MEMBERS_SUCCESS =
  "FETCH_FAV_TRIBE_MEMBERS_SUCCESS";
export const FETCH_FAV_TRIBE_MEMBERS_FAILURE =
  "FETCH_FAV_TRIBE_MEMBERS_FAILURE";
export const fetchFavTribeMembers = () => ({
  [CALL_API]: {
    types: [
      FETCH_FAV_TRIBE_MEMBERS,
      FETCH_FAV_TRIBE_MEMBERS_SUCCESS,
      FETCH_FAV_TRIBE_MEMBERS_FAILURE,
    ],
    endpoint: `/api/auth/tribes/favourite/tribe`,
    options: {
      method: "GET",
    },
  },
});

export const CHANGE_TRIBE_MEMBER = "CHANGE_TRIBE_MEMBER";
export const changeTribeMember = (payload) => ({
  type: CHANGE_TRIBE_MEMBER,
  payload,
});

export const FETCH_TRIBE_MEMBERS_LIST = "FETCH_TRIBE_MEMBERS_LIST";
export const FETCH_TRIBE_MEMBERS_LIST_SUCCESS =
  "FETCH_TRIBE_MEMBERS_LIST_SUCCESS";
export const FETCH_TRIBE_MEMBERS_LIST_FAILURE =
  "FETCH_TRIBE_MEMBERS_LIST_FAILURE";

export const fetchTribeMembersList = () => ({
  [CALL_API]: {
    types: [
      FETCH_TRIBE_MEMBERS_LIST,
      FETCH_TRIBE_MEMBERS_LIST_SUCCESS,
      FETCH_TRIBE_MEMBERS_LIST_FAILURE,
    ],
    endpoint: `/api/auth/tribes/team/list`,
    options: {
      method: "GET",
    },
  },
});

export const SENDING_TO_INCENTIVE = "SENDING_TO_INCENTIVE";
export const SENDING_TO_INCENTIVE_SUCCESS = "SENDING_TO_INCENTIVE_SUCCESS";
export const SENDING_TO_INCENTIVE_FAILURE = "SENDING_TO_INCENTIVE_FAILURE";

export const getIncentives = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: SENDING_TO_INCENTIVE,
    });

    const jobdescription = getState().jobSetup.jobDetails.description;

    const payload = await fetch(
      `${process.env.REACT_APP_NER_INCENTIVE_HOST}/incentive_detection`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          jobdescription,
        }),
      }
    ).then((r) => r.json());

    dispatch({
      type: SENDING_TO_INCENTIVE_SUCCESS,
      payload,
    });
  } catch (err) {
    console.log("Error: ", err.message);
    dispatch({
      type: SENDING_TO_INCENTIVE_FAILURE,
    });
  }
};

export const INIT_GENERATED_SPECIALTY = "INIT_GENERATED_SPECIALTY";
export const initGeneratedSpecialty = () => ({
  type: INIT_GENERATED_SPECIALTY,
});

export const SENDING_TO_NER = "SENDING_TO_NER";
export const SENDING_TO_NER_SUCCESS = "SENDING_TO_NER_SUCCESS";
export const SENDING_TO_NER_FAILURE = "SENDING_TO_NER_FAILURE";
export const SET_SIMILAR_COMPANIES = "SET_SIMILAR_COMPANIES";
export const SETTING_SIMILAR_COMPANIES = "SETTING_SIMILAR_COMPANIES";

export const sendJobDescriptionToNer = (view, viewFallback) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: SENDING_TO_NER,
    });

    dispatch({
      type: SET_JOB_SETUP_VIEW,
      payload: {
        ...view,
      },
    });

    const job_location = getState().jobSetup.jobLocation.selectedLocations;
    const jobdescription = getState().jobSetup.jobDetails.description;
    const jobSetup = getState().jobSetup.jobSetup.jobSetup;

    const url = process.env.REACT_APP_NER_ADDITIONAL_TITLE_SUGGESTED_TITLES

    fetch(url,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ideal: [],
          jobdescription: "",
          jobSetup,
          isEdit: false,
        }),
      }
    );

    fetch(
      `${process.env.REACT_APP_NER_ADDITIONAL_SPECIALITY_HOST}/similar_specialities`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ideal: [],
          jobdescription,
          jobSetup,
          isEdit: false,
        }),
      }
    );

    const payload = await fetch(process.env.REACT_APP_NER_HOST_JOB_DESCRIPTION,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          jobdescription,
          job_location,
        }),
      }
    ).then((r) => r.json());

    // console.log("===============sendJobDescriptionToNer===============", res)

    if (payload) {
      dispatch({
        type: SENDING_TO_NER_SUCCESS,
        payload,
      });
    } else {
      dispatch({
        type: SET_JOB_SETUP_VIEW,
        payload: {
          ...viewFallback,
        },
      });
      throw new Error("NER returned Empty");
    }
  } catch (err) {
    console.log("Error: ", err.message);
    dispatch({
      type: SENDING_TO_NER_FAILURE,
    });
  }
};

export const TOOGLE_lIKE_ATTRIBUTES = "TOOGLE_lIKE_ATTRIBUTES";
export const toggleLikeAttributes = (state, value, type, key = "value") => (
  dispatch,
  getState
) => {
  const payload = {
    ...getState().jobSetup.jobIdealProfile.likedAttributes,
  };
  if (state) {
    payload[type] = payload[type].filter(
      (attribute) => attribute[key] !== value
    );
  } else {
    const newAttribute = {};
    newAttribute[key] = value;
    payload[type] = [...payload[type], newAttribute];
  }
  dispatch({
    type: TOOGLE_lIKE_ATTRIBUTES,
    payload,
  });
};

export const SET_IDEAL_PROFILES = "SET_IDEAL_PROFILES";

export const sendIdealProfilesToNer = (view, isEdit = false) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: SENDING_TO_NER,
    });

    // const joblocation = getState().jobSetup.jobLocation.selectedLocations[0].name
    const ideal = getState().jobSetup.jobIdealProfile.profiles;
    const jobSetup = getState().jobSetup.jobSetup.jobSetup;

    const res = await fetch(process.env.REACT_APP_NER_HOST_IDEAL_PROFILE, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ideal,
        jobSetup,
        isEdit,
      }),
    }).then((r) => r.json());

    if (Array.isArray(res?.ideal) && (res?.ideal.length > 0 || isEdit)) {
      dispatch({
        type: SENDING_TO_NER_SUCCESS,
        payload: res?.jobSetup || jobSetup,
      });
      dispatch({
        type: SET_IDEAL_PROFILES,
        payload: res?.ideal,
      });
      dispatch({
        type: SET_JOB_SETUP_VIEW,
        payload: {
          view,
        },
      });
    } else {
      throw new Error("NER returned empty array");
    }
  } catch (err) {
    console.log("Error: ", err.message);
    dispatch({
      type: SENDING_TO_NER_FAILURE,
    });
  }
};

export const SET_IDEAL_PROFILE_IN_JOB_SETUP = "SET_IDEAL_PROFILE_IN_JOB_SETUP";
export const setIdealProfileInJobSetup = (view) => (dispatch, getState) => {
  const ideal_company = getState().jobSetup.jobIdealProfile.likedAttributes.companies.map(
    ({ value }) => value
  );

  const ideal_education = getState().jobSetup.jobIdealProfile.likedAttributes.educations.map(
    (ed) => ed
  );

  const ideal_experience = getState().jobSetup.jobIdealProfile.likedAttributes.experienceSpans.map(
    ({ value }) => parseInt(value)
  );

  const ideal_skill = getState().jobSetup.jobIdealProfile.likedAttributes.skills.map(
    ({ value }) => value
  );

  const ideal_title = getState().jobSetup.jobIdealProfile.likedAttributes.titles.map(
    ({ value }) => value
  );

  const ideal_specialities = getState().jobSetup.jobIdealProfile.likedAttributes.specialities.map(
    ({ value }) => value
  );

  dispatch({
    type: SET_IDEAL_PROFILE_IN_JOB_SETUP,
    payload: {
      ideal_company,
      ideal_education,
      ideal_experience,
      ideal_skill,
      ideal_title,
      ideal_specialities,
    },
  });
  dispatch({
    type: SET_JOB_SETUP_VIEW,
    payload: {
      view,
    },
  });
};

export const setCompaniesInJobSetup = (view, isEdit = false) => async (
  dispatch,
  getState
) => {
  let shouldSetSimilarCompanies = false
  try {
    dispatch({
      type: SENDING_TO_NER,
    });

    const { companyDetails } = getState().jobSetup;
    const likedCompanies = [];
    const competitorCompanies = [];
    companyDetails.similarCompanies.map((company) =>
      company.liked
        ? likedCompanies.push(company)
        : competitorCompanies.push(company)
    );

    const { companyHiringFor } = companyDetails;

    const jobSetup = getState().jobSetup.jobSetup.jobSetup;
    const jobdescription = getState().jobSetup.jobDetails.description;
    const ideal = getState().jobSetup.jobIdealProfile.profiles;

    if (likedCompanies.length > 0) {
      shouldSetSimilarCompanies = true
    }

    const payload = await fetch(process.env.REACT_APP_NER_HOST_SPECIALITY,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ideal,
          jobdescription,
          jobSetup: {
            ...jobSetup,
            companyHiringFor: [companyHiringFor],
            likedCompanies,
            competitorCompanies,
          },
          isEdit,
        }),
      }
    ).then((r) => r.json());

    if (!("isSuccess" in payload)) {
      dispatch({
        type: SENDING_TO_NER_SUCCESS,
        payload,
      });

      dispatch({
        type: SET_JOB_SETUP_VIEW,
        payload: {
          view,
        },
      });
    } else {
      throw new Error("NER returned empty array");
    }
  } catch (err) {
    console.log("Error: ", err);
    dispatch({
      type: SENDING_TO_NER_FAILURE,
    });
  }
  finally {
    if (shouldSetSimilarCompanies) {
      try {
        dispatch({
          type: SETTING_SIMILAR_COMPANIES,
        });
        const timeout = 4 * 60 * 1000;
        const controller = new AbortController();
        const id = setTimeout(() => controller.abort(), timeout);
        const { companyDetails } = getState().jobSetup;
        const likedCompaniesNames = companyDetails?.similarCompanies
          .filter((item) => item.liked)
          .map((item) => item.name);

        const hiringCompanyName = companyDetails?.companyHiringFor?.name;
        const similarComps = await fetch(process.env.REACT_APP_SIMILAR_COMPANIES, {
          method: "POST",
          signal: controller.signal,
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            companies: [hiringCompanyName, ...likedCompaniesNames].filter((k) => k),
          }),
        }).then((r) => r.json())
        clearTimeout(id);

        if (similarComps?.success) {
          let cmp = similarComps?.additionalCompanies.filter(
            (k) => ![null, "null"].includes(k)
          );
          if (cmp.length > 0) {
            dispatch({
              type: SET_SIMILAR_COMPANIES,
              payload: similarComps?.additionalCompanies,
            });
          }
        } else {
          throw new Error("SIMILAR COMPANIES SERVICE failed");
        }
      } catch (err) {
        console.log("Error: ", err);
        dispatch({
          type: SET_SIMILAR_COMPANIES,
          payload: [],
        });
      }
    }
  }
};


export const setCompaniesAndIdealProfileInJobSetup = (view, isEdit = false) => async (
  dispatch,
  getState
) => {
  let shouldSetSimilarCompanies = false
  try {
    dispatch({
      type: SENDING_TO_NER,
    });

    // const joblocation = getState().jobSetup.jobLocation.selectedLocations[0].name
    let ideal = getState().jobSetup.jobIdealProfile.profiles;
    let jobSetup = getState().jobSetup.jobSetup.jobSetup;

    const res = await fetch(process.env.REACT_APP_NER_HOST_IDEAL_PROFILE, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ideal,
        jobSetup,
        isEdit,
      }),
    }).then((r) => r.json());


    if (Array.isArray(res?.ideal) && (res?.ideal.length > 0 || isEdit)) {
      jobSetup = res?.jobSetup || jobSetup
      ideal = res?.ideal

      const ideal_company = getState().jobSetup.jobIdealProfile.likedAttributes.companies.map(
        ({ value }) => value
      );

      const ideal_education = getState().jobSetup.jobIdealProfile.likedAttributes.educations.map(
        (ed) => ed
      );

      const ideal_experience = getState().jobSetup.jobIdealProfile.likedAttributes.experienceSpans.map(
        ({ value }) => parseInt(value)
      );

      const ideal_skill = getState().jobSetup.jobIdealProfile.likedAttributes.skills.map(
        ({ value }) => value
      );

      const ideal_title = getState().jobSetup.jobIdealProfile.likedAttributes.titles.map(
        ({ value }) => value
      );

      const ideal_specialities = getState().jobSetup.jobIdealProfile.likedAttributes.specialities.map(
        ({ value }) => value
      );

      dispatch({
        type: SET_IDEAL_PROFILE_IN_JOB_SETUP,
        payload: {
          ideal_company,
          ideal_education,
          ideal_experience,
          ideal_skill,
          ideal_title,
          ideal_specialities,
        },
      });

    }

    const { companyDetails } = getState().jobSetup;
    const likedCompanies = [];
    const competitorCompanies = [];
    companyDetails.similarCompanies.map((company) =>
      company.liked
        ? likedCompanies.push(company)
        : competitorCompanies.push(company)
    );

    const { companyHiringFor } = companyDetails;
    const jobdescription = getState().jobSetup.jobDetails.description;

    if (likedCompanies.length > 0) {
      shouldSetSimilarCompanies = true
    }

    const payload = await fetch(process.env.REACT_APP_NER_HOST_SPECIALITY,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ideal,
          jobdescription,
          jobSetup: {
            ...jobSetup,
            companyHiringFor: [companyHiringFor],
            likedCompanies,
            competitorCompanies,
          },
          isEdit,
        }),
      }
    ).then((r) => r.json());

    if (!("isSuccess" in payload)) {
      dispatch({
        type: SENDING_TO_NER_SUCCESS,
        payload,
      });
    } else {
      throw new Error("NER returned empty array");
    }
  } catch (err) {
    console.log("Error: ", err);
    dispatch({
      type: SENDING_TO_NER_FAILURE,
    });
  }
  finally {
    dispatch({
      type: SET_JOB_SETUP_VIEW,
      payload: {
        view,
      },
    });
    if (shouldSetSimilarCompanies) {
      try {
        dispatch({
          type: SETTING_SIMILAR_COMPANIES,
        });
        const timeout = 4 * 60 * 1000;
        const { companyDetails } = getState().jobSetup;
        const likedCompaniesNames = companyDetails?.similarCompanies
          .filter((item) => item.liked)
          .map((item) => item.name);

        const hiringCompanyName = companyDetails?.companyHiringFor?.name;
        const controller = new AbortController();
        const id = setTimeout(() => controller.abort(), timeout);
        const similarComps = await fetch(process.env.REACT_APP_SIMILAR_COMPANIES, {
          method: "POST",
          signal: controller.signal,
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            companies: [hiringCompanyName, ...likedCompaniesNames].filter((k) => k),
          }),
        }).then((r) => r.json())
        clearTimeout(id);

        if (similarComps?.success) {
          let cmp = similarComps?.additionalCompanies.filter(
            (k) => ![null, "null"].includes(k)
          );
          if (cmp.length > 0) {
            dispatch({
              type: SET_SIMILAR_COMPANIES,
              payload: similarComps?.additionalCompanies,
            });
          }
        } else {
          throw new Error("SIMILAR COMPANIES SERVICE failed");
        }
      } catch (err) {
        console.log("Error: ", err);
        dispatch({
          type: SET_SIMILAR_COMPANIES,
          payload: [],
        });
      }
    }
  }
};


export const FETCH_ADDITIONAL_SPECIALTY_RESULTS =
  "FETCH_ADDITIONAL_SPECIALTY_RESULTS";
export const FETCH_ADDITIONAL_SPECIALTY_RESULTS_SUCCESS =
  "FETCH_ADDITIONAL_SPECIALTY_RESULTS_SUCCESS";
export const FETCH_ADDITIONAL_SPECIALTY_RESULTS_FAILURE =
  "FETCH_ADDITIONAL_SPECIALTY_RESULTS_FAILURE";

export const fetchAdditionalSpecialtyResults = (specialty) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: FETCH_ADDITIONAL_SPECIALTY_RESULTS,
      payload: specialty,
    });

    const locations = getState().jobSetup.jobLocation.selectedLocations;

    // const { metadata = {} } = await
    // callLiApi(
    //   `/voyager/api/search/dash/clusters?decorationId=com.linkedin.voyager.dash.deco.search.SearchClusterCollection-122&origin=FACETED_SEARCH&q=all&query=(keywords:"${encodeURIComponent(specialty.name)}",flagshipSearchIntent:SEARCH_SRP,queryParameters:(geoUrn:List(${locations.map(({ id }) => id).join(",")}),resultType:List(PEOPLE)),includeFiltersInResponse:false)&start=0`,
    //   {
    //     headers: {
    //       "x-li-lang": "en_US",
    //       "x-restli-protocol-version": "2.0.0",
    //     },
    //   }
    // )
    // .then((r) => r.data);

    const payload = await fetch(
      `${process.env.REACT_APP_API_HOST}/api/v2/specialty`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: specialty.name,
          locations,
        }),
      }
    ).then((r) => r.json());

    // const results = get(metadata, ["totalResultCount"], 0)
    const results = payload.data.count;

    if (results > 10) {
      dispatch({
        type: FETCH_ADDITIONAL_SPECIALTY_RESULTS_SUCCESS,
        payload: {
          specialty,
          results,
          variantSuggestions: [],
        },
      });
    } else {
      const { variantSuggestion = [] } = await fetch(
        "https://speciality-variant-suggestion-bdhweedyzq-uc.a.run.app/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ speciality: specialty?.name }),
        }
      ).then((r) => r.json());

      const specialities = getState().jobSetup.jobSpecialities.specialities;
      const specialitiesName = Object.keys(specialities).map((key) =>
        specialities[key]?.name?.trim()?.toLowerCase()
      );
      const specialitiesSynonm = Object.keys(specialities).map(
        (key) => specialities[key]?.synonym
      );
      const filteredVariantSuggestion =
        variantSuggestion?.filter(
          ({ variant = "" }) =>
            variant?.trim() !== "" &&
            !specialitiesName?.includes(variant?.trim()?.toLowerCase()) &&
            !specialitiesSynonm.some((synonym) =>
              synonym.some(
                ({ name }) =>
                  name?.trim()?.toLowerCase() === variant?.trim()?.toLowerCase()
              )
            )
        ) || [];

      dispatch({
        type: FETCH_ADDITIONAL_SPECIALTY_RESULTS_SUCCESS,
        payload: {
          specialty,
          variantSuggestions: filteredVariantSuggestion,
          results: results,
        },
      });
    }

    // if( get(metadata, ["totalResultCount"], 0) > 30){

    // }
  } catch (e) {
    dispatch({
      type: FETCH_ADDITIONAL_SPECIALTY_RESULTS_FAILURE,
      payload: specialty,
    });
  }
};

export const fetchSpecialityResults = (specialty, key) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(
      changeJobSpeciality({
        key,
        value: {
          fetchingResults: true,
        },
      })
    );

    const locations = getState().jobSetup.jobLocation.selectedLocations;

    // const { metadata = {} } = await
    // callLiApi(
    //   `/voyager/api/search/dash/clusters?decorationId=com.linkedin.voyager.dash.deco.search.SearchClusterCollection-122&origin=FACETED_SEARCH&q=all&query=(keywords:"${encodeURIComponent(specialty.name)}",flagshipSearchIntent:SEARCH_SRP,queryParameters:(geoUrn:List(${locations.map(({ id }) => id).join(",")}),resultType:List(PEOPLE)),includeFiltersInResponse:false)&start=0`,
    //   {
    //     headers: {
    //       "x-li-lang": "en_US",
    //       "x-restli-protocol-version": "2.0.0",
    //     },
    //   }
    // )
    //==@liWrapper==//
    // callLiWrapperApi('Speciality', null, {name:specialty.name, locations })
    // .then((r) => r.data);
    // console.log("============metadata=================", metadata);

    const payload = await fetch(
      `${process.env.REACT_APP_API_HOST}/api/v2/specialty`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: specialty.name,
          locations,
        }),
      }
    ).then((r) => r.json());

    // if ("totalResultCount" in metadata) {
    //   const { totalResultCount } = metadata;
    if (true) {
      const { count: totalResultCount } = payload.data;
      // if (totalResultCount > 30) {
      if (totalResultCount > 10) {
        dispatch(
          changeJobSpeciality({
            key,
            value: {
              fetchingResults: false,
              results: totalResultCount,
            },
          })
        );
      } else {
        const { variantSuggestion = [] } = await fetch(
          "https://speciality-variant-suggestion-bdhweedyzq-uc.a.run.app/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ speciality: specialty?.name }),
          }
        ).then((r) => r.json());
        const specialities = getState().jobSetup.jobSpecialities.specialities;
        const specialitiesName = Object.keys(specialities).map((key) =>
          specialities[key]?.name?.trim()?.toLowerCase()
        );
        const specialitiesSynonm = Object.keys(specialities).map(
          (key) => specialities[key]?.synonym
        );
        const filteredVariantSuggestion =
          variantSuggestion?.filter(
            ({ variant = "" }) =>
              variant?.trim() !== "" &&
              !specialitiesName?.includes(variant?.trim()?.toLowerCase()) &&
              !specialitiesSynonm.some((synonym) =>
                synonym.some(
                  ({ name }) =>
                    name?.trim()?.toLowerCase() ===
                    variant?.trim()?.toLowerCase()
                )
              )
          ) || [];
        if (filteredVariantSuggestion?.length > 0) {
          const popularVariantSuggestion = await Promise.all(
            filteredVariantSuggestion.filter(async (itm) => {
              // const callLiApiRes = await
              // callLiApi(
              //   `/voyager/api/search/dash/clusters?decorationId=com.linkedin.voyager.dash.deco.search.SearchClusterCollection-122&origin=FACETED_SEARCH&q=all&query=(keywords:"${encodeURIComponent(itm.variant)}",flagshipSearchIntent:SEARCH_SRP,queryParameters:(geoUrn:List(${locations.map(({ id }) => id).join(",")}),resultType:List(PEOPLE)),includeFiltersInResponse:false)&start=0`,
              //   {
              //     headers: {
              //       "x-li-lang": "en_US",
              //       "x-restli-protocol-version": "2.0.0",
              //     },
              //   }
              // )
              //==@liWrapper==//
              // callLiWrapperApi('Speciality', null, {name:itm.variant, locations })
              // .then((r) => r.data);

              const payload = await fetch(
                `${process.env.REACT_APP_API_HOST}/api/v2/specialty`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    name: itm.variant,
                    locations,
                  }),
                }
              ).then((r) => r.json());

              return payload.data.count > 10;

              // return callLiApiRes?.metadata?.totalResultCount>30
            })
          );
          dispatch(
            changeJobSpeciality({
              key,
              value: {
                fetchingResults: false,
                results: totalResultCount,
                variantSuggestions: popularVariantSuggestion,
              },
            })
          );
        } else {
          dispatch(
            changeJobSpeciality({
              key,
              value: {
                fetchingResults: false,
              },
            })
          );
        }
      }
    } else {
    }
  } catch (err) {
    console.log("ERROR: ", err.message);
    dispatch(
      changeJobSpeciality({
        key,
        value: {
          fetchingResults: false,
        },
      })
    );
  }
};

export const setAddtionalSpecialitiesInJobSetup = (isEdit = false) => async (
  dispatch,
  getState
) => {
  const { currentTab, tabs } = getState().jobSetup.viewController;
  try {
    dispatch({
      type: SENDING_TO_NER,
    });

    const {
      specialities,
      additionalSpecialties,
    } = getState().jobSetup.jobSpecialities;

    // console.log("====================setAddtionalSpecialitiesInJobSetup============================", {specialities, additionalSpecialties})

    const moreSpeciality = [
      ...Object.keys(specialities).reduce((prev, key) => {
        if (
          additionalSpecialties
            .map(({ name }) => name.trim().toLowerCase())
            .includes(specialities[key]?.name?.trim()?.toLowerCase())
        ) {
          return [...prev, { ...specialities[key], selected: true }];
        }
        return prev;
      }, []),
      ...additionalSpecialties.filter(
        ({ name }) =>
          !Object.keys(specialities)
            .map((key) => specialities[key]?.name?.trim().toLowerCase())
            .includes(name?.trim().toLowerCase())
      ),
    ];

    // console.log("====================setAddtionalSpecialitiesInJobSetup============================", {moreSpeciality})

    const company_speciality = [
      ...Object.keys(specialities)
        .filter(
          (key) =>
            specialities[key].selected &&
            !moreSpeciality
              .map(({ name }) => name?.trim().toLowerCase())
              .includes(specialities[key]?.name?.trim().toLowerCase())
        )
        .map((key) => ({
          ...specialities[key],
          min: specialities[key].experienceSelected
            ? specialities[key].experience.min
            : 0,
          max: specialities[key].experienceSelected
            ? specialities[key].experience.max
            : 0,
        })),
      ...moreSpeciality,
    ];

    const suggestedSpecialty = company_speciality
      .filter(({ source }) => source.includes("generated"))
      .map(({ name }) => name);

    const jobSetup = getState().jobSetup.jobSetup.jobSetup;
    const jobdescription = getState().jobSetup.jobDetails.description;
    const ideal = getState().jobSetup.jobIdealProfile.profiles;

    const payload = await fetch(
      `${process.env.REACT_APP_NER_ADDITIONAL_SPECIALITY_HOST}/similar_specialities`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ideal,
          jobdescription,
          jobSetup: {
            ...jobSetup,
            company_speciality: company_speciality.filter(
              ({ source }) =>
                !(source.length === 1 && source.includes("generated"))
            ),
          },
          isEdit,
        }),
      }
    ).then((r) => r.json());

    let newTabs = tabs;

    if (Object.keys(payload || {}).length > 0) {
      const newSpecialty = (payload?.company_speciality || []).filter(
        ({ name, source = [] }) =>
          source.includes("generated") && !suggestedSpecialty.includes(name)
      );
      if (newSpecialty.length > 0) {
        if (!tabs.includes("Suggested")) {
          if (newTabs.includes("From Best Fits")) {
            newTabs = [
              ...tabs.slice(0, tabs.indexOf("From Best Fits") + 1),
              "Suggested",
              ...tabs.slice(tabs.indexOf("From Best Fits") + 1),
            ];
          } else if (newTabs.includes("From Companies")) {
            newTabs = [
              ...tabs.slice(0, tabs.indexOf("From Companies") + 1),
              "Suggested",
              ...tabs.slice(tabs.indexOf("From Companies") + 1),
            ];
          }
        }

        newSpecialty.map(
          (
            {
              name,
              min,
              max,
              experienceSelected,
              selected,
              source = [],
              sourceId,
              spans,
              synonym = [],
            },
            i
          ) => {
            dispatch(
              addJobSpeciality({
                name,
                spans,
                experienceSelected,
                experience: {
                  min,
                  max,
                },
                selected,
                synonym,
                source,
                sourceId,
              })
            );
          }
        );
        dispatch({
          type: SET_JOB_SETUP_VIEW,
          payload: {
            tabs: newTabs,
            currentTab: currentTab + 1,
          },
        });
      } else if (tabs.includes("Suggested")) {
        dispatch({
          type: SET_JOB_SETUP_VIEW,
          payload: {
            tabs:
              suggestedSpecialty.length > 0
                ? tabs
                : tabs.filter((itm) => itm !== "Suggested"),
            currentTab: currentTab + 1,
          },
        });
      } else {
        dispatch({
          type: SET_JOB_SETUP_VIEW,
          payload: {
            currentTab: currentTab + 1,
          },
        });
      }

      dispatch({
        type: SENDING_TO_NER_SUCCESS,
        payload,
      });
    } else {
      throw new Error("NER returned empty array");
    }
  } catch (err) {
    console.log("Error: ", err);
    dispatch({
      type: SET_JOB_SETUP_VIEW,
      payload: {
        currentTab: currentTab + 1,
      },
    });
    dispatch({
      type: SENDING_TO_NER_FAILURE,
    });
  }
};

export const saveAdditionalSpecialties = () => (dispatch, getState) => {
  const {
    specialities,
    additionalSpecialties,
  } = getState().jobSetup.jobSpecialities;
  const specialitiesToFilter = Object.keys(specialities).map((key) =>
    specialities[key]?.name?.trim().toLowerCase()
  );

  for (let index = 0; index < additionalSpecialties.length; index++) {
    const specialty = additionalSpecialties[index];
    // console.log("==============saveAdditionalSpecialties===================", {specialty, specialitiesToFilter})
    if (
      specialty?.name?.trim() !== "" &&
      !specialitiesToFilter.includes(specialty?.name?.trim().toLowerCase())
    ) {
      specialitiesToFilter.push(specialty?.name?.trim().toLowerCase());

      // console.log("==============ADD_JOB_SPECIALITY===================", {specialty, specialitiesToFilter})
      dispatch({
        type: ADD_JOB_SPECIALITY,
        payload: { ...specialty, selected: true },
      });
    } else if (
      specialitiesToFilter.includes(specialty?.name?.trim().toLowerCase())
    ) {
      const updateSpecialityKey = Object.keys(specialities)
        .filter(
          (key) =>
            specialities[key]?.name?.trim().toLowerCase() ===
            specialty?.name?.trim().toLowerCase()
        )
        ?.pop();

      // console.log("==============updateSpecialityKey===================", {specialty, specialitiesToFilter, updateSpecialityKey})

      if (updateSpecialityKey) {
        dispatch(
          changeJobSpeciality({
            key: updateSpecialityKey,
            value: {
              selected: true,
            },
          })
        );
      }
    }
  }

  dispatch({
    type: INIT_ADDITIONAL_JOB_SPECIALITY,
  });
};

export const setSpecialitiesInJobSetup = (view, isEdit = false) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: SENDING_TO_NER,
    });

    const {
      specialities,
      additionalSpecialties,
    } = getState().jobSetup.jobSpecialities;
    const specialitiesToFilter = Object.keys(specialities).map((key) =>
      specialities[key]?.name?.trim().toLowerCase()
    );
    const company_speciality = Object.keys(specialities)
      .filter((key) => specialities[key].selected)
      .map((key) => {
        // specialitiesToFilter.push(specialities[key].name.trim().toLowerCase())
        return {
          ...specialities[key],
          min: specialities[key].experienceSelected
            ? specialities[key].experience.min
            : 0,
          max: specialities[key].experienceSelected
            ? specialities[key].experience.max
            : 0,
        };
      });

    for (let index = 0; index < additionalSpecialties.length; index++) {
      const specialty = additionalSpecialties[index];
      if (
        specialty?.name?.trim() !== "" &&
        !specialitiesToFilter.includes(specialty?.name?.trim().toLowerCase())
      ) {
        company_speciality.push(specialty);
        specialitiesToFilter.push(specialty?.name?.trim().toLowerCase());
        dispatch({
          type: ADD_JOB_SPECIALITY,
          payload: { ...specialty, selected: true },
        });
      } else if (
        specialitiesToFilter.includes(specialty?.name?.trim().toLowerCase())
      ) {
        const updateSpecialityKey = Object.keys(specialities)
          .filter(
            (key) =>
              specialities[key]?.name?.trim().toLowerCase() ===
              specialty?.name?.trim().toLowerCase()
          )
          ?.pop();

        // console.log("==============updateSpecialityKey===================", {specialty, specialitiesToFilter, updateSpecialityKey})
        if (!specialities[updateSpecialityKey]?.selected) {
          company_speciality.push({
            ...specialities[updateSpecialityKey],
            selected: true,
          });
        }
        if (updateSpecialityKey) {
          dispatch(
            changeJobSpeciality({
              key: updateSpecialityKey,
              value: {
                selected: true,
              },
            })
          );
        }
      }
    }

    dispatch({
      type: INIT_ADDITIONAL_JOB_SPECIALITY,
    });

    const jobSetup = getState().jobSetup.jobSetup.jobSetup;
    const jobdescription = getState().jobSetup.jobDetails.description;
    const ideal = getState().jobSetup.jobIdealProfile.profiles;

    const payload = await fetch(process.env.REACT_APP_NER_HOST_MAIN_TITLE,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ideal,
          jobdescription,
          jobSetup: {
            ...jobSetup,
            company_speciality,
          },
          isEdit,
        }),
      }
    ).then((r) => r.json());

    if (!("isSuccess" in payload)) {
      dispatch({
        type: SENDING_TO_NER_SUCCESS,
        payload: {
          ...payload,
          job_position: payload.job_position.map((item) => ({
            id: v4(),
            ...item,
          })),
        },
      });

      dispatch({
        type: SET_JOB_SETUP_VIEW,
        payload: {
          view,
        },
      });
    } else {
      throw new Error("NER returned empty array");
    }
  } catch (err) {
    console.log("Error: ", err);
    dispatch({
      type: SENDING_TO_NER_FAILURE,
    });
  }
};

export const setExperienceInJobSetup = (view, isEdit = false) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: SENDING_TO_NER,
    });

    const total_experience = getState().jobSetup.jobCriteria.totalExperience;

    const jobSetup = getState().jobSetup.jobSetup.jobSetup;
    const jobdescription = getState().jobSetup.jobDetails.description;
    const ideal = getState().jobSetup.jobIdealProfile.profiles;

    const payload = await fetch(process.env.REACT_APP_NER_HOST_SKILLS, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ideal,
        jobdescription,
        jobSetup: {
          ...jobSetup,
          total_experience,
        },
        isEdit,
      }),
    }).then((r) => r.json());

    if (!("isSuccess" in payload)) {
      dispatch({
        type: SENDING_TO_NER_SUCCESS,
        payload,
      });

      dispatch({
        type: SET_JOB_SETUP_VIEW,
        payload: {
          view,
        },
      });
    } else {
      throw new Error("NER returned empty array");
    }
  } catch (err) {
    console.log("Error: ", err);
    dispatch({
      type: SENDING_TO_NER_FAILURE,
    });
  }
};

export const setMainTitleInJobSetup = (view, isEdit = false) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: SENDING_TO_NER,
    });

    const job_position = [getState().jobSetup.jobCriteria.mainTitle];
    const jobSetup = getState().jobSetup.jobSetup.jobSetup;
    const jobdescription = getState().jobSetup.jobDetails.description;
    const ideal = getState().jobSetup.jobIdealProfile.profiles;

    const url = process.env.REACT_APP_NER_ADDITIONAL_TITLE_SUGGESTED_TITLES

    const payload = await fetch(url,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ideal,
          jobdescription,
          jobSetup: {
            ...jobSetup,
            job_position,
          },
          isEdit,
        }),
      }
    ).then((r) => r.json());

    if (!("isSuccess" in payload)) {
      if (
        payload?.job_position?.length > 0 &&
        payload?.job_position?.[0]?.rank
      ) {
        dispatch(
          changeMainJobTitle({
            rank: payload?.job_position?.[0]?.rank,
            alternativeTitle: get(
              payload,
              ["job_position", "0", "alternativeTitle"],
              []
            ), //payload?.job_position?.[0]?.rank
          })
        );
      }

      dispatch({
        type: SENDING_TO_NER_SUCCESS,
        payload: {
          ...payload,
          suggested_titles: [...jobSetup.suggested_titles, ...payload.suggested_titles.filter(({ name }) => !jobSetup.suggested_titles.some(title => title.name?.trim()?.toLowerCase() === name?.trim()?.toLowerCase())).map((item) => ({
            id: v4(),
            ...item,
          }))],
        },
      });

      dispatch({
        type: SET_JOB_SETUP_VIEW,
        payload: {
          view,
        },
      });
    } else {
      throw new Error("NER returned empty array");
    }
  } catch (err) {
    console.log("Error: ", err);
    dispatch({
      type: SENDING_TO_NER_FAILURE,
    });
  }
};

export const saveAdditionalTitles = () => (dispatch, getState) => {
  const { titles, additionalTitles } = getState().jobSetup.jobCriteria;
  // const titlesToFilter = Object.keys(titles).filter((key) => titles[key].selected && titles[key].name?.trim() !== "").map((key) => titles[key]) || [];

  const titlesToFilter =
    Object.keys(titles)
      .filter((key) => titles[key].selected && titles[key].name?.trim() !== "")
      .map((key) => titles[key]) || [];

  console.log("Titles to filter are: ", titlesToFilter);

  for (let index = 0; index < additionalTitles.length; index++) {
    const ttl = additionalTitles[index];
    const ttlCompSize = ttl.company_size.reduce(
      (obj, item) => {
        return { ...obj, min: item.min, max: item.max };
      },
      { min: null, max: null }
    );

    if (
      (isEmpty(titlesToFilter) && ttl?.name?.trim() !== "") ||
      (ttl?.name?.trim() !== "" &&
        titlesToFilter.filter((item) => {
          // console.log("ttl comp size: ", ttlCompSize)
          const filterCondition = item.uniqProp !== ttl.uniqProp; //!((item.company_size[0].min !== ttlCompSize.min || item.company_size[0].max !== ttlCompSize.max)) || item.name.trim().toLowerCase() !== ttl.name.trim().toLowerCase()
          // console.log("filter condition is: ", { filterCondition, item, ttl })
          return filterCondition;
        }).length > 0)
      // !titlesToFilter.includes(ttl.name.trim().toLowerCase())
    ) {
      if (typeof (ttl !== "string")) {
        titlesToFilter.push(ttl);
      }
      console.log("dispatching add job title");
      dispatch({
        type: ADD_JOB_TITLE,
        payload: { ...ttl, selected: true },
      });
    } else {
      console.log("REMOVE JOB TITLE");
      //remove job title here
    }
  }

  dispatch({
    type: INIT_ADDITIONAL_JOB_TITLE,
  });
};

export const setTitlesInJobSetup = (view, isEdit = false) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: SENDING_TO_NER,
    });

    // const { titles } = getState().jobSetup.jobCriteria;
    // const suggested_titles =
    //   Object.keys(titles)
    //     .filter(
    //       (key) =>
    //         titles[key].selected &&
    //         !titles[key].isMainTitle &&
    //         titles[key].name?.trim() !== ""
    //     )
    //     .map((key) => titles[key]) || [];

    const { titles, additionalTitles } = getState().jobSetup.jobCriteria;
    // console.log("==============updateTitleKey===================", {titles, additionalTitles})
    const titlesToFilter = Object.keys(titles)
      .filter((key) => titles[key].name?.trim() !== "" && titles[key]?.selected)
      .map((key) => titles[key]);
    // console.log("==============updateTitleKey===================", {titlesToFilter})
    const suggested_titles =
      Object.keys(titles)
        .filter(
          (key) =>
            titles[key].selected &&
            !titles[key].isMainTitle &&
            titles[key].name?.trim() !== ""
        )
        .map((key) => {
          // titlesToFilter.push(titles[key].name.trim().toLowerCase())
          return titles[key];
        }) || [];

    for (let index = 0; index < additionalTitles.length; index++) {
      const ttl = {
        ...additionalTitles[index],
        uniqProp: `${additionalTitles[index].name}_${additionalTitles[index].company_size[0].min}_${additionalTitles[index].company_size[0].max}`,
      };
      const ttlCompSize = ttl.company_size.reduce(
        (obj, item) => {
          return { ...obj, min: item.min, max: item.max };
        },
        { min: null, max: null }
      );

      console.log("DEBUG DEBUG: ", { ttl, titlesToFilter });
      if (isEmpty(titlesToFilter)) {
        dispatch({
          type: ADD_JOB_TITLE,
          payload: { ...ttl, selected: true },
        });
      }

      if (
        ttl?.name?.trim() !== "" &&
        titlesToFilter.filter((item) => {
          // console.log("ttl comp size: ", ttlCompSize)
          const filterCondition = item.uniqProp !== ttl.uniqProp;
          // console.log("filter condition is: ", { filterCondition, item, ttl })
          return filterCondition;
        }).length > 0
        // ttl.name.trim() !== "" && !titlesToFilter.map(item => item.name.toLowerCase().trim()).includes(ttl.name.trim().toLowerCase())
      ) {
        suggested_titles.push(ttl);
        if (typeof ttl !== "string") {
          titlesToFilter.push(ttl);
        }
        // console.log("ADDING JOB TITLEEERER")
        dispatch({
          type: ADD_JOB_TITLE,
          payload: { ...ttl, selected: true },
        });
      } else if (titlesToFilter.includes(ttl?.name?.trim().toLowerCase())) {
        const updateTitleKey = Object.keys(titles)
          .filter(
            (key) =>
              titles[key]?.name?.trim().toLowerCase() ===
              ttl?.name?.trim().toLowerCase()
          )
          ?.pop();

        // console.log("==============updateTitleKey===================", {titles, titlesToFilter, updateTitleKey})
        if (!titles[updateTitleKey]?.selected) {
          suggested_titles.push({ ...titles[updateTitleKey], selected: true });
        }
        if (updateTitleKey) {
          dispatch(
            changeJobTitle({
              key: updateTitleKey,
              value: {
                selected: true,
              },
            })
          );
        }
      }
    }

    dispatch({
      type: INIT_ADDITIONAL_JOB_TITLE,
    });

    const jobSetup = getState().jobSetup.jobSetup.jobSetup;
    const jobdescription = getState().jobSetup.jobDetails.description;
    const ideal = getState().jobSetup.jobIdealProfile.profiles;

    const payload = await fetch(process.env.REACT_APP_NER_HOST_EXPIRENCE,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ideal,
          jobdescription,
          jobSetup: {
            ...jobSetup,
            suggested_titles,
          },
          isEdit,
        }),
      }
    ).then((r) => r.json());

    if (!("isSuccess" in payload)) {
      dispatch({
        type: SENDING_TO_NER_SUCCESS,
        payload,
      });

      dispatch({
        type: SET_JOB_SETUP_VIEW,
        payload: {
          view,
        },
      });
    } else {
      throw new Error("NER returned empty array");
    }
  } catch (err) {
    console.log("Error: ", err);
    dispatch({
      type: SENDING_TO_NER_FAILURE,
    });
  }
};

export const setFunctionsInJobSetup = (view, isEdit = false) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: SENDING_TO_NER,
    });

    const functionsData = getState().jobSetup.jobCriteria.functions;
    const functions = Object.keys(functionsData)
      .filter((key) => functionsData[key].selected)
      .map((key) => ({
        experienceSelected: functionsData[key].experienceSelected,
        min: functionsData[key].experienceSelected
          ? functionsData[key].experience.min
          : 0,
        max: functionsData[key].experienceSelected
          ? functionsData[key].experience.max
          : 0,
        name: functionsData[key].name,
      }));

    const jobSetup = getState().jobSetup.jobSetup.jobSetup;
    const jobdescription = getState().jobSetup.jobDetails.description;
    const ideal = getState().jobSetup.jobIdealProfile.profiles;

    const payload = await fetch(process.env.REACT_APP_NER_HOST_SKILLS, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ideal,
        jobdescription,
        jobSetup: {
          ...jobSetup,
          functions,
        },
        isEdit,
      }),
    }).then((r) => r.json());

    if (!("isSuccess" in payload)) {
      dispatch({
        type: SENDING_TO_NER_SUCCESS,
        payload,
      });

      dispatch({
        type: SET_JOB_SETUP_VIEW,
        payload: {
          view,
        },
      });
    } else {
      throw new Error("NER returned empty array");
    }
  } catch (err) {
    console.log("Error: ", err);
    dispatch({
      type: SENDING_TO_NER_FAILURE,
    });
  }
};

export const saveAdditionalSkills = () => (dispatch, getState) => {
  const skills = getState().jobSetup.jobCriteria.skills;
  const additionalSkills = getState().jobSetup.jobCriteria.additionalSkills;

  const skillsToFilter = Object.keys(skills)
    .filter((key) => skills[key].selected)
    .map((key) => skills[key]?.name?.trim().toLowerCase());

  for (let index = 0; index < additionalSkills.length; index++) {
    const skl = additionalSkills[index];
    if (
      skl?.name?.trim() !== "" &&
      !skillsToFilter.includes(skl?.name?.trim().toLowerCase())
    ) {
      skillsToFilter.push(skl?.name?.trim().toLowerCase());
      dispatch({
        type: ADD_JOB_SKILL,
        payload: { ...skl, selected: true },
      });
    }
  }

  dispatch({
    type: INIT_ADDITIONAL_JOB_SKILLS,
  });
};

export const setSkillsInJobSetup = (view, isEdit = false) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: SENDING_TO_NER,
    });

    const importanceMap = {
      Low: "1",
      Medium: "3",
      High: "5",
    };

    const skillsData = getState().jobSetup.jobCriteria.skills;
    const additionalSkillsData = getState().jobSetup.jobCriteria
      .additionalSkills;
    const additionalSkills = additionalSkillsData.map((itm) => ({
      ...itm,
      importance:
        importanceMap[
        Object.keys(itm.importance)
          .filter((k) => itm.importance[k])
          .pop()
        ],
    }));
    const skillsToFilter = [];
    const skills = Object.keys(skillsData)
      .filter((key) => skillsData[key].selected)
      .map((key) => {
        skillsToFilter.push(skillsData[key]?.name?.trim().toLowerCase());
        return {
          ...skillsData[key],
          min: skillsData[key].experienceSelected
            ? skillsData[key].experience.min
            : 0,
          max: skillsData[key].experienceSelected
            ? skillsData[key].experience.max
            : 0,
          importance:
            importanceMap[
            Object.keys(skillsData[key].importance)
              .filter((k) => skillsData[key].importance[k])
              .pop()
            ],
          experienceSelected: skillsData[key].experienceSelected,
        };
      });

    for (let index = 0; index < additionalSkills.length; index++) {
      const skl = additionalSkills[index];
      if (
        skl?.name?.trim() !== "" &&
        !skillsToFilter.includes(skl?.name?.trim().toLowerCase())
      ) {
        skills.push(skl);
        skillsToFilter.push(skl?.name?.trim().toLowerCase());
        dispatch({
          type: ADD_JOB_SKILL,
          payload: {
            ...skl,
            importance: additionalSkillsData[index].importance,
            selected: true,
          },
        });
      }
    }

    dispatch({
      type: INIT_ADDITIONAL_JOB_SKILLS,
    });

    const jobSetup = getState().jobSetup.jobSetup.jobSetup;
    const jobdescription = getState().jobSetup.jobDetails.description;
    const ideal = getState().jobSetup.jobIdealProfile.profiles;

    const url = process.env.REACT_APP_NER_HOST_EDUCATION

    const payload = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ideal,
        jobdescription,
        jobSetup: {
          ...jobSetup,
          skills,
        },
        isEdit,
      }),
    }).then((r) => r.json());

    if (!("isSuccess" in payload)) {
      dispatch({
        type: SENDING_TO_NER_SUCCESS,
        payload,
      });

      dispatch({
        type: SET_JOB_SETUP_VIEW,
        payload: {
          view,
        },
      });
    } else {
      throw new Error("NER returned empty array");
    }
  } catch (err) {
    console.log("Error: ", err);
    dispatch({
      type: SENDING_TO_NER_FAILURE,
    });
  }
};

export const SET_IS_JOB_EDIT_SUCCESS = "SET_IS_JOB_EDIT_SUCCESS";
export const _resetIsJobEditSuccess = () => (dispatch) => {
  dispatch({
    type: SET_IS_JOB_EDIT_SUCCESS,
    payload: false,
  });
};

export const setEducationInJobSetup = (
  view,
  edit = false,
  jobId = null,
  initLoader = (e) => false,
  finLoader = (e) => false,
  started
) => async (dispatch, getState) => {
  try {
    if (started) {
      dispatch({
        type: SET_IS_JOB_EDIT_SUCCESS,
        payload: false,
      });
    } else {
      initLoader();
      console.log(
        "==============setEducationInJobSetup================",
        view,
        edit,
        jobId,
        initLoader,
        finLoader
      );
      const importanceMap = {
        Low: "1",
        Medium: "3",
        High: "5",
      };

      const educationsData = getState().jobSetup.jobCriteria.educations;
      const education = Object.keys(educationsData)
        .filter((key) => educationsData[key].selected)
        .map((key) => ({
          ...educationsData[key],
          importance:
            importanceMap[
            Object.keys(educationsData[key].importance)
              .filter((k) => educationsData[key].importance[k])
              .pop()
            ],
        }));

      const jobSetup = getState().jobSetup.jobSetup.jobSetup;

      const payload = {
        ...jobSetup,
        education,
      };

      dispatch({
        type: SENDING_TO_NER_SUCCESS,
        payload,
      });
      console.log("SAVE BUTTON CLICKED", getState().jobSetup.jobSetup.jobSetup);
      if (edit) {
        const specialitiesData = getState().jobSetup.jobSpecialities
          .specialities;
        const company_speciality = Object.keys(specialitiesData)
          .filter((key) => specialitiesData[key].selected)
          .map((key) => ({
            ...specialitiesData[key],
            min: specialitiesData[key].experienceSelected
              ? specialitiesData[key].experience.min
              : 0,
            max: specialitiesData[key].experienceSelected
              ? specialitiesData[key].experience.max
              : 0,
          }));

        const mainTitle = getState().jobSetup.jobCriteria.mainTitle;
        const titlesData = getState().jobSetup.jobCriteria.titles;

        const job_position = [mainTitle];

        const suggested_titles =
          Object.keys(titlesData)
            .filter(
              (key) =>
                titlesData[key].selected &&
                !titlesData[key].isMainTitle &&
                titlesData[key].name?.trim() !== ""
            )
            .map((key) => titlesData[key]) || [];

        const total_experience = getState().jobSetup.jobCriteria
          .totalExperience;

        const functionsData = getState().jobSetup.jobCriteria.functions;
        const functions =
          Object.keys(functionsData)
            .filter((key) => functionsData[key].selected)
            .map((key) => ({
              experienceSelected: functionsData[key].experienceSelected,
              min: functionsData[key].experienceSelected
                ? functionsData[key].experience.min
                : 0,
              max: functionsData[key].experienceSelected
                ? functionsData[key].experience.max
                : 0,
              name: functionsData[key].name,
            })) || [];

        const skillsData = getState().jobSetup.jobCriteria.skills;
        const skills =
          Object.keys(skillsData)
            .filter((key) => skillsData[key].selected)
            .map((key) => ({
              ...skillsData[key],
              min: skillsData[key].experienceSelected
                ? skillsData[key].experience.min
                : 0,
              max: skillsData[key].experienceSelected
                ? skillsData[key].experience.max
                : 0,
              importance:
                importanceMap[
                Object.keys(skillsData[key].importance)
                  .filter((k) => skillsData[key].importance[k])
                  .pop()
                ],
              experienceSelected: skillsData[key].experienceSelected,
            })) || [];

        const jobArray = {
          competitorCompanies: [],
          companyHiringFor: [],
          company_speciality: [],
          likedCompanies: [],
          education: [],
          functions: [],
          ideal_company: [],
          ideal_education: [],
          ideal_experience: [],
          ideal_skill: [],
          ideal_title: [],
          job_location: [],
          job_position: [],
          skill: [],
          skills: [],
          speciality: [],
          suggested_skills: [],
          suggested_titles: [],
          technology_work_experience: [],
          total_experience: [],
          suggested_titles_copy: [],
          ideal_specialities: [],
          incentive_section: "",
          company_section: "",
          ...getState().jobSetup.jobSetup.jobSetup,
          company_speciality,
          job_position,
          suggested_titles,
          total_experience,
          functions,
          skills,
          education,
          job_location: getState().jobSetup.jobLocation.selectedLocations,
        };

        console.log(
          "=================Edit Job Array=========================",
          jobArray
        );
        const ideal = getState().jobSetup.jobIdealProfile.profiles;

        const r = await manualApiCall(`/api/v2/auth/jobs/${jobId}/edit`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            jobSetup: jobArray,
            benchmarkProspects: {
              similar: null,
              ideal,
            },
          }),
        }).catch((err) => {
          console.log("===========Edit Job Error============", err);
          dispatch({
            type: SET_IS_JOB_EDIT_SUCCESS,
            payload: false,
          });
        });

        if (r) {
          console.log("===========Edit Job Response============", r);
          dispatch({
            type: SET_IS_JOB_EDIT_SUCCESS,
            payload: true,
          });
        }
      }
      if (!edit) {
        dispatch({
          type: SET_JOB_SETUP_VIEW,
          payload: {
            view,
          },
        });
      }
      finLoader();
    }
  } catch (err) {
    console.log("ERROR: ", err.message);
    finLoader();
  }
};

export const INITIALISING_JOB_TITLES = "INITIALISING_JOB_TITLES";
export const INITIALISING_JOB_TITLES_SUCCESS =
  "INITIALISING_JOB_TITLES_SUCCESS";
export const INITIALISING_JOB_TITLES_FAILURE =
  "INITIALISING_JOB_TITLES_FAILURE";
// export const ADD_MAIN_JOB_TITLE = "ADD_MAIN_JOB_TITLE"
export const initialiseTitles = () => async (dispatch, getState) => {
  try {
    const companySizeOptions = [
      0,
      1,
      10,
      50,
      200,
      500,
      1000,
      5000,
      10000,
      10001,
    ];

    dispatch({ type: INITIALISING_JOB_TITLES });
    const locations = getState().jobSetup.jobLocation.selectedLocations;
    const titles = [
      ...getState().jobSetup.jobSetup.jobSetup.job_position.map((item) => ({
        ...item,
        isMainTitle: true,
      })),
    ];

    const mainTitle = getState().jobSetup.jobCriteria.mainTitle;
    // console.log(
    //   "===================jobCriteriaTitles==============================",
    //   jobCriteriaTitles
    // );
    // console.log(
    //   "===================titles==============================",
    //   titles
    // );
    // const mainTitles = Object.keys(jobCriteriaTitles)
    //   .filter((key) => jobCriteriaTitles[key].isMainTitle)
    //   .map((key) => jobCriteriaTitles[key]);
    let payload = {
      name: "",
      company_size: [
        {
          min: 1,
          max: 10001,
        },
      ],
      spans: [],
      synonym: [],
      results: 0,
      selected: true,
      initial: false,
      rank: get(
        getState(),
        ["jobSetup", "jobSetup", "jobSetup", "job_position", "0", "rank"],
        3
      ),
      type: [],
    };

    if (mainTitle?.initial && titles?.length > 0) {
      const {
        name,
        company_size_max,
        company_size_min,
        spans,
        synonym,
        isMainTitle = false,
      } = titles?.[0];
      const min =
        company_size_min >= 10000
          ? 10000
          : companySizeOptions.includes(company_size_min)
            ? companySizeOptions.filter((n) => company_size_min === n)?.[0]
            : 0;

      const company_size =
        titles?.[0]?.company_size?.length > 0
          ? titles?.[0]?.company_size
          : [
            {
              min,
              max:
                company_size_max > 10000
                  ? 10001
                  : companySizeOptions.includes(company_size_max)
                    ? companySizeOptions.filter(
                      (n) => company_size_max === n
                    )?.[0]
                    : companySizeOptions.includes(company_size_min)
                      ? companySizeOptions[companySizeOptions.indexOf(min) + 1]
                      : 10001,
            },
          ];

      // USCode: 103644278

      // const resInSelectedLocation = name?.length>0 ? await callLiApi(`/voyager/api/search/dash/clusters?decorationId=com.linkedin.voyager.dash.deco.search.SearchClusterCollection-122&origin=FACETED_SEARCH&q=all&query=(keywords:${encodeURIComponent(name)},flagshipSearchIntent:SEARCH_SRP,queryParameters:(geoUrn:List(${locations.map(({ id }) => id).join(',')}),resultType:List(PEOPLE)),includeFiltersInResponse:false)&start=0`, {
      //   headers: {
      //     "x-li-lang": "en_US",
      //     "x-restli-protocol-version": "2.0.0",
      //   }
      // }).then(r => r.data) : undefined

      // const results = resInSelectedLocation?.metadata?.totalResultCount || 0

      payload = {
        rank: get(
          getState(),
          ["jobSetup", "jobSetup", "jobSetup", "job_position", "0", "rank"],
          3
        ),
        ...titles?.[0],
        name,
        company_size,
        spans,
        synonym,
        results: 0,
        selected: true,
        isMainTitle,
        isPredicting: false,
        initial: false,
      };
    } else if (!mainTitle?.initial && mainTitle?.name?.length > 0) {
      dispatch({
        type: INITIALISING_JOB_TITLES_SUCCESS,
      });
      return true;
    }
    dispatch({
      type: CHANGE_MAIN_JOB_TITLE,
      payload,
    });
    dispatch({
      type: INITIALISING_JOB_TITLES_SUCCESS,
    });
  } catch (err) {
    console.log("ERROR: ", err.message);
    dispatch({ type: INITIALISING_JOB_TITLES_FAILURE });
  }
};

export const PREDICT_JOB_TITLE_RESULT = "PREDICT_JOB_TITLE_RESULT";
export const PREDICT_JOB_TITLE_RESULT_SUCCESS =
  "PREDICT_JOB_TITLE_RESULT_SUCCESS";
export const PREDICT_JOB_TITLE_RESULT_FAILURE =
  "PREDICT_JOB_TITLE_RESULT_FAILURE";
export const predictTitleResult = (key) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PREDICT_JOB_TITLE_RESULT,
      payload: {
        key,
      },
    });

    const locations = getState().jobSetup.jobLocation.selectedLocations;
    const title = getState().jobSetup.jobCriteria.titles[key];

    const companySize = title?.companySize;
    const name = title?.name;

    // const resInSelectedLocation = await
    // callLiApi(
    //   `/voyager/api/search/dash/clusters?decorationId=com.linkedin.voyager.dash.deco.search.SearchClusterCollection-122&origin=FACETED_SEARCH&q=all&query=(keywords:${encodeURIComponent(
    //     name
    //   )},flagshipSearchIntent:SEARCH_SRP,queryParameters:(geoUrn:List(${locations
    //     .map(({ id }) => id)
    //     .join(
    //       ","
    //     )}),resultType:List(PEOPLE)),includeFiltersInResponse:false)&start=0`,
    //   {
    //     headers: {
    //       "x-li-lang": "en_US",
    //       "x-restli-protocol-version": "2.0.0",
    //     },
    //   }
    // )
    //==@liWrapper==//
    // callLiWrapperApi('Speciality', null, {name, locations })
    // .then((r) => r.data);

    const pay = await fetch(
      `${process.env.REACT_APP_API_HOST}/api/v2/specialty`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          locations,
        }),
      }
    ).then((r) => r.json());

    const resInUS = await callLiApi(
      `/voyager/api/search/dash/clusters?decorationId=com.linkedin.voyager.dash.deco.search.SearchClusterCollection-122&origin=FACETED_SEARCH&q=all&query=(keywords:${encodeURIComponent(
        name
      )},flagshipSearchIntent:SEARCH_SRP,queryParameters:(geoUrn:List(103644278),resultType:List(PEOPLE)),includeFiltersInResponse:false)&start=0`,
      {
        headers: {
          "x-li-lang": "en_US",
          "x-restli-protocol-version": "2.0.0",
        },
      }
    ).then((r) => r.data);

    // const resInUS = await callLiApi(`/voyager/api/search/dash/clusters?decorationId=com.linkedin.voyager.dash.deco.search.SearchClusterCollection-122&origin=FACETED_SEARCH&q=all&query=(keywords:${encodeURIComponent(name)},flagshipSearchIntent:SEARCH_SRP,queryParameters:(geoUrn:List(103644278),resultType:List(PEOPLE)),includeFiltersInResponse:false)&start=0`, {
    //   headers: {
    //     "x-li-lang": "en_US",
    //     "x-restli-protocol-version": "2.0.0",
    //   }
    // }).then(r => r.data)

    // const resWithCompanySize = await manualApiCall('/api/v2/auth/jobs/title/results', {
    //   method: 'POST',
    //   body: JSON.stringify({
    //     title: name,
    //     companySize,
    //     locations: locations.map(({ name }) => name)
    //   })
    // })

    // let resultsWithCompanySize = 0
    // if(resWithCompanySize.success){
    //   resultsWithCompanySize = resWithCompanySize?.data?.withCompanySize || 0
    // }

    const resultsInSelectedLocation =
      // resInSelectedLocation?.metadata?.totalResultCount || 0;
      pay?.data.count || 0;
    // const resultsInUS = resInUS?.metadata?.totalResultCount || 0

    const results = resultsInSelectedLocation;
    // titleHealthPrediction(resultsInSelectedLocation, resultsInUS, resultsWithCompanySize)

    const payload = {
      key,
      values: {
        results,
        // resultsMessage: results?.message,
        // resultsHealth: results?.health,
      },
    };

    // console.log("======================predictTitleResult=============================", {
    //   payload,
    //   resultsInSelectedLocation,
    //   resultsInUS,
    //   resultsWithCompanySize
    // })

    dispatch({
      type: PREDICT_JOB_TITLE_RESULT_SUCCESS,
      payload,
    });
  } catch (err) {
    console.log("ERROR: ", err.message);
    dispatch({
      type: PREDICT_JOB_TITLE_RESULT_FAILURE,
      payload: {
        key,
      },
    });
  }
};

export const initTitleResult = () => ({
  type: PREDICT_JOB_TITLE_RESULT_FAILURE,
  payload: 0,
});

export const updateJobTitleResults = (titlePayload) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: PREDICT_JOB_TITLE_RESULT,
    });

    const locations = getState().jobSetup.jobLocation.selectedLocations;


    const payload = await getTitleProfileCount(
      titlePayload.name,
      locations
    )

    if (payload != -1) {
      dispatch({
        type: PREDICT_JOB_TITLE_RESULT_SUCCESS,
        payload,
      });
      return true;
    }
    dispatch({
      type: PREDICT_JOB_TITLE_RESULT_SUCCESS,
      payload: -1,
    });
  } catch (err) {
    console.log("ERROR: ", err.message);
    dispatch({
      type: PREDICT_JOB_TITLE_RESULT_FAILURE,
      payload: -1,
    });
  }
};

export const PREDICT_GENERIC_JOB_TITLE_RESULT =
  "PREDICT_GENERIC_JOB_TITLE_RESULT";
export const PREDICT_GENERIC_JOB_TITLE_RESULT_SUCCESS =
  "PREDICT_GENERIC_JOB_TITLE_RESULT_SUCCESS";
export const PREDICT_GENERIC_JOB_TITLE_RESULT_FAILURE =
  "PREDICT_GENERIC_JOB_TITLE_RESULT_FAILURE";
export const updateJobTitleGenericResults = (titlePayload) => async (
  dispatch
) => {
  try {
    dispatch({
      type: PREDICT_GENERIC_JOB_TITLE_RESULT,
    });

    const locations = [];

    const payload = await getTitleProfileCount(
      titlePayload.name,
      locations
    )

    if (payload != -1) {
      dispatch({
        type: PREDICT_GENERIC_JOB_TITLE_RESULT_SUCCESS,
        payload,
      });
      // resolve()
      return true;
    }
    dispatch({
      type: PREDICT_GENERIC_JOB_TITLE_RESULT_SUCCESS,
      payload: 0,
    });
  } catch (err) {
    console.log("ERROR: ", err.message);
    dispatch({
      type: PREDICT_GENERIC_JOB_TITLE_RESULT_FAILURE,
      payload: 0,
    });
  }
};

export const FETCH_TITLE_RESULT = "FETCH_TITLE_RESULT";
export const FETCH_TITLE_RESULT_SUCCESS = "FETCH_TITLE_RESULT_SUCCESS";
export const FETCH_TITLE_RESULT_FAILURE = "FETCH_TITLE_RESULT_FAILURE";

export const fetchTitleResult = (key) => async (dispatch, getState) => {
  try {
    dispatch({ type: FETCH_TITLE_RESULT });
    const locations = getState().jobSetup.jobLocation.selectedLocations;
    const { name } = getState().jobSetup.jobCriteria.titles[key];

    const totalResultCount = await getTitleProfileCount(
      name, locations
    )

    const value = { results: totalResultCount }
    dispatch({
      type: CHANGE_JOB_TITLE,
      payload: {
        key,
        value,
      },
    });

    dispatch({
      type: FETCH_TITLE_RESULT_SUCCESS,
    });
  } catch (err) {
    console.log("ERROR: ", err.message);
    dispatch({ type: FETCH_TITLE_RESULT_FAILURE });
  }
};

export const SET_SHOULD_SELECT_LAST_TAB = "SET_SHOULD_SELECT_LAST_TAB";
export const setShouldSelectLastTab = (payload = false) => ({
  type: SET_SHOULD_SELECT_LAST_TAB,
  payload,
});

export const ADD_NEW_JOB = "ADD_NEW_JOB";
export const ADD_NEW_JOB_SUCCESS = "ADD_NEW_JOB_SUCCESS";
export const ADD_NEW_JOB_FAILURE = "ADD_NEW_JOB_FAILURE";
export const addNewJob = (callBack, isDuplicate = false) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: ADD_NEW_JOB,
    });

    const toBeInvited = [];
    const tribeData = [];
    getState().jobSetup.tribeMembers.tribeCards.map(
      ({ added, type, email, userId, reachoutPermission, viewPermission }) => {
        if (added) {
          const permissions = [];
          if (reachoutPermission) {
            permissions.push(2);
          }
          if (viewPermission) {
            permissions.push(3);
          }
          if (type === "invite") {
            toBeInvited.push({
              email,
              permissions,
            });
          } else {
            tribeData.push({
              userId,
              permissions,
            });
          }
        }
      }
    );

    let ideal;
    let jdText;
    let jobLocation;
    let jobArray;
    const importanceMap = {
      Low: "1",
      Medium: "3",
      High: "5",
    };

    const educationsData = getState().jobSetup.jobCriteria.educations;
    const education = Object.keys(educationsData)
      .filter((key) => educationsData[key].selected)
      .map((key) => ({
        ...educationsData[key],
        importance:
          importanceMap[
          Object.keys(educationsData[key].importance)
            .filter((k) => educationsData[key].importance[k])
            .pop()
          ],
      }));

    // if (isDuplicate === true) {
    const specialitiesData = getState().jobSetup.jobSpecialities.specialities;
    const company_speciality = Object.keys(specialitiesData)
      .filter((key) => specialitiesData[key].selected)
      .map((key) => ({
        ...specialitiesData[key],
        min: specialitiesData[key].experienceSelected
          ? specialitiesData[key].experience.min
          : 0,
        max: specialitiesData[key].experienceSelected
          ? specialitiesData[key].experience.max
          : 0,
      }));

    const mainTitle = getState().jobSetup.jobCriteria.mainTitle;
    const titlesData = getState().jobSetup.jobCriteria.titles;

    const job_position = [mainTitle];

    const suggested_titles =
      Object.keys(titlesData)
        .filter(
          (key) =>
            titlesData[key].selected &&
            !titlesData[key].isMainTitle &&
            titlesData[key].name?.trim() !== ""
        )
        .map((key) => titlesData[key]) || [];

    const total_experience = getState().jobSetup.jobCriteria.totalExperience;

    const functionsData = getState().jobSetup.jobCriteria.functions;
    const functions =
      Object.keys(functionsData)
        .filter((key) => functionsData[key].selected)
        .map((key) => ({
          experienceSelected: functionsData[key].experienceSelected,
          min: functionsData[key].experienceSelected
            ? functionsData[key].experience.min
            : 0,
          max: functionsData[key].experienceSelected
            ? functionsData[key].experience.max
            : 0,
          name: functionsData[key].name,
        })) || [];

    const skillsData = getState().jobSetup.jobCriteria.skills;
    const skills =
      Object.keys(skillsData)
        .filter((key) => skillsData[key].selected)
        .map((key) => ({
          ...skillsData[key],
          min: skillsData[key].experienceSelected
            ? skillsData[key].experience.min
            : 0,
          max: skillsData[key].experienceSelected
            ? skillsData[key].experience.max
            : 0,
          importance:
            importanceMap[
            Object.keys(skillsData[key].importance)
              .filter((k) => skillsData[key].importance[k])
              .pop()
            ],
          experienceSelected: skillsData[key].experienceSelected,
        })) || [];

    jobArray = {
      competitorCompanies: [],
      companyHiringFor: [],
      company_speciality: [],
      likedCompanies: [],
      education: [],
      functions: [],
      ideal_company: [],
      ideal_education: [],
      ideal_experience: [],
      ideal_skill: [],
      ideal_title: [],
      job_location: [],
      job_position: [],
      skill: [],
      skills: [],
      speciality: [],
      suggested_skills: [],
      suggested_titles: [],
      technology_work_experience: [],
      total_experience: [],
      suggested_titles_copy: [],
      ideal_specialities: [],
      ...getState().jobSetup.jobSetup.jobSetup,
      company_speciality,
      job_position,
      suggested_titles,
      total_experience,
      functions,
      skills,
      education,
      version: 2,
      job_location: getState().jobSetup.jobLocation.selectedLocations,
    };

    // console.log("=================Edit Job Array=========================", jobArray)
    ideal = getState().jobSetup.jobIdealProfile.profiles;

    jdText = getState().jobSetup.jobDetails.description || "";
    jobLocation = getState().jobSetup.jobLocation.selectedLocations || [];
    // } else {
    //   ideal = prepareIdealProfiles(
    //     getState().jobSetup.jobIdealProfile.profiles || []
    //   );
    //   jdText = getState().jobSetup.jobDetails.description || "";
    //   jobLocation = getState().jobSetup.jobLocation.selectedLocations || [];

    //   const setup = {
    //     competitorCompanies: [],
    //     companyHiringFor: [],
    //     company_speciality: [],
    //     likedCompanies: [],
    //     education: [],
    //     functions: [],
    //     ideal_company: [],
    //     ideal_education: [],
    //     ideal_experience: [],
    //     ideal_skill: [],
    //     ideal_title: [],
    //     job_location: [],
    //     job_position: [],
    //     skill: [],
    //     skills: [],
    //     speciality: [],
    //     suggested_skills: [],
    //     suggested_titles: [],
    //     technology_work_experience: [],
    //     total_experience: [],
    //     suggested_titles_copy: [],
    //     ideal_specialities: [],
    //     ...getState().jobSetup.jobSetup.jobSetup,
    //   };

    //   jobArray = {
    //     ...Object.keys(setup).reduce((total, key) => {
    //       if (["company_speciality"].includes(key)) {
    //         return {
    //           ...total,
    //           [key]: setup[key].filter(({ selected }) => selected),
    //         };
    //       }
    //       return {
    //         ...total,
    //         [key]: setup[key],
    //       };
    //     }, {}),
    //     job_location: jobLocation,
    //     version: 2,
    //   };
    // }

    const jobTitle = jobArray?.job_position?.[0]?.name || "";

    const payload = {
      toBeInvited,
      tribeData,
      jdText,
      jobLocation,
      jobTitle,
      jobArray: {
        ...jobArray,
        job_position: jobArray.job_position.map((item) =>
          item.id ? item : { ...item, id: v4() }
        ),
        suggested_titles: jobArray.suggested_titles.map((item) =>
          item.id ? item : { ...item, id: v4() }
        ),
      },
      benchmarkProspects: {
        similar: null,
        ideal,
      },
      oldP2P: process.env.REACT_APP_OLD_P2P === "true",
    };
    const res = await manualApiCall("/api/auth/tribes/store?v2=true", {
      method: "POST",
      body: JSON.stringify(payload),
    });
    if (res.success) {
      dispatch({
        type: ADD_NEW_JOB_SUCCESS,
        payload: res,
      });
      dispatch(initCompanyDetails());
      dispatch(initJobCriteria());
      dispatch(initJobDetails());
      dispatch(initJobIdealProfile());
      dispatch(initJobLocation());
      dispatch(initJobSpecialities());
      dispatch(initTribeMembers());
      dispatch(initJobsetup());
      dispatch(initJobSetupView());
      callBack(res.data.id);
    } else {
      throw new Error("new job api failed!");
    }
  } catch (err) {
    dispatch({
      type: ADD_NEW_JOB_FAILURE,
    });
    console.log("Error: ", err.message);
  }
};

export const INIT_COMPANY_DETAILS = "INIT_COMPANY_DETAILS";
export const initCompanyDetails = () => ({
  type: INIT_COMPANY_DETAILS,
});

export const INIT_JOB_CRITERIA = "INIT_JOB_CRITERIA";
export const initJobCriteria = () => ({
  type: INIT_JOB_CRITERIA,
});

export const INIT_JOB_DETAILS = "INIT_JOB_DETAILS";
export const initJobDetails = () => ({
  type: INIT_JOB_DETAILS,
});

export const INIT_JOB_IDEAL_PROFILE = "INIT_JOB_IDEAL_PROFILE";
export const initJobIdealProfile = () => ({
  type: INIT_JOB_IDEAL_PROFILE,
});

export const INIT_JOB_LOCATION = "INIT_JOB_LOCATION";
export const initJobLocation = () => ({
  type: INIT_JOB_LOCATION,
});

export const INIT_JOBSETUP = "INIT_JOBSETUP";
export const initJobsetup = () => ({
  type: INIT_JOBSETUP,
});

export const INIT_JOB_SEPCIALITIES = "INIT_JOB_SEPCIALITIES";
export const initJobSpecialities = () => ({
  type: INIT_JOB_SEPCIALITIES,
});

export const INIT_TRIBE_MEMBERS = "INIT_TRIBE_MEMBERS";
export const initTribeMembers = () => ({
  type: INIT_TRIBE_MEMBERS,
});

export const INIT_JOB_EDIT_SIMILAR_COMPANY = "INIT_JOB_EDIT_SIMILAR_COMPANY";
export const initJobEditSimilarCompany = (payload) => ({
  type: INIT_JOB_EDIT_SIMILAR_COMPANY,
  payload,
});

export const INIT_JOB_EDIT = "INIT_JOB_EDIT";

export const initJobEdit = () => async (dispatch, getState) => {
  const jobdescription = getState()?.prospectsView?.candidates?.job?.jdText || "";
  if (jobdescription && jobdescription?.length > 0) {
    dispatch({
      type: CHANGE_JOB_DESCRIPTION,
      payload: jobdescription,
    });
  }
  const locations = getState().prospectsView?.candidates?.job?.jobArray?.job_location || [];
  if (locations?.length > 0) {
    locations?.map((location) => {
      dispatch({
        type: ADD_SELECTED_JOB_LOCATION,
        payload: location,
      });
    });
  }

  const ideal = getState()?.prospectsView?.candidates?.job?.benchmarkProspects?.ideal || [];

  if (ideal.length > 0) {
    ideal.map((idealProfile) =>
      dispatch({
        type: FETCH_IDEAL_PROFILE_DATA_SUCCESS,
        payload: idealProfile,
      })
    );
  }
  const companyHiringFor =
    getState()?.prospectsView?.candidates?.job?.jobArray?.companyHiringFor || [];

  if (companyHiringFor.length > 0 && companyHiringFor?.[0]?.universalName) {
    dispatch({
      type: SELECT_COMPANY_SUCCESS,
      payload: companyHiringFor[0],
    });
  }
  const competitorCompanies = getState()?.prospectsView?.candidates?.job?.jobArray?.competitorCompanies || [];

  const likedCompanies = getState()?.prospectsView?.candidates?.job?.jobArray?.likedCompanies || [];

  // console.log("=============likedCompanies====================", likedCompanies, getState()?.prospectsView?.candidates?.job?.jobArray?.likedCompanies)

  if (likedCompanies.length > 0) {
    dispatch({
      type: FETCH_JOB_SIMILAR_COMPANY_SUCCESS,
      payload: {
        similarComp: likedCompanies,
        universalName: false,
        unsliced: true,
        uninitialized: true
      },
    });
  }

  const similarCompanies = competitorCompanies?.concat(likedCompanies);

  if (similarCompanies?.length > 0) {
    dispatch(initJobEditSimilarCompany(similarCompanies));
  }

  const jobArray = getState()?.prospectsView?.candidates?.job?.jobArray || [];

  dispatch({
    type: SENDING_TO_NER_SUCCESS,
    payload: jobArray,
  });
};

export const SET_IS_REVIEW = "SET_IS_REVIEW";

export const setIsReview = (isReview) => async (dispatch, getState) => {
  dispatch({
    type: SET_IS_REVIEW,
    payload: isReview,
  });
};

export const SET_IS_EDITED = "SET_IS_EDITED";
export const setIsEdited = (payload) => ({
  type: SET_IS_EDITED,
  payload,
});

export const SET_IS_SPECIALTY_EDITED = "SET_IS_SPECIALTY_EDITED";
export const setIsSpecialtyEdited = (payload) => ({
  type: SET_IS_SPECIALTY_EDITED,
  payload,
});

export const SET_GENERATE_JOB_DESCRIPTION = "SET_GENERATE_JOB_DESCRIPTION";
export const setIsJDGenerated = (payload) => ({
  type: SET_GENERATE_JOB_DESCRIPTION,
  payload,
});

export const SET_JD_CHAT_LIST = "SET_JD_CHAT_LIST";
export const setJDChatList = (payload) => ({
  type: SET_JD_CHAT_LIST,
  payload,
});

export const SET_IS_JD_CHAT_GENERATING = "SET_IS_JD_CHAT_GENERATING";
export const setIsJDChatLoading = (payload) => ({
  type: SET_IS_JD_CHAT_GENERATING,
  payload,
});

export const SET_JOB_DESCRIPTION = "SET_JOB_DESCRIPTION";
export const setJobDescription = (payload) => {
  return {
    type: SET_JOB_DESCRIPTION,
    payload,
  };
};

export const SET_ENTER_BOT_PRESSED = "SET_ENTER_BOT_PRESSED";
export const setEnterBotPressed = (payload) => {
  return {
    type: SET_ENTER_BOT_PRESSED,
    payload,
  };
};
