import React, { useEffect, useState } from 'react'
import PrimaryButton from '../../../../uiLibrary/PrimaryButton'
import PrimaryInput from '../../../../uiLibrary/PrimaryInput'
import PillButton from '../../../../uiLibrary/PillButton'
import { deleteFirebaseUser, getFirebaseCurrentUser, getRecaptcha, isMobileNumberValid, jsonParse, manualApiCall, send2FACode, signInToFirebase } from '../../../../utils'
import './style.scss'
import Loading from '../../../../uiLibrary/Loading'
import PrimaryCheckBox from '../../../../uiLibrary/PrimaryCheckBox'
import { setUser } from '../../../../actions/auth'
import { isEmpty } from 'lodash'
const MultiFactorAuth = ({
    userData,
    emailProvider,
    dispatch
}) => {

    const [phone_number, set_phone_number] = useState("")
    const [userInfo, setUserInfo] = useState({})
    const [is_2fa_enabled, set_is_2fa_enabled] = useState(false)
    const [verifyMode, setVerifyMode] = useState(false)
    const [code, setCode] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    useEffect(async () => {
      console.log("==========userData=================", {userData})
        set_is_2fa_enabled(userData.is_2fa_enabled)
        const phone = await manualApiCall("/api/v2/auth/user/phone", {
            method: 'GET'
        })
        // console.log("================phone===================", {phone})
        set_phone_number((phone.data?.[0] === "+" ? phone.data : '+' + phone.data) || "")

    }, [])

    const toggle2FA = e => {
        // console.log("===================toggle2FA=========================", e.target.checked)
        // manualApiCall('/api/v2/auth/updateUserData', {
        //     method: 'POST',
        //     body: JSON.stringify({is_2fa_enabled: e.target.checked})
        // })
        // localStorage.setItem('user', JSON.stringify({...userData, is_2fa_enabled: e.target.checked}))
        // dispatch(setUser({...userData, is_2fa_enabled: e.target.checked}))

        set_is_2fa_enabled(prev => {
            if (!prev) {
                setVerifyMode(false)
            }
            manualApiCall('/api/v2/auth/updateUserData', {
                method: 'POST',
                body: JSON.stringify({ is_2fa_enabled: !prev })
            })
            return !prev
        })
    }

    const handleSave = async e => {
        try {
            if (verifyMode) {
                // console.log("===============confirmationResult=================", window.confirmationResult)
                window.confirmationResult.confirm(code).then(async (result) => {
                    // console.log("===============result=================", result)
                    const user = result?.user
                    // console.log("===============user=================", user)

                    // deleteFirebaseUser(user)

                    // console.log("===============userData=================", userData)

                    const newUser = await signInToFirebase({ ...userInfo, phoneNumber: phone_number, is_2fa_enabled })

                    if (newUser) {
                        // console.log("===============newUser=================", newUser)
                        setVerifyMode(false)
                        const token = await newUser.getIdToken(true)
                        // console.log("===============token=================", token)
                        localStorage.setItem('user', JSON.stringify({ ...userInfo, phone_number, is_2fa_enabled, token, sw: newUser?.stsTokenManager?.refreshToken }))
                        dispatch(setUser({ ...userInfo, phone_number, is_2fa_enabled }))
                        manualApiCall('/api/v2/auth/updateUserData', {
                            method: 'POST',
                            body: JSON.stringify({ is_2fa_enabled, phone_number, sw: newUser?.stsTokenManager?.refreshToken })
                        })
                        setCode("")
                        alert(`2 step verification enabled on ${phone_number}`)
                    }
                    else {
                        throw new Error("Relogin Failed")
                    }
                })
            }
            else {
                const r = await manualApiCall('/public/new/firebase/token',
                    { method: 'POST', body: JSON.stringify({ email: userData.email }) })
                if (r.isSuccess && !isEmpty(r.data)) {
                    setUserInfo(r?.data)
                    getRecaptcha('recaptcha-verify-container', res => {
                        // console.log("===========getRecaptcha==============", {res})
                    })
                    send2FACode(phone_number)
                    setVerifyMode(true)
                }
            }
        }
        catch (err) {
            console.log("ERROR: ", err.message)

        }
    }

    if (emailProvider !== "GENERIC") {
        return <div />
    }


    return (
        <div
            className='MultiFactorAuthContainer'
        >
            <div>
                <PrimaryCheckBox
                    checked={is_2fa_enabled}
                    onChange={toggle2FA}
                />
                <label>
                    {`2 step verification ${is_2fa_enabled ? "enabled" : "disabled"}`}
                </label>
                {isLoading &&
                    <Loading />
                }
            </div>
            {is_2fa_enabled &&
                <React.Fragment>
                    <div>
                        {verifyMode ?
                            <React.Fragment>
                                <label>
                                    Code:
                                </label>
                                <PrimaryInput
                                    value={code}
                                    onChange={e => setCode(e.target.value)}
                                    placeholder="756489"
                                />
                            </React.Fragment> :
                            <React.Fragment>
                                <label>
                                    Phone Number:
                                </label>
                                <PrimaryInput
                                    placeholder="+534857146452"
                                    value={phone_number}
                                    onChange={e => set_phone_number(e.target.value)}
                                />
                            </React.Fragment>}
                        <PrimaryButton
                            onClick={handleSave}
                            disabled={verifyMode? code?.trim()==="" :!isMobileNumberValid(phone_number)}
                        >
                            Save Number
                        </PrimaryButton>
                    </div>
                    <div id='recaptcha-verify-container' />
                </React.Fragment>
            }
        </div>
    )
}

export default MultiFactorAuth