import React, { useEffect, useState, useCallback } from "react";
import NewAutoSuggestion from "../../../components/NewAutoSuggestion/NewAutoSuggestion";
import CompanyCard from "../../../components/CompanyCard/CompanyCard";
import { connect } from "react-redux";
import NoCompanyFoundCard from "./NoCompanyFoundCard";
import CompanyDetailsDropDown from "./CompanyDetailsDropDown";
import {
  fetchJobCompanySuggestion,
  getJobCompanyProfile,
  changeJobCompanyName,
  fetchJobSimilarCompanies,
  getJobCompanyProfileEmployeeCount,
  companyProfileDelete,
  clearJobCompanySuggestion,
  selectHiringCompany,
  setIsEdited,
  initJobSpecialities,
  setIsSpecialtyEdited,
} from "../../../actions/JobSetup";
import Loading from '../../../uiLibrary/Loading'
import "./style.scss";
import ProfileSummaryCard from "../../../components/ProfileSummaryCard/ProfileSummaryCard";
import { Mixpanel } from "../../../utils/Mixpanel";

const HiringCompanyDetails = ({
  dispatch,
  companyName,
  companySuggestions,
  companyHiringFor,
  similarCompanies,
  companyProfileDetails,
  isLoading,
  isLoadedSuggestion,
  isLoadingSuggestion,
  isCompanyProfileLoaded,
  isCompanyProfileLoading,
  isReview
}) => {
  const [companyAddBtnClicked, setCompanyAddBtnClicked] = useState(false);
  const [companyCardCloseBtn, setCompanyCardCloseBtn] = useState(true);
  const [noCompanyFound, setNoCompanyFound] = useState(false);

  useEffect(()=>{    
    Mixpanel.track("Hiring Company")
  }, [])

  useEffect(()=>{
    if(Object.keys(companyHiringFor).length !== 0 && similarCompanies.length === 0 && isReview==='0' ){
      dispatch(fetchJobSimilarCompanies(companyHiringFor?.urn, companyHiringFor.universalName));
    }
  },[])

  const NoCompanyFoundCardAddBtnClicked = () => {
    setCompanyAddBtnClicked(true);
  };

  const handlerChangeJobCompanyName = (e) => {
    dispatch(changeJobCompanyName(e.target.value));
  };

  const handlerGetJobCompanyProfile = (value) => {
    // dispatch(changeJobCompanyName(''))
    // console.log("=================handlerGetJobCompanyProfile======================", value)
    dispatch(selectHiringCompany(value));
    dispatch(setIsSpecialtyEdited(true))

    Mixpanel.track("Hiring company select")
  };

  const handlerFetchJobCompanySuggestion = (value) => {
    const companyReg =  new RegExp('linkedin.com\/company\/', 'gi')
    const agencyReg =  new RegExp('linkedin.com\/showcase\/', 'gi')
    if(companyReg.exec(value)!==null || agencyReg.exec(value)!==null){
      const valueSplit = value.split(/\//)
      const lastValueSplit =  valueSplit?.pop()?.trim()
      const secondlastValueSplit =  valueSplit?.pop()?.trim()
      if(typeof lastValueSplit === 'string' && lastValueSplit!==""){
        dispatch(fetchJobCompanySuggestion(lastValueSplit, true, value));
      }
      else if(typeof secondlastValueSplit === 'string' && secondlastValueSplit!==""){
        dispatch(fetchJobCompanySuggestion(secondlastValueSplit, true, value));
      }
    }
    else{
      dispatch(fetchJobCompanySuggestion(value));
    }
  };

  const handleSuggestionsClearJobCompany = () => {
    dispatch(clearJobCompanySuggestion());
  }

  const handlerCompanycardCloseBtn = () => {
    dispatch(initJobSpecialities());
    dispatch(companyProfileDelete());
    dispatch(setIsSpecialtyEdited(true))
  };


  const renderSuggestion = (suggestion) => {
    // console.log("==============renderSuggestion==============", suggestion)
    return (
    typeof suggestion === 'string' ? 
      <div>{suggestion}</div> :
      suggestion.isUrl ? 
      <div>{suggestion.name}</div> :
      <div>
        <ProfileSummaryCard
          key={suggestion.urn}
          name={suggestion.name}
          headline={suggestion.industry}
          imgSrc={suggestion.logo}
        />
      </div>

  )}

  const shouldRenderSuggestions = suggestion => (
    (typeof suggestion === "string"
    ? suggestion.trim() 
    : suggestion?.name.trim()) && 
    !isLoadingSuggestion &&
    !isLoading
  )



  const [currValue, setcurrValue] = useState("51-200");
  const subDropDownMenu = [
    "1-10",
    "11-50",
    "51-200",
    "201-500",
    "501-1000",
    "1001-5000",
    "5001-10,000",
    "10,001+",
  ];

  return (
    <div className="hiringCompanyDetails">
      <h1>What company are you hiring for?</h1>
      <div>
        {(isCompanyProfileLoaded && !isCompanyProfileLoading) ?
          <CompanyCard
            companyLogo={companyHiringFor?.logo}
            companyName={companyHiringFor?.name}
            companyHeadline={companyHiringFor?.industry}
            companyEmployees={`${companyHiringFor?.staffCount || 0} Employees`}
            companyDescription={companyHiringFor?.companyDescription}
            industry={companyHiringFor?.industry}
            specialities={companyHiringFor?.specialities}
            staffCount={companyHiringFor?.staffCount}
            companyPageUrl={companyHiringFor?.companyPageUrl}
            headquarter={[companyHiringFor?.headquarter?.country || '', companyHiringFor?.headquarter?.geographicArea || '', companyHiringFor?.headquarter?.city || ''].filter(itm=>itm!=='')}
            companyType={companyHiringFor?.companyType?.localizedName}
            onClick={isReview === '1' ? () => false : handlerCompanycardCloseBtn}
          /> :
          <React.Fragment>
            <p>COMPANY NAME OR URL</p>
            <div
              className='hiringCompanySuggestionContainer'
            >
              <NewAutoSuggestion
                placeholder="ACME lnc."
                inputValue={companyName}
                disabled={isCompanyProfileLoading}
                suggestionsArray={companySuggestions}
                handleChangeInput={isReview === '1' ? () => false : handlerChangeJobCompanyName}
                handleSuggestionsFetchRequested={isReview === '1' ? () => false : handlerFetchJobCompanySuggestion}
                handleSuggestionSelected={isReview === '1' ? () => false : handlerGetJobCompanyProfile}
                handleSuggestionsClearRequested={isReview === '1' ? () => false : handleSuggestionsClearJobCompany}
                renderSuggestion={isReview === '1' ? () => false : renderSuggestion}
                shouldRenderSuggestions={isReview === '1' ? () => false : shouldRenderSuggestions}
              />
              {(isCompanyProfileLoading || isLoadingSuggestion) &&
                <Loading
                  width='24px'
                  height='24px'
                />
              }
            </div>
          </React.Fragment>
        }
      </div>
    </div>
  );
};

export default connect((state) => ({
  companyHiringFor: state.jobSetup.companyDetails.companyHiringFor,
  companyProfileDetails: state.jobSetup.companyDetails.companyProfileDetails,
  companySuggestions: state.jobSetup.companyDetails.companySuggestions,
  companyName: state.jobSetup.companyDetails.companyName,
  similarCompanies: state.jobSetup.companyDetails.similarCompanies,
  isLoading: state.jobSetup.companyDetails.isLoading,
  isLoadedSuggestion: state.jobSetup.companyDetails.isLoadedSuggestion,
  isLoadingSuggestion: state.jobSetup.companyDetails.isLoadingSuggestion,
  isCompanyProfileLoaded: state.jobSetup.companyDetails.isCompanyProfileLoaded,
  isCompanyProfileLoading: state.jobSetup.companyDetails.isCompanyProfileLoading,
  isReview: state.jobSetup?.jobSetup?.isReview
}))(HiringCompanyDetails);
